const toolbar = {
  options: ["inline", "list", "embedded"],
  inline: {
    inDropdown: false,
    options: ["bold", "italic", "underline"],
    bold: {
      icon: "/static/images/RichTextAreaToolbarIcons/bold.svg",
      className: undefined,
    },
    italic: {
      icon: "/static/images/RichTextAreaToolbarIcons/italic.svg",
      className: undefined,
    },
    underline: {
      icon: "/static/images/RichTextAreaToolbarIcons/underline.svg",
      className: undefined,
    },
  },
  list: {
    inDropdown: false,
    options: ["unordered", "ordered"],
    unordered: {
      icon: "/static/images/RichTextAreaToolbarIcons/unordered.svg",
      className: undefined,
    },
    ordered: {
      icon: "/static/images/RichTextAreaToolbarIcons/ordered.svg",
      className: undefined,
    },
  },
  textAlign: {
    inDropdown: false,
    options: ["left", "center", "right", "justify"],
    left: {
      icon: "/static/images/RichTextAreaToolbarIcons/left.svg",
      className: undefined,
    },
    center: {
      icon: "/static/images/RichTextAreaToolbarIcons/center.svg",
      className: undefined,
    },
    right: {
      icon: "/static/images/RichTextAreaToolbarIcons/right.svg",
      className: undefined,
    },
    justify: {
      icon: "/static/images/RichTextAreaToolbarIcons/justify.svg",
      className: undefined,
    },
  },
  embedded: {
    icon: "/static/images/RichTextAreaToolbarIcons/embedded.svg",
  },
};

export const richTextAreaUtils = { toolbar };
