import instance from "src/axios/axios-user";
import { API_PATHS } from "src/utils/API_PATHS";
import { CreateJobProps } from "src/Redux/slices";

const createJob = (values: CreateJobProps.CreateUpdateRequest) =>
  instance.post(API_PATHS.createJob, JSON.stringify(values));
const updateJob = (id: number, values: CreateJobProps.CreateUpdateRequest) =>
  instance.put(`${API_PATHS.createJob}?job_id=${id}`, JSON.stringify(values));
const getJobs = (id: string | number) =>
  instance.get(`${API_PATHS.getJob}/${id}`);
const getJobOverview = (status: number) =>
  instance.get(`${API_PATHS.jobOverview}/${status}`);
const getBillingProducts = () =>
  instance.get(`${API_PATHS.getBillingProducts}`);

export const JOBS = {
  getJobOverview,
  createJob,
  updateJob,
  getJobs,
  getBillingProducts,
};
