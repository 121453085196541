import { FC, useEffect, useState } from "react";
import clsx from "clsx";
import { faEllipsisV, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Dropdown } from "react-bootstrap";
import { Grid } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Cell,
  Column,
  // ColumnInstance,
  HeaderGroup,
  Row,
  TableState,
  useAsyncDebounce,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

import { FormFields, IconButton, Typography } from "src/components/Basic";
import { CustomTable, TableProps, IOverview } from "./Overview";
import {
  customColumnCell,
  pageJumperOptions,
  tableColumns,
  tableColumnsMobile,
} from "./Utils";
import {
  PaginationButton,
  ContentWrapper,
  Wrapper,
  Table,
  IconWrapper,
  IconContainer,
  Container,
  DropDownContainer,
  DropDownMenuContainer,
  RowCellContainer,
  CommonContainer,
  FooterContainer,
  TableData,
} from "./OverviewStyle";
import { ICONS } from "src/assets/custom_Icons";
import { useAppDispatch, useAppSelector, actions } from "src/Redux";
import send from "src/assets/img/faces/send.png";

const OverviewTable: FC<IOverview.IProps> = ({
  setIsAddUserModalOpen,
  setIsEditModalOpen,
  setIsDeactivateModalOpen,
}) => {
  const { userData } = useAppSelector((state) => state.settings);
  const dispatch = useAppDispatch();
  const [status, setStatus] = useState("All");
  const [memberData, setMemberData] = useState<TableProps.column[]>([]);
  const screen = Grid.useBreakpoint();

  const {
    getTableBodyProps,
    getTableProps,
    headerGroups,
    page,
    prepareRow,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setPageSize,
    gotoPage,
    setGlobalFilter,
    state,
    pageCount,
  }: CustomTable.TableOptions<TableProps.Data> = useTable(
    {
      columns: screen.lg ? tableColumns : tableColumnsMobile,
      data: memberData,
      initialState: { pageSize: pageJumperOptions[1].value } as Partial<
        TableState<Column>
      >,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { pageIndex, globalFilter, pageSize } = state;
  const [value, setValue] = useState(globalFilter);
  const onChangeHandler = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 300);

  useEffect(() => {
    setGlobalFilter(value || undefined);
  }, [userData]);

  useEffect(() => {
    setValue("");
    setGlobalFilter("");
    if (status === "All") {
      return setMemberData(userData);
    }
    setMemberData(
      userData.filter(
        (data) => data.member_status === status.toLocaleLowerCase()
      )
    );
  }, [userData, status]);

  const customRowCell = (cell: Cell) => {
    const row = cell.row.original as TableProps.column;
    switch (cell.column.Header) {
      case "Profile":
        return (
          <IconContainer
            src={
              row.member_status === "invited"
                ? send
                : `data:image/png;base64,${cell.value}`
            }
            iscover={row.member_status === "invited"}
            className={clsx(
              row.member_status === "deactivated" && "opacity-50"
            )}
          >
            {!cell.value &&
              row.member_status !== "invited" &&
              `${row.first_name[0] + row.last_name[0]}`}
          </IconContainer>
        );
      case "Action":
        return (
          <Container>
            <Dropdown>
              <DropDownContainer className="btn btn-danger">
                <FontAwesomeIcon
                  icon={faEllipsisV}
                  size="xl"
                  className="user-icon"
                />
              </DropDownContainer>
              <DropDownMenuContainer className="dropdown-menu tx-13 custom-dropdown">
                {(cell.row.original as TableProps.column).member_status !==
                  "deactivated" && (
                  <Dropdown.Item
                    className="dropdown-item"
                    href="#"
                    onClick={() => {
                      dispatch(
                        actions.settings.setEditUserData(cell.row.original)
                      );
                      setIsEditModalOpen(true);
                    }}
                  >
                    <Typography
                      content="Edit"
                      styleName="tableSubHeading"
                      color="black_pure"
                    />
                  </Dropdown.Item>
                )}
                <Dropdown.Item
                  className="dropdown-item"
                  href="#"
                  onClick={() => {
                    dispatch(
                      actions.settings.setEditUserData(cell.row.original)
                    );
                    setIsDeactivateModalOpen(true);
                  }}
                >
                  <Typography
                    content={
                      (cell.row.original as TableProps.column).member_status ===
                      "deactivated"
                        ? "Activate"
                        : (cell.row.original as TableProps.column)
                            .member_status === "active"
                        ? "Deactivate"
                        : "Remove"
                    }
                    styleName="tableSubHeading"
                    color="black_pure"
                  />
                </Dropdown.Item>
              </DropDownMenuContainer>
            </Dropdown>
          </Container>
        );
      case "Email":
        return (
          <RowCellContainer className="job-column email-user-role">
            <Typography
              content={cell.value}
              styleName="tableSubHeading"
              color="blue_grey"
            />
          </RowCellContainer>
        );
      case "User Role":
        return (
          <RowCellContainer className="job-column email-user-role">
            {row.member_status === "active" ? (
              <Typography
                content={cell.value === 0 ? "Standard" : "Admin"}
                styleName="tableSubHeading"
                color="blue_grey"
              />
            ) : row.member_status === "invited" ? (
              <Typography
                content="Invited"
                styleName="invitedDeactivatedTable"
                color="blue_grey"
              />
            ) : (
              <Typography
                content="Deactivated"
                styleName="invitedDeactivatedTable"
                color="blue_grey"
              />
            )}
          </RowCellContainer>
        );
      case "First Name":
        return (
          <RowCellContainer className="job-column">
            <Typography
              content={cell.value}
              styleName="tableRowDefault"
              color="black85"
            />
            {!screen.lg && (
              <div className="d-flex align-items-center" style={{ gap: "5px" }}>
                <ICONS.BagIcon />
                <Typography
                  content={row.user_role === 0 ? "Standard" : "Admin"}
                  styleName="tableSubHeadingText"
                  color="blue_grey"
                />
              </div>
            )}
          </RowCellContainer>
        );

      default:
        return (
          <RowCellContainer className="job-column">
            <Typography
              content={cell.value}
              styleName="tableRowDefault"
              color="black85"
            />
          </RowCellContainer>
        );
    }
  };

  return (
    <ContentWrapper>
      <div>
        <CommonContainer>
          <div className="d-flex gap-3 justify-content-between mb-3 ">
            <FormFields.SearchField
              fieldName="search"
              idPrefix="table_search_input_field"
              onChangeHandler={(value) => {
                onChangeHandler(value);
                setValue(value);
              }}
              value={value}
              isRequired={false}
            />
            <div className="d-flex gap-2 mr-1 status-dropdown  ">
              <Dropdown
                onSelect={(value) => {
                  setStatus(value as string);
                }}
              >
                <Dropdown.Toggle
                  variant=""
                  id="dropdown-basic"
                  aria-expanded="false"
                  aria-haspopup="true"
                  className=" btn-light "
                  data-bs-toggle="dropdown"
                  type="button"
                >
                  {status}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey="All">All</Dropdown.Item>
                  <Dropdown.Item eventKey="Active">Active</Dropdown.Item>
                  <Dropdown.Item eventKey="Invited">Invited</Dropdown.Item>
                  <Dropdown.Item eventKey="Deactivated">
                    Deactivated
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <IconButton
                handleClick={() => setIsAddUserModalOpen(true)}
                icon={faPlus}
                label="User"
                position="left"
              />
            </div>
          </div>
        </CommonContainer>
        <Table {...getTableProps()} className="table  mb-0">
          <thead>
            {headerGroups.map((headerGroup: HeaderGroup) => (
              <tr
                className="table-header"
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column: any) => (
                  <th
                    {...column.getHeaderProps(
                      !column.disableSortBy && column.getSortByToggleProps()
                    )}
                    style={{ width: column.maxWidth }}
                  >
                    {customColumnCell(column)}
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <IconWrapper className="mx-2">
                          <ICONS.Polygon />
                        </IconWrapper>
                      ) : (
                        <span className="mx-2 ">
                          <ICONS.Polygon />
                        </span>
                      )
                    ) : (
                      ""
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="table-body" {...getTableBodyProps()}>
            {page.map((row: Row) => {
              prepareRow(row);
              return (
                <TableData
                  className={clsx(
                    (row.original as TableProps.column).member_status ===
                      "deactivated" && "member-deactivated",
                    (row.original as TableProps.column).member_status ===
                      "invited" && "member-invited",
                    (row.original as TableProps.column).member_status ===
                      "active" && "member-active",
                    "overview-table"
                  )}
                  isdisable={
                    (row.original as TableProps.column).member_status ===
                    "deactivated"
                  }
                  isInvite={
                    (row.original as TableProps.column).member_status ===
                    "invited"
                  }
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell: Cell) => (
                    <td {...cell.getCellProps()}>{customRowCell(cell)}</td>
                  ))}
                </TableData>
              );
            })}
          </tbody>
        </Table>
      </div>
      <FooterContainer>
        <div className="d-flex align-items-center gap-3">
          <Wrapper>Rows per page</Wrapper>
          <FormFields.DropdownField
            fieldName="tablePageNumber"
            isRequired={false}
            defaultValue={pageJumperOptions[0]}
            idPrefix="table_page_jumper_filed"
            onChangeHandler={(selectedElement) => {
              setPageSize(selectedElement?.value);
            }}
            value={{ label: pageSize, value: pageSize }}
            options={pageJumperOptions}
          />
          <Wrapper>{`${pageIndex * pageSize + 1}-${
            pageIndex * pageSize + page.length
          } of ${memberData.length}`}</Wrapper>
        </div>
        <div className="d-flex  ">
          <PaginationButton
            className={clsx(canPreviousPage ? "page-enable" : "page-disable")}
            variant=""
            onClick={() => {
              gotoPage(0);
            }}
            disabled={!canPreviousPage}
          >
            {" |< "}
          </PaginationButton>
          <PaginationButton
            className={clsx(canPreviousPage ? "page-enable" : "page-disable")}
            variant=""
            onClick={() => {
              previousPage();
            }}
            disabled={!canPreviousPage}
          >
            {" < "}
          </PaginationButton>
          <PaginationButton
            className={clsx(canNextPage ? "page-enable" : "page-disable")}
            variant=""
            onClick={() => {
              nextPage();
            }}
            disabled={!canNextPage}
          >
            {" > "}
          </PaginationButton>
          <PaginationButton
            className={clsx(canNextPage ? "page-enable" : "page-disable")}
            variant=""
            onClick={() => {
              gotoPage(pageCount && pageCount - 1);
            }}
            disabled={!canNextPage}
          >
            {" >| "}
          </PaginationButton>
        </div>
      </FooterContainer>
    </ContentWrapper>
  );
};

export default OverviewTable;
