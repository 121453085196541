import { BackIcon } from "./backIcon";
import { BackArrowIcon } from "./BackArrowIcon";
import { Cards } from "./cards";
import { SuccessCheck } from "./successCheckIcon";
import { TriangleArrowDown, TriangleArrowUp } from "./triangleArrow";
import { XIcon } from "./xIcon";
import { StripeLogo } from "./stripeLogo";
import { EditIcon } from "./EditIcon";

import { default as HomeIcon } from "./HomeIcon";
import { default as BriefCaseIcon } from "./BriefCaseIcon";
import { default as BarChartIcon } from "./BarChartIcon";
import { default as DarkModeIcon } from "./DarkModeIcon";
import { default as LightModeIcon } from "./LightModeIcon";
import { default as BellIcon } from "./BellIcon";
import { default as NovaBlack } from "./NovaBlack";
import { default as NovaWhite } from "./NovaWhite";
import { default as UserIcon } from "./UserIcon";
import { default as ViewIcon } from "./ViewIcon";
import { default as BriefCase } from "./BriefCase";
import { default as BagIcon } from "./BagIcon";
import { default as Envelope } from "./Envelope";
import { default as OpenEnvelope } from "./OpenEnvelope";
import { default as VerticalLineIcon } from "./VerticalLineIcon";
import { default as GreaterThanIcon } from "./GreaterThanIcon";
import { default as ResizeArrow } from "./ResizeArrow";
import { default as PhoneIcon } from "./PhoneIcon";
import { default as LocationIcon } from "./LocationIcon";
import { default as MailIcon } from "./MailIcon";
import { default as SadRatingIcon } from "./SadRatingIcon";
import { default as NormalRatingIcon } from "./NormalRatingIcon";
import { default as HappyRatingIcon } from "./HappyRatingIcon";
import { default as PlusCircleIcon } from "./PlusCircleIcon";
import { default as BriefCaseIconWithVerticalLine } from "./BriefCaseIconWithVerticalLine";
import { default as PaperClip } from "./PaperClip";
import { default as PdfIcon } from "./PdfIcon";
import { default as FileDownloadIcon } from "./FileDownloadIcon";
import { default as MessageIcon } from "./MessageIcon";
import { default as ActivityIcon } from "./ActivityIcon";
import { default as SummaryIcon } from "./SummaryIcon";
import { default as TickIcon } from "./TickIcon";
import { default as CrossIcon } from "./CrossIcon";
import { default as QualifierIcon } from "./QualifierIcon";
import { default as DashboardPageIcon } from "./DashboardPageIcon";
import { Arrow } from "./ArrowIcons";
import { RangeSlider } from "./RangeSlider";
import { WarningIcon } from "./WarningIcon";
import { PromoCodeIcon } from "./PromoCodeIcon";
import { default as LessThanIcon } from "./LessThanIcon";
import { default as UserProfileIcon } from "./UserProfileIcon";
import { default as BuildingIcon } from "./BuildingIcon";
import { default as MultiUserIcon } from "./MultiUserIcon";
import { default as EmailIcon } from "./EmailIcon";
import { default as LayoutIcon } from "./LayoutIcon";
import { default as Polygon } from "./Polygon";
import { default as ArchiveIcon } from "./ArchiveIcon";
import { default as DownloadIcon } from "./DownloadIcon";
import { default as AddImageIcon } from "./AddImageIcon";
import { default as PenIcon } from "./PenIcon";
import { default as LinkedinIcon } from "./LinkedinIcon";
import { default as FacebookIcon } from "./FacebookIcon";
import { default as TwitterIcon } from "./TwitterIcon";
import { default as InstagramIcon } from "./InstagramIcon";
import { default as YoutubeIcon } from "./YoutubeIcon";
import { default as RoketIcon } from "./RoketIcon";
import { default as MoonRoketIcon } from "./MoonRoketIcon";
import { default as Profile } from "./Profile";
import { default as AngelDown } from "./AngelDown";
import { default as SettingsWarningIcon } from "./SettingsWarningIcon";
import { default as EyeOffIcon } from "./EyeOffIcon";
import { default as FileDownloadBlueIcon } from "./FileDownloadBlueIcon";
import { default as EnvelopWithCircleIcon } from "./EnvelopWithCircleIcon";
import { Heart } from "./Heart";
import CreditCardIcon from "./CreditCardIcon";
import DoubleUserIcon from "./DoubleUserIcon";
import SettingsIcon from "./SettingsIcon";
import SignoutIcon from "./SignoutIcon";
import ThemeIcon from "./ThemeIcon";

export const ICONS = {
  BackIcon,
  BackArrowIcon,
  Cards,
  SuccessCheck,
  TriangleArrowDown,
  TriangleArrowUp,
  XIcon,
  HomeIcon,
  BriefCaseIcon,
  BarChartIcon,
  DarkModeIcon,
  LightModeIcon,
  BellIcon,
  NovaBlack,
  NovaWhite,
  UserIcon,
  RangeSlider,
  ViewIcon,
  BriefCase,
  StripeLogo,
  BagIcon,
  Envelope,
  OpenEnvelope,
  VerticalLineIcon,
  GreaterThanIcon,
  ResizeArrow,
  PhoneIcon,
  LocationIcon,
  MailIcon,
  SadRatingIcon,
  NormalRatingIcon,
  HappyRatingIcon,
  PlusCircleIcon,
  BriefCaseIconWithVerticalLine,
  PaperClip,
  PdfIcon,
  FileDownloadIcon,
  MessageIcon,
  ActivityIcon,
  SummaryIcon,
  LessThanIcon,
  TickIcon,
  CrossIcon,
  QualifierIcon,
  DashboardPageIcon,
  Arrow,
  EditIcon,
  WarningIcon,
  PromoCodeIcon,
  UserProfileIcon,
  BuildingIcon,
  MultiUserIcon,
  EmailIcon,
  LayoutIcon,
  Polygon,
  ArchiveIcon,
  DownloadIcon,
  AddImageIcon,
  PenIcon,
  LinkedinIcon,
  FacebookIcon,
  TwitterIcon,
  InstagramIcon,
  YoutubeIcon,
  RoketIcon,
  MoonRoketIcon,
  Profile,
  AngelDown,
  SettingsWarningIcon,
  EyeOffIcon,
  FileDownloadBlueIcon,
  EnvelopWithCircleIcon,
  Heart,
  CreditCardIcon,
  DoubleUserIcon,
  SettingsIcon,
  SignoutIcon,
  ThemeIcon,
};
