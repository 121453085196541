import { Grid } from "antd";
import clsx from "clsx";
import {
  Cell,
  ColumnInstance,
  HeaderGroup,
  Row,
  usePagination,
  useTable,
} from "react-table";

import { CustomTable, TableProps } from "./Receipts";
import {
  customColumnCell,
  tableColumns,
  tableColumnsDesktop,
  tableColumnsMobile,
} from "./utils";
import { PaginationButton, Table } from "./ReceiptsStyle";
import { useAppSelector } from "src/Redux";

const ReceiptsTable = () => {
  const { receiptData } = useAppSelector((state) => state.settings);
  const screen = Grid.useBreakpoint();

  const {
    getTableBodyProps,
    getTableProps,
    headerGroups,
    page,
    prepareRow,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    gotoPage,
    pageCount,
  }: CustomTable.TableOptions<TableProps.Data> = useTable(
    {
      columns: screen.lg
        ? screen.xl
          ? tableColumns
          : tableColumnsDesktop
        : tableColumnsMobile,
      data: receiptData,
    },
    usePagination
  );
  
  return (
    <>
      <Table {...getTableProps()} className="table  mb-0">
        <thead>
          {headerGroups.map((headerGroup: HeaderGroup) => (
            <tr className="table-header" {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: ColumnInstance) => (
                <th {...column.getHeaderProps()}>{customColumnCell(column)}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="table-body" {...getTableBodyProps()}>
          {page.map((row: Row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell: Cell) => (
                  <td {...cell.getCellProps()}>{cell.value}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div className="d-flex justify-content-end mt-4 ">
        <PaginationButton
          className={clsx(canPreviousPage ? "page-enable" : "page-disable")}
          variant=""
          onClick={() => {
            gotoPage(0);
          }}
          disabled={!canPreviousPage}
        >
          {" |< "}
        </PaginationButton>
        <PaginationButton
          className={clsx(canPreviousPage ? "page-enable" : "page-disable")}
          variant=""
          onClick={() => {
            previousPage();
          }}
          disabled={!canPreviousPage}
        >
          {" < "}
        </PaginationButton>
        <PaginationButton
          className={clsx(canNextPage ? "page-enable" : "page-disable")}
          variant=""
          onClick={() => {
            nextPage();
          }}
          disabled={!canNextPage}
        >
          {" > "}
        </PaginationButton>
        <PaginationButton
          className={clsx(canNextPage ? "page-enable" : "page-disable")}
          variant=""
          onClick={() => {
            gotoPage(pageCount && pageCount - 1);
          }}
          disabled={!canNextPage}
        >
          {" >| "}
        </PaginationButton>
      </div>
    </>
  );
};

export default ReceiptsTable;
