import styled from "styled-components";
import { Button } from "react-bootstrap";

import { theme } from "src/components/Theme";

export const NotificationPageContainer = styled.div`
  padding: 40px 127px 251px 123px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  @media (max-width: 991px) {
    padding: 10px 8px 89px;
    gap: 10px;
    & > label {
      color: ${theme.colors.primary_default};
      margin-left: 12px;
    }
  }
`;

export const Container = styled.div`
  border-radius: 5px;
  background: ${theme.colors.white_pure};
  box-shadow: 1px 1px 7px 0px rgba(154, 154, 204, 0.1);
  padding: 20px 24px 26px 20px;
  @media (max-width: 991px) {
    padding: 20px 16px 40px 12px;
  }
  .badge {
    cursor: pointer;
    label {
      cursor: pointer;
    }
  }
  .page-notification-count {
    color: rgba(0, 0, 0, 0.54);
  }
  .dark-theme & {
    background: ${theme.colors.black110};
    .page-notification-count {
      color: ${theme.colors.setting_grey};
    }
  }
`;
export const NotificationDetail = styled.div`
  padding: 40px 119px 115px 71px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  .notification-label {
    ${theme.text.inputRegular12pt}
  }
  @media (max-width: 991px) {
    padding: 34px 0 38px;
    gap: 24px;
  }
  .notification-subtext {
    padding: 1px 6px;
    border-radius: 3px;
    background: ${theme.colors.grey20};
    min-width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    .dark-theme & {
      background: ${theme.colors.black120};
      color: ${theme.colors.black70};
    }
  }
`;

export const PaginationButton = styled(Button)`
  color: ${theme.colors.black_pure};
  font-weight: 500;
  font-size: 18px;
  border: none;
  &:disabled {
    color: ${theme.colors.black50};
    border: none;
    cursor: not-allowed;
    pointer-events: visible;
  }
  &:active {
    border: none;
  }
`;
