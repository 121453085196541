import { toast } from "react-toastify";

import { API_INSTANCES } from "src/API_INSTANCES";
import store, { actions } from "src/Redux";

const dispatch = store.dispatch;

const getEmailNotificationSettings = () => {
  API_INSTANCES.EMAIL_NOTIFICATION.getEmailNotificationSettings()
    .then((res) => dispatch(actions.settings.setEmailNotification(res.data)))
    .catch((error) => console.log(error))
    .finally(() => dispatch(actions.common.setPageLoading(false)));
};

const updateEmailNotificationSettings = (key: string, value: boolean) => {
  toast.promise(
    API_INSTANCES.EMAIL_NOTIFICATION.updateEmailNotificationSettings(
      key,
      value
    ),
    {
      pending: "Promise is pending",
      success: {
        render({ data }) {
          dispatch(actions.settings.setEmailNotification(data?.data));
          return "Promise resolved 👌";
        },
      },
      error: {
        render({ data }) {
          console.log(data);
          return "Promise rejected 🤯";
        },
      },
    }
  );
};

export const EMAIL_NOTIFICATION = {
  getEmailNotificationSettings,
  updateEmailNotificationSettings,
};
