import { Typography } from "../../../Basic";
import { RowCellContainer } from "../JobStyle";
import { IJobs } from "../Job";

export const View = ({ cell, className}: IJobs.CustomCellItem) => {
  return (
    <RowCellContainer className={className}>
      <Typography
        content={cell.value}
        styleName="tableSubHeading"
        color="blue_grey"
      />
    </RowCellContainer>
  );
};
