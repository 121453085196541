import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import Cards from "./Card";
import { ICONS } from "src/assets/custom_Icons";
import { colors } from "../Theme/Colors";
import { useAppSelector } from "src/Redux";

const CardGroup = () => {
  const { active_jobs, unread_candidates, views, applications } =
    useAppSelector((state) => state.dashboard);
  return (
    <Container>
      <Wrapper>
        <Cards
          icon={
            <CardIcon
              background="rgba(78, 194, 240,0.25)"
              className="dashboard-bagicon"
            >
              <ICONS.BriefCase color="info_default" />
            </CardIcon>
          }
          title="Active Jobs"
          totalCount={active_jobs.active_jobs}
        />
        <Cards
          icon={
            <CardIcon background="rgba(252, 125, 154,0.25)">
              <FontAwesomeIcon
                icon={faEyeSlash}
                style={{ color: colors.secondary_icon }}
                size="lg"
              />
            </CardIcon>
          }
          title="Unread Candidates"
          totalCount={unread_candidates.unread}
        />
      </Wrapper>
      <Wrapper>
        <Cards
          icon={
            <CardIcon background="rgba(36, 213, 184,0.25)">
              <FontAwesomeIcon
                icon={faEye}
                style={{ color: colors.success_default }}
                size="lg"
              />
            </CardIcon>
          }
          title="Views"
          totalCount={views.views}
          isShownLastWeek={true}
          isBottom={true}
        />
        <Cards
          icon={
            <CardIcon
              background="rgba(255, 189, 90,0.25)"
              className="dashboard-usericon"
            >
              <ICONS.UserIcon color="warning_default" />
            </CardIcon>
          }
          title="Applications"
          totalCount={applications.applications}
          isShownLastWeek={true}
          isBottom={true}
        />
      </Wrapper>
    </Container>
  );
};

export default CardGroup;

const CardIcon = styled.div<{ background: string }>`
  height: 40px;
  width: 40px;
  position: absolute;
  top: 16px;
  border-radius: 5px;
  right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ background }) => background};
  @media (max-width: 991px) {
    top: 48px;
    right: 20px;
  }
`;
export const Wrapper = styled.div`
  display: flex;
  gap: 20px;
  @media (max-width: 991px) {
    flex-direction: column;
  }
`;
export const Container = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  width: 100%;
  @media (max-width: 991px) {
    gap: 20px;
  }
`;
