import { useEffect } from "react";
import styled from "styled-components";
import clsx from "clsx";
import { Grid } from "antd";

import { Typography } from "src/components/Basic";
import { theme } from "src/components/Theme";
import { ICONS } from "src/assets/custom_Icons";
import { useAppSelector } from "src/Redux";
import { API_CALLS } from "src/API_CALLS";

const Qualifiers = () => {
  const screen = Grid.useBreakpoint();
  const { qualifiers, selectedCandidate } = useAppSelector(
    (state) => state.candidate
  );

  useEffect(() => {
    if (qualifiers.length === 0 && selectedCandidate.section_qualifiers)
      API_CALLS.PIPELINE.getCandidateQualifiers(selectedCandidate.candidate_id);
  }, []);
  
  return (
    <QualifiersContainer>
      <HeadingContainer className="heading">
        <Typography
          content="Qualifying Questions"
          styleName="candidateNameEmailOpen"
          color="primary_default"
        />
      </HeadingContainer>
      <QualifierTimelineContainer>
        <TimelineContainer className="vtimeline">
          {qualifiers?.map((qualifier, index) => (
            <div
              className="timeline-wrapper timeline-inverted timeline-wrapper-primary"
              key={index}
            >
              <div
                className={clsx(
                  "timeline-badge ",
                  qualifier.reject_criteria !== "True" && "not-qualifier"
                )}
              >
                {qualifier.reject_criteria === "True" ? (
                  <ICONS.TickIcon />
                ) : (
                  <ICONS.CrossIcon />
                )}
              </div>
              <Container>
                <div className="d-flex flex-column title-ideal">
                  <Typography
                    content={qualifier.qualifying_question}
                    styleName={screen.lg ? "subtitle3" : "qualifiersIdel"}
                    color="black80"
                  />
                  <Typography
                    content={`Ideal : ${qualifier.ideal_answer}`}
                    styleName="qualifiersIdel"
                    color="black70"
                  />
                </div>
                <div>
                  <Typography
                    content={`Response : ${qualifier.candidate_answer}`}
                    styleName={
                      screen.lg
                        ? "qualifiersResponse"
                        : "qualifiersResponseMobile"
                    }
                    color="primary_default"
                  />
                </div>
              </Container>
            </div>
          ))}
        </TimelineContainer>
      </QualifierTimelineContainer>
    </QualifiersContainer>
  );
};

export default Qualifiers;

const QualifiersContainer = styled.div`
  margin: 20px 38px;
  @media (max-width: 991px) {
    margin: 16px 32px 16px 16px;
  }
`;

const HeadingContainer = styled.div`
  .dark-theme &.no-data-msg label {
    color: ${theme.colors.black10};
  }
  &.heading label {
    text-transform: uppercase;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 20px 0px 16px;
  .title-ideal {
    gap: 5px;
  }
  @media (max-width: 991px) {
    margin: 12px 0px 16px 50px;
    gap: 3px;
  }
  .dark-theme & .title-ideal label {
    &:first-child {
      color: ${theme.colors.white10};
    }
    &:last-child {
      color: ${theme.colors.black50};
    }
  }
`;
const QualifierTimelineContainer = styled.div`
  margin-top: 24px;
  @media (max-width: 991px) {
    margin-top: 20px;
  }
`;
const TimelineContainer = styled.div`
  &:before {
    left: 10px;
    background: ${theme.colors.primary10};
    @media (max-width: 991px) {
      left: 14px !important;
    }
  }
  & .timeline-wrapper .timeline-badge {
    left: -5px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${theme.colors.white_pure};
    border: 2px solid ${theme.colors.success_default};
    border-radius: 21px;
    top: 21px;
    &.not-qualifier {
      border: 2px solid ${theme.colors.black50};
    }
  }
  & .timeline-wrapper.timeline-inverted .timeline-panel {
    margin: 0px;
    width: 100%;
  }
  & .timeline-wrapper.timeline-inverted {
    padding-left: 60px;
  }
  .dark-theme & {
    .timeline-wrapper .timeline-badge {
      border: 2px solid ${theme.colors.success_default};
      background: #294363;
    }
    &::before {
      background: rgba(30, 150, 242, 0.2);
    }
  }
`;
