import { FormFieldsProps } from "src/components/Basic";
import { ICONS } from "src/assets/custom_Icons";
import { CreateJobComponentProps } from "../CreateJob";

const card_number: FormFieldsProps.InputIcon = {
  fieldName: "card_number",
  placeholder: "1234 1234 1234 1234",
  idPrefix: "checkout",
  isRequired: true,
  label: "Card number",
  icon: <ICONS.Cards.Visa />,
};
const card_expiry: FormFieldsProps.InputMMYY = {
  fieldName: "card_expiry",
  placeholder: "MM / YY",
  idPrefix: "checkout",
  isRequired: true,
  label: "Expiry",
  inputType: "MMYY",
};
const card_cvc: FormFieldsProps.Input = {
  fieldName: "card_cvc",
  placeholder: "CVC",
  idPrefix: "checkout",
  isRequired: true,
  label: "CVC",
  inputType: "number",
  textLimit: 3,
};
const card_name: FormFieldsProps.Input = {
  fieldName: "card_name",
  placeholder: "Name on card",
  idPrefix: "checkout",
  isRequired: true,
  label: "Name on card",
};
const checkout_first_name: FormFieldsProps.Input = {
  fieldName: "checkout_first_name",
  idPrefix: "checkout",
  isRequired: true,
  label: "First Name",
  placeholder: "First Name",
};
const checkout_last_name: FormFieldsProps.Input = {
  fieldName: "checkout_last_name",
  idPrefix: "checkout",
  isRequired: true,
  label: "Last Name",
  placeholder: "Last Name",
};
const checkout_email: FormFieldsProps.Input = {
  fieldName: "checkout_email",
  idPrefix: "checkout",
  isRequired: true,
  label: "Email",
  placeholder: "Email",
  inputType: "email",
};
const checkout_country: FormFieldsProps.CountrySelector = {
  fieldName: "checkout_country",
  idPrefix: "checkout",
  isRequired: true,
  label: "Country",
  placeholder: "Select...",
  citySearchName: "checkout_city",
};
const checkout_city: FormFieldsProps.CitySearch = {
  fieldName: "checkout_city",
  idPrefix: "checkout",
  isRequired: true,
  label: "City",
  placeholder: "City",
  countrySelectorName: "checkout_country",
};
const checkout_street_address_1 = {
  fieldName: "checkout_street_address_1",
  idPrefix: "checkout",
  isRequired: true,
  label: "Street Address",
  placeholder: "Street Address",
};
const checkout_street_address_2 = {
  fieldName: "checkout_street_address_2",
  idPrefix: "checkout",
  isRequired: false,
  placeholder: " ",
};
const checkout_postal_code = {
  fieldName: "checkout_postal_code",
  idPrefix: "checkout",
  isRequired: false,
  label: "Zip/Postal code",
  placeholder: "Postal code",
};
const checkout_province = {
  fieldName: "checkout_province",
  idPrefix: "checkout",
  isRequired: false,
  label: "State/Province",
  placeholder: "State/Province",
};

const CheckoutFormFields = {
  card_number,
  card_expiry,
  card_cvc,
  card_name,
  checkout_first_name,
  checkout_last_name,
  checkout_email,
  checkout_country,
  checkout_city,
  checkout_street_address_1,
  checkout_street_address_2,
  checkout_postal_code,
  checkout_province,
};

const cardIconProps = { width: 33.5, height: 25 };
const cardTypeIcon: CreateJobComponentProps.CardTypeIcon = {
  Visa: <ICONS.Cards.Visa {...cardIconProps} />,
  Amex: <ICONS.Cards.Amex {...cardIconProps} />,
  Discover: <ICONS.Cards.Discover {...cardIconProps} />,
  Mastercard: <ICONS.Cards.Mastercard {...cardIconProps} />,
};

const cards: CreateJobComponentProps.Cards = [
  {
    cardType: "Visa",
    cardNumber: "1234567890123456",
    cvc: 123,
    expiry: "06/25",
    name: "Gopi krishnan",
  },
  {
    cardType: "Mastercard",
    cardNumber: "1023456123456789",
    cvc: 123,
    expiry: "06/25",
    name: "Gopi krishnan",
  },
];

export const CheckoutUtils = { CheckoutFormFields, cardTypeIcon, cards };
