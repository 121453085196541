import { theme } from "src/components/Theme";
import styled from "styled-components";

const PoweredByText = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="75"
    height="13"
    viewBox="0 0 75 13"
    fill="none"
  >
    <path
      d="M4.37971 2.78552C5.3384 2.78552 6.16012 3.13739 6.83348 3.82977C7.50684 4.52215 7.84923 5.37344 7.84923 6.37229C7.84923 7.37114 7.50684 8.22243 6.83348 8.91481C6.16012 9.60719 5.3384 9.95906 4.37971 9.95906C3.24984 9.95906 2.38246 9.50504 1.78899 8.60835V12.5016H0.602051V2.96713H1.78899V4.13624C2.39387 3.23954 3.24984 2.78552 4.37971 2.78552ZM4.23135 8.81265C4.91612 8.81265 5.48676 8.57429 5.95469 8.10892C6.42262 7.64355 6.66229 7.06467 6.66229 6.37229C6.66229 5.67991 6.43403 5.10103 5.9661 4.63566C5.49818 4.17029 4.91612 3.93193 4.23135 3.93193C3.53516 3.93193 2.9531 4.17029 2.49659 4.63566C2.02866 5.10103 1.8004 5.67991 1.8004 6.37229C1.8004 7.06467 2.02866 7.64355 2.49659 8.10892C2.96452 8.57429 3.53516 8.81265 4.23135 8.81265Z"
      fill="#1E96F2"
    />
    <path
      d="M15.0506 8.91481C14.3431 9.60719 13.4985 9.94771 12.4942 9.94771C11.4898 9.94771 10.6339 9.60719 9.93768 8.91481C9.24149 8.22243 8.8877 7.38249 8.8877 6.37229C8.8877 5.36209 9.24149 4.5108 9.93768 3.82977C10.6339 3.13739 11.4898 2.79688 12.4942 2.79688C13.4985 2.79688 14.3545 3.13739 15.0506 3.82977C15.7582 4.52216 16.1006 5.37345 16.1006 6.37229C16.1006 7.37114 15.7582 8.23378 15.0506 8.91481ZM12.4942 8.80131C13.1789 8.80131 13.7496 8.57429 14.2175 8.10892C14.6854 7.64355 14.9137 7.06467 14.9137 6.38364C14.9137 5.70261 14.6854 5.11238 14.2175 4.65836C13.7496 4.19299 13.1789 3.96598 12.4942 3.96598C11.8208 3.96598 11.2502 4.19299 10.7822 4.65836C10.3143 5.10103 10.086 5.67991 10.086 6.37229C10.086 7.06467 10.3143 7.64355 10.7822 8.09757C11.2502 8.56294 11.8208 8.80131 12.4942 8.80131Z"
      fill="#1E96F2"
    />
    <path
      d="M25.3111 2.96716H26.4296L24.4323 9.77748H23.2111L21.5791 4.66974L19.9585 9.77748H18.6802L16.6602 2.96716H17.9042L19.4107 8.14301L21.0427 2.96716H22.2182L23.8274 8.14301L25.3111 2.96716Z"
      fill="#1E96F2"
    />
    <path
      d="M28.347 6.91712C28.4611 7.53005 28.7464 7.99542 29.1915 8.33593C29.6366 8.67645 30.1844 8.83536 30.8236 8.83536C31.7252 8.83536 32.3871 8.50619 32.798 7.83651L33.8137 8.41539C33.1518 9.43693 32.1475 9.95906 30.8007 9.95906C29.7165 9.95906 28.8263 9.61854 28.153 8.94886C27.4682 8.26783 27.1372 7.41654 27.1372 6.38364C27.1372 5.36209 27.4682 4.49945 28.1415 3.81842C28.8149 3.13739 29.6709 2.79688 30.7323 2.79688C31.7366 2.79688 32.5583 3.14874 33.186 3.85247C33.8252 4.55621 34.1333 5.4075 34.1333 6.39499C34.1333 6.56525 34.1219 6.74686 34.0876 6.92847H28.347V6.91712ZM30.7209 3.90923C30.0817 3.90923 29.5567 4.09084 29.1345 4.4427C28.7122 4.80592 28.4497 5.28264 28.3584 5.88422H32.9349C32.8322 5.23724 32.5812 4.74917 32.1703 4.40865C31.748 4.07948 31.2687 3.90923 30.7209 3.90923Z"
      fill="#1E96F2"
    />
    <path
      d="M36.9633 4.11354C37.3513 3.27361 38.0704 2.84229 39.0975 2.84229V4.07949C38.5155 4.05679 38.0133 4.20435 37.591 4.54486C37.1687 4.88538 36.9633 5.41886 36.9633 6.16799V9.77746H35.7764V2.96714H36.9633V4.11354Z"
      fill="#1E96F2"
    />
    <path
      d="M40.7298 6.91712C40.8439 7.53005 41.1292 7.99542 41.5743 8.33593C42.0194 8.67645 42.5673 8.83536 43.2064 8.83536C44.108 8.83536 44.7699 8.50619 45.1808 7.83651L46.1966 8.41539C45.5346 9.43693 44.5303 9.95906 43.1836 9.95906C42.0993 9.95906 41.2091 9.61854 40.5358 8.94886C39.851 8.26783 39.52 7.41654 39.52 6.38364C39.52 5.36209 39.851 4.49945 40.5244 3.81842C41.1977 3.13739 42.0537 2.79688 43.1151 2.79688C44.1194 2.79688 44.9411 3.14874 45.5688 3.85247C46.208 4.55621 46.5161 5.4075 46.5161 6.39499C46.5161 6.56525 46.5047 6.74686 46.4705 6.92847H40.7298V6.91712ZM43.1037 3.90923C42.4645 3.90923 41.9395 4.09084 41.5173 4.4427C41.095 4.80592 40.8325 5.28264 40.7412 5.88422H45.3178C45.215 5.23724 44.964 4.74917 44.5531 4.40865C44.1308 4.07948 43.6515 3.90923 43.1037 3.90923Z"
      fill="#1E96F2"
    />
    <path
      d="M53.5348 0.243042H54.7218V9.77749H53.5348V8.60838C52.9414 9.50508 52.074 9.9591 50.9441 9.9591C49.9854 9.9591 49.1637 9.60723 48.4904 8.91485C47.817 8.22247 47.4746 7.37118 47.4746 6.37233C47.4746 5.37348 47.817 4.52219 48.4904 3.82981C49.1637 3.13743 49.9854 2.78556 50.9441 2.78556C52.074 2.78556 52.9414 3.23958 53.5348 4.13627V0.243042ZM51.1039 8.81269C51.8001 8.81269 52.3821 8.57433 52.8387 8.10896C53.3066 7.64359 53.5348 7.06471 53.5348 6.37233C53.5348 5.67995 53.3066 5.10107 52.8387 4.6357C52.3707 4.17033 51.7887 3.93197 51.1039 3.93197C50.4191 3.93197 49.8485 4.17033 49.3806 4.6357C48.9126 5.10107 48.6844 5.67995 48.6844 6.37233C48.6844 7.06471 48.9126 7.64359 49.3806 8.10896C49.8371 8.57433 50.4191 8.81269 51.1039 8.81269Z"
      fill="#1E96F2"
    />
    <path
      d="M63.7381 2.78556C64.6968 2.78556 65.5185 3.13743 66.1919 3.82981C66.8652 4.52219 67.2076 5.37348 67.2076 6.37233C67.2076 7.37118 66.8652 8.22247 66.1919 8.91485C65.5185 9.60723 64.6968 9.9591 63.7381 9.9591C62.6082 9.9591 61.7409 9.50508 61.1474 8.60838V9.77749H59.9604V0.243042H61.1474V4.13627C61.7409 3.23958 62.6082 2.78556 63.7381 2.78556ZM63.5897 8.81269C64.2745 8.81269 64.8452 8.57433 65.3131 8.10896C65.781 7.64359 66.0093 7.06471 66.0093 6.37233C66.0093 5.67995 65.781 5.10107 65.3131 4.6357C64.8452 4.17033 64.2745 3.93197 63.5897 3.93197C62.8936 3.93197 62.3115 4.17033 61.855 4.6357C61.3871 5.10107 61.1588 5.67995 61.1588 6.37233C61.1588 7.06471 61.3871 7.64359 61.855 8.10896C62.3115 8.57433 62.8936 8.81269 63.5897 8.81269Z"
      fill="#1E96F2"
    />
    <path
      d="M73.131 2.96716H74.4093L71.6131 10.1975C71.3164 10.9693 70.9169 11.5595 70.3919 11.9568C69.8669 12.3541 69.262 12.5357 68.5773 12.4903V11.3893C69.4104 11.4574 70.0267 10.992 70.4147 9.99314L70.5403 9.73208L67.5615 2.96716H68.8398L71.1223 8.18841L73.131 2.96716Z"
      fill="#1E96F2"
    />
  </svg>
);
const DittoJobs = () => (
  <div>
    <Ditto>ditto</Ditto>
    <Dot>.</Dot>
    <Ditto>jobs</Ditto>
  </div>
);
const Ditto = styled.span`
  color: ${theme.colors.nova_black};
  font-family: Gilroy, sans-serif;
  font-size: 17.2px;
  font-style: normal;
  font-weight: 800;
  line-height: 19.2px; /* 111.628% */
  letter-spacing: -0.2px;
`;
const Dot = styled.span`
  color: ${theme.colors.primary_default};
  font-family: Gilroy, sans-serif;
  font-size: 17.2px;
  font-style: normal;
  font-weight: 800;
  line-height: 19.2px;
`;
export const PoweredBy = () => (
  <Container>
    <div>
      <PoweredByText />
    </div>
    <DittoJobs />
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: baseline;
  justify-content: center;
  padding: 50px 0 30px 0;
`;
