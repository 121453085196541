import { EffectsProps } from "./EffectsProps";

const Input: EffectsProps.Input = {
  hover_active: `0px 0px 3px #24D5B8`,
  error: `0px 0px 3px #F74F75`,
  photoBorderShadow: `0px 0.535714px 2.14286px rgba(21, 21, 21, 0.02),
  0px 4.28571px 6.42857px rgba(21, 21, 21, 0.08)`,
  candidateListShadow: ` drop-shadow(1px 1px 5px rgba(154, 154, 204, 0.5))`,
  candidateBoxShadow: ` 0px 0px 1px 0px #24D5B8 inset, 0px 0px 3px 0px #24D5B8`,
  candidateDropShadow: `drop-shadow(0px 0px 3px #24d5b8)`,
  profileShadow: `0px 1px 4px rgba(21, 21, 21, 0.02), 0px 8px 12px rgba(21, 21, 21, 0.08)`,
  activityShadow: `0px 1px 16px rgba(0, 0, 0, 0.05)`,
};
const Job: EffectsProps.job = {
  job_card_shadow: `inset 0px 3px 3px rgba(179, 179, 179, 0.25)`,
  hover_active_blue: `0px 0px 3px #1E96F2, inset 0px 0px 1px #1E96F2;`,
  card_selected: `-3px 7px 7px 0px rgba(108, 115, 126, 0.18) inset,
    -1px 4px 0px 0px rgba(185, 205, 237, 0.03) inset,
    1px 4px 0px 0px rgba(196, 196, 196, 0) inset`,
};
export const Effects: EffectsProps.props = { ...Input, ...Job };
