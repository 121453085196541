import { createSlice } from "@reduxjs/toolkit";
import { activityData } from "src/components/Pipeline/Utils";
import CandidateProps from "./Candidate";

const initialState: CandidateProps.CandidateSlice = {
  candidateListData: {
    applicants: [],
    results: {
      total_applicants: -1,
      total_pages: 0,
    },
    query: {
      job_id: -1,
      page: -1,
      size: -1,
      sort: "",
    },
  },
  selectedCandidate: {
    application_id: "",
    candidate_id: -1,
    name: "",
    current_job_title: "",
    candidate_rating: -1,
    profile_picture: "",
    phone: "",
    email: "",
    location: "",
    application_date: "",
    section_experience: 0,
    section_files: 0,
    section_comments: 0,
    section_activity: 0,
    section_qualifiers: 0,
    recent_positions: {
      job_title: "",
      company: "",
      to_date: "",
      previous_positions: "",
    },
    key_skills: [],
    work_experience: "",
    languages: [],
    notice_period: "",
    citizenship: "",
    own_transport: true,
    willing_to_relocate: true,
    current_remuneration: "",
    desired_remuneration: "",
    bio: ``,
    pipeline_status: 0,
    template_tags: [
      {
        candidate_first_name: "",
        candidate_last_name: "",
        job_applied_to: "",
        your_company_name: "",
        your_first_name: "",
        your_last_name: "",
      },
    ],
  },
  pageSize: 10,
  careerHistory: [],
  educationHistory: [],
  files: [],
  selectedFile: {} as CandidateProps.Files,
  comment: [],
  activity: activityData,
  qualifiers: [],
  candidateOverviewLoading: false,
  step: 0,
  isFilePreview: false,
  activePage: 1,
};

const candidateSlice = createSlice({
  name: "candidate",
  initialState,
  reducers: {
    setIsEmailOpen: (state, action) => {
      state.candidateListData.applicants[
        action.payload.applicant_index
      ].is_read = action.payload.is_read;
    },
    setCandidateRating: (state, action) => {
      state.selectedCandidate.candidate_rating = action.payload.rating;
      const findIndex = state.candidateListData.applicants.findIndex(
        (item) => item.application_id === action.payload.application_id
      );
      state.candidateListData.applicants[findIndex] = {
        ...state.candidateListData.applicants[findIndex],
        candidate_rating: action.payload.rating,
      };
    },
    setCandidatelist: (state, action) => {
      state.candidateListData = action.payload;
    },
    setSelectedCandidate: (state, action) => {
      state.selectedCandidate = {
        ...action.payload,
      };
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
    setCareerHistory: (state, action) => {
      state.careerHistory = action.payload;
    },
    setEducationHistory: (state, action) => {
      state.educationHistory = action.payload;
    },
    setFiles: (state, action) => {
      state.files = action.payload;
    },
    setQualifiers: (state, action) => {
      state.qualifiers = action.payload;
    },
    setComments: (state, action) => {
      state.comment = action.payload;
      state.selectedCandidate.section_comments = action.payload.length;
    },
    resetCandidateOverviewDetails: (state) => {
      state.files = [];
      state.educationHistory = [];
      state.careerHistory = [];
      state.qualifiers = [];
      state.comment = [];
      state.selectedFile = {} as CandidateProps.Files;
    },
    setSelectedFile: (state, action) => {
      state.selectedFile = action.payload;
    },
    setCandidateOverviewLoading: (state, action) => {
      state.candidateOverviewLoading = action.payload;
    },
    setStep: (state, action) => {
      state.step = action.payload;
    },
    setIsFilePreview: (state, action) => {
      state.isFilePreview = action.payload;
    },
    setActivePage: (state, action) => {
      state.activePage = action.payload;
    },
  },
});

export const candidateActions = candidateSlice.actions;
export const candidateReducer = candidateSlice.reducer;
