import { CommonProps } from "src/Redux";
import instance from "src/axios/axios-user";
import { API_PATHS } from "src/utils/API_PATHS";

const getCurrencyDict = () => instance.get(API_PATHS.getCurrency);
const getSkills = () => instance.get(API_PATHS.getSkills);
const getIndustries = () => instance.get(API_PATHS.getIndustries);
const getCities = (data: CommonProps.cityAPIData) =>
  instance.post(API_PATHS.getCities, data);
const getCityById = (id: string) =>
  instance.get(`${API_PATHS.getCityById}/${id}`);

const toggleTheme = () => instance.get(API_PATHS.toggleTheme);

export const COMMON = {
  getCurrencyDict,
  getSkills,
  getIndustries,
  getCities,
  getCityById,
  toggleTheme,
};
