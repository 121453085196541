import { NotificationsProps } from "src/Redux/slices/Notification/Notification";
import { ICONS } from "src/assets/custom_Icons";

export const notificataIcons: NotificationsProps.NotificataIcons = {
  people: <ICONS.DoubleUserIcon />,
  campaign: <ICONS.BagIcon height={19} width={20} fill="#AD4FFA" />,
  comment: <ICONS.MessageIcon color="success_default" size={22} width={8} />,
  transaction: <ICONS.CreditCardIcon />,
};

export const notificataIconsBackground: NotificationsProps.NotificataIconsBackground =
  {
    people: "rgba(78, 194, 240,0.25)",
    campaign: "rgb(173, 79, 250 ,0.25)",
    comment: "rgb(36, 213 ,184,0.25)",
    transaction: "rgba(255, 189, 90,0.25)",
  };
