import { Outlet, useLocation } from "react-router-dom";
import { Grid } from "antd";

import {
  AuthBackGround,
  EndComma,
  NovaBlack,
  NovaBlackMobile,
  NovaPoweredBy,
  StartComma,
} from "../Icon";
import { AuthLayoutStyles } from "./AuthLayoutStyles";
import { ROUTES } from "src/utils/ROUTES";

const AuthLayoutWithBg = ({ children }: { children: JSX.Element }) => {
  return (
    <>
      <AuthLayoutStyles.AuthBackGround className="auth-bg">
        <div style={{ background: "rgba(255, 255, 255, 0.5)" }}>{children}</div>
      </AuthLayoutStyles.AuthBackGround>
    </>
  );
};

export const AuthLayoutTemplateLeft = () => {
  const textMsg = `Start your
business today`;
  return (
    <AuthLayoutStyles.AuthBackGroundWrapper className="auth-bg-wrapper">
      <AuthBackGround />
      <AuthLayoutStyles.TextContainer>
        <div>
          <StartComma />
        </div>
        <AuthLayoutStyles.PreContainer children={textMsg} />
        <div className="d-flex justify-content-end">
          <EndComma />
        </div>
      </AuthLayoutStyles.TextContainer>
    </AuthLayoutStyles.AuthBackGroundWrapper>
  );
};

const AuthLayout = () => {
  const screen = Grid.useBreakpoint();

  const location = useLocation();
  const isAuthBgNeeded =
    location.pathname === ROUTES.RESET || location.pathname === ROUTES.FORGOT;

  const AuthContainer = (
    <AuthLayoutStyles.AuthContainer className="auth-container">
      <AuthLayoutStyles.SubContainer>
        <AuthLayoutTemplateLeft />
        <AuthLayoutStyles.Wrapper>
          <AuthLayoutStyles.LogoContainer className="auth-logo-container">
            {screen.lg ? <NovaBlack /> : <NovaBlackMobile />}
            {screen.lg && <NovaPoweredBy />}
          </AuthLayoutStyles.LogoContainer>
          <AuthLayoutStyles.Line />
          <Outlet />
        </AuthLayoutStyles.Wrapper>
      </AuthLayoutStyles.SubContainer>
      {!screen.lg && (
        <div style={{ marginTop: "48px" }}>
          <NovaPoweredBy />
        </div>
      )}
    </AuthLayoutStyles.AuthContainer>
  );

  return isAuthBgNeeded ? (
    <AuthLayoutWithBg>{AuthContainer}</AuthLayoutWithBg>
  ) : (
    AuthContainer
  );
};
export default AuthLayout;
