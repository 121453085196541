import { SignIn } from "./SignIn";
import { Register } from "./Register";
import { Forgot } from "./Forgot";
import { Reset } from "./Reset";
import { OTP } from "./OTP";
import { Onboarding } from "./Onboarding";
export { default as AuthLayout } from "./Components/AuthLayout";

export const Auth = {
  SignIn,
  Register,
  Forgot,
  Reset,
  OTP,
  Onboarding,
};
