import { TermsAndPrivacyComponents } from "../Components";

const { Points, Text } = TermsAndPrivacyComponents;

export const NoticesAndServiceAddress = () => (
  <>
    <Points.Heading pointNumber="14." heading="Notices and Service Address" />
    <Points.Indent>
      <>
        <POINT_14_1 />
        <Points.Span pointNumber="14.2." Content={POINT_14_2} />
        <Points.Span pointNumber="14.3." Content={POINT_14_3} />
      </>
    </Points.Indent>
  </>
);

//#region 14.1
const POINT_14_1 = () => (
  <>
    <Points.SubHeading
      pointNumber="14.1."
      heading={`Each of the parties chooses their service address for the purposes of the giving of any notice, the serving of any process and for any other purposes arising from these Terms as being:`}
      needUnderline={false}
    />
    <Points.Indent>
      <>
        <Points.Span2 pointNumber="14.1.1." Content={POINT_14_1_1} />
        <Points.Span2 pointNumber="14.1.2." Content={POINT_14_1_2} />
      </>
    </Points.Indent>
  </>
);
const POINT_14_1_1 = (
  <Points.Content>
    <Text.Span content="in the case of Ditto Media, at " />
    <Text.Link content="legal@ditto.work" />
    <Text.Span content="; or" />
  </Points.Content>
);
const POINT_14_1_2 = (
  <Points.Content>
    <Text.Span content="in the case of the user, at the e-mail and addresses provided by the user to Ditto Media. " />
  </Points.Content>
);
//#endregion

const POINT_14_2 = (
  <Points.Content>
    <Text.Span content="Each of the parties will be entitled from time to time, by written notice to the other to vary its service address to any other address which is not a post office box or poste restante, provided that the change will become effective only 14 (fourteen) days after service of the notice in question." />
  </Points.Content>
);
const POINT_14_3 = (
  <Points.Content>
    <Text.Span content="Notwithstanding the above, any notice given in writing in English, and actually received by the party to whom the notice is addressed, will be deemed to have been properly given and received, notwithstanding that such notice has not been given in accordance with this clause." />
  </Points.Content>
);
