import instance from "src/axios/axios-user";
import { API_PATHS } from "src/utils/API_PATHS";

const getReceipts = () => instance.get(API_PATHS.receipts);

const downloadReceipt = (id: string) =>
  instance.get(`${API_PATHS.downloadReceipts}/${id}`, {
    responseType: "blob",
  });
  
export const RECEIPTS = { getReceipts, downloadReceipt };
