import styled, { css } from "styled-components";

import { FIELD_COLORS, FIELD_CSS } from "../../FormFieldsStyles/common";

interface RichTextAreaWrapperProps {
  minHeight?: number | string;
  hasError?: boolean;
  value: string;
  containerWidth: string;
  focus: boolean;
}

const defaultStyle = css<RichTextAreaWrapperProps>`
  .description-editor {
    padding: 0;
    position: relative;
    display: block;
    line-height: 28px;
    height: auto !important;
    min-height: 20em;
    border-radius: 6px !important;
    width: 100% !important;
    border: 1px solid ${FIELD_COLORS.FIELD_BORDER_COLOR};
    background: ${({ theme }) => theme.colors.white_pure};

    ${({ theme }) => theme.text.inputGeneral};
    color: ${FIELD_COLORS.FILLLED_TEXT_COLOR};

    @media (min-width: 768px) {
      min-width: 300px;
    }
  }
  .description-editor:hover {
    padding: 0;
    border: 1px solid ${FIELD_COLORS.FIELD_BORDER_COLOR};
    box-shadow: ${({ theme }) => theme.Effects.hover_active} !important;
  }
  .description-editor--focus {
    border: 1px solid ${FIELD_COLORS.FIELD_BORDER_COLOR};
    box-shadow: ${({ theme }) => theme.Effects.hover_active} !important;
  }
  .description-editor--focus.description-editor:hover {
    border: 1px solid ${FIELD_COLORS.FIELD_BORDER_COLOR};
    box-shadow: ${({ theme }) => theme.Effects.hover_active} !important;
  }

  .rdw-editor-main {
    min-height: ${(props) => `${props.minHeight}px !important`};
    max-height: ${(props) => `${props.minHeight}px !important`};
    overflow: scroll;
    padding: 0px 12px;
  }
  .DraftEditor-root,
  .DraftEditor-editorContainer,
  .public-DraftEditor-content {
    min-height: inherit;
  }
  .public-DraftEditorPlaceholder-inner {
    display: ${({ focus }) => (focus ? "none" : "block")};
    ${({ theme }) => theme.text.inputGeneral};
    color: ${FIELD_COLORS.PLACEHOLDER_COLOR_DIM};
    text-transform: capitalize !important;
  }

  /* Toolbar styling */
  .rdw-editor-toolbar {
    margin: 15px 12px 5px 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: ${({ theme }) => theme.colors.white_pure};
    box-shadow: 0px 2px 4px rgba(21, 21, 21, 0.08);
    border-radius: 6px;
    padding: 8px;

    @media (min-width: 768px) {
      padding: 8px 16px;
      margin-top: 10px;
    }
  }
  .rdw-dropdown-wrapper {
    border: 0;
    &:hover {
      box-shadow: none;
    }
  }
  .rdw-list-wrapper {
    display: flex;
    flex-direction: row;
    border: 0;
    margin: 0;
    &:hover {
      box-shadow: none;
    }
  }
  .rdw-link-wrapper {
    border: 0;
    margin: 0;
    &:hover {
      box-shadow: none;
    }
  }
  .rdw-option-wrapper {
    border: 0;
    border-radius: 2px;
    padding: 4px 10px;
    margin: 0 2px;
    &:hover {
      box-shadow: none;
    }
  }
  .rdw-option-wrapper:hover {
    background-color: ${({ theme }) => theme.colors.black10};
  }
  .rdw-option-active {
    background-color: ${({ theme }) => theme.colors.black10};
    box-shadow: none;
  }
  .rdw-inline-wrapper {
    display: flex;
    flex-direction: row;
    margin-bottom: 0;
  }
  .rdw-image-wrapper {
    margin-bottom: 0;
  }
  .rdw-text-align-wrapper {
    display: flex;
    flex-direction: row;
    margin-bottom: 0;
  }
`;
const errorStyle = css<RichTextAreaWrapperProps>`
  .description-editor {
    border: 1px solid ${FIELD_COLORS.ERROR_FIELD_BORDER_COLOR} !important;
  }
  .description-editor:hover {
    border: 1px solid ${FIELD_COLORS.ERROR_FIELD_BORDER_COLOR} !important;
    box-shadow: ${({ theme }) => theme.Effects.error} !important;
  }
  .description-editor--focus {
    border: 1px solid ${FIELD_COLORS.ERROR_FIELD_BORDER_COLOR} !important;
    box-shadow: ${({ theme }) => theme.Effects.error} !important;
  }
  .description-editor--focus.description-editor:hover {
    border: 1px solid ${FIELD_COLORS.ERROR_FIELD_BORDER_COLOR} !important;
    box-shadow: ${({ theme }) => theme.Effects.error} !important;
  }
`;
export const RichTextAreaContainer = styled.div<RichTextAreaWrapperProps>`
  ${defaultStyle};
  ${({ hasError }) => hasError && errorStyle};
  .rdw-embedded-wrapper {
    margin-bottom: 0;
  }
  .rdw-embedded-modal-header-option {
    width: 100%;
    align-items: start;
  }
  .rdw-embedded-modal-size-input {
    ${FIELD_CSS.TEXT};
  }
  .rdw-embedded-modal-link-input {
    ${FIELD_CSS.TEXT};
  }
  .rdw-embedded-modal-link-input::placeholder {
    ${FIELD_CSS.PLACEHOLDER};
  }
  .richtextarea-text-count {
    font-weight: 300;
    float: right;
  }
`;
