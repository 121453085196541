import styled from "styled-components";
import { TermsAndPrivacyComponents } from "../Components";
import { theme } from "src/components/Theme";

const { Points, Text } = TermsAndPrivacyComponents;

export const PurposeOfUse = () => (
  <>
    <Points.Heading
      pointNumber="5."
      heading="Purposes For Which We Will Use Your Personal Data"
    />
    <Points.Indent>
      <>
        <POINT_5_1 />
        <POINT_5_2 />
        <POINT_5_3 />
        <POINT_5_4 />
        <POINT_5_5 />
        <POINT_5_6 />
      </>
    </Points.Indent>
  </>
);

//#region 5.1
const POINT_5_1 = () => (
  <>
    <Points.SubHeading
      pointNumber="5.1."
      heading="We have set out below, in a table format, a description of"
      needUnderline={false}
    />
    <Points.Indent>
      <>
        <Points.Span2 pointNumber="5.1.1." Content={POINT_5_1_1} />
        <Points.Span2 pointNumber="5.1.2." Content={POINT_5_1_2} />
        <Points.Span2 pointNumber="5.1.3." Content={POINT_5_1_3} />
      </>
    </Points.Indent>
  </>
);
const POINT_5_1_1 = (
  <Text.Span content="the purpose for which we will process your personal data, " />
);
const POINT_5_1_2 = <Text.Span content="the type of data we process; and" />;
const POINT_5_1_3 = <Text.Span content="the lawful basis of processing." />;
//#endregion

//#region 5.2
const POINT_5_2 = () => (
  <>
    <Points.SubHeading
      pointNumber="5.2."
      heading="Note that we may process your personal data for more than one lawful ground depending on the specific purpose for which we are using your personal data."
      needUnderline={false}
    />
    <Table />
  </>
);
const Table = () => (
  <TableContainer className="table-wrapper">
    <table>
      <tr>
        <th>PURPOSE / ACTIVITY</th>
        <th>TYPE OF DATA</th>
        <th>LAWFUL BASIS AND BUSINESS INTEREST</th>
      </tr>

      {/* 1 */}
      <tr>
        <td>
          <Text.Span content="To engage with you after you have contacted us via the Platform" />
        </td>
        <td>
          <Points.Table pointNumber="a" content="Identity" />
          <Points.Table pointNumber="b" content="Contact" />
          <Points.Table pointNumber="c" content="Technical" />
          <Points.Table pointNumber="d" content="Usage" />
          <Points.Table
            pointNumber="e"
            content="Marketing and Communications"
          />
        </td>
        <td>
          <Points.Table pointNumber="a" content="Express consent" />
          <Points.Table
            pointNumber="b"
            content="Performance of a contract with you"
          />
          <Points.Table
            pointNumber="c"
            content="Necessary for our legitimate interests (keep records updated, study how users use our services, develop services, grow)"
          />
        </td>
      </tr>
      {/* 2 */}
      <tr>
        <td>
          <Text.Span content="To allow you to our Services and to provide you and Jobseekers with our Services as contracted" />
        </td>
        <td>
          <Points.Table pointNumber="a" content="Identity" />
          <Points.Table pointNumber="b" content="Contact" />
          <Points.Table pointNumber="c" content="Jobseeker" />
          <Points.Table pointNumber="d" content="Job Listing" />
          <Points.Table pointNumber="e" content="Usage" />
          <Points.Table pointNumber="f" content="Transaction" />
        </td>
        <td>
          <Points.Table
            pointNumber="a"
            content="Performance of a contract with you"
          />
          <Points.Table pointNumber="b" content="Express consent " />
          <Points.Table
            pointNumber="c"
            content="Necessary to comply with a legal obligation"
          />
          <Points.Table
            pointNumber="d"
            content="Necessary for our legitimate interests (provide you with Services you contracted to acquire, keep records updated, study how users use Services)"
          />
        </td>
      </tr>
      {/* 3 */}
      <tr>
        <td>
          <Text.Span content="To contract with you as a service provider to Ditto Media" />
        </td>
        <td>
          <Points.Table pointNumber="a" content="Identity" />
          <Points.Table pointNumber="b" content="Contact" />
          <Points.Table pointNumber="c" content="Financial" />
          <Points.Table pointNumber="d" content="Transaction" />
        </td>
        <td>
          <Points.Table
            pointNumber="a"
            content="Performance of a contract with you"
          />
          <Points.Table pointNumber="b" content="Express consent " />
          <Points.Table
            pointNumber="c"
            content="Necessary to comply with a legal obligation"
          />
          <Points.Table
            pointNumber="d"
            content="Necessary for our legitimate interests (provide us with your services, keep our records updated, and study how we may use third-party services)"
          />
        </td>
      </tr>
      {/* 4 */}
      <tr>
        <td>
          <Text.Span content="To provide it to our authorized service providers who need your personal data to provide their services to you (such as payment service providers)" />
        </td>
        <td>
          <Points.Table pointNumber="a" content="Identity" />
          <Points.Table pointNumber="b" content="Contact" />
          <Points.Table pointNumber="c" content="Financial" />
          <Points.Table pointNumber="d" content="Transaction" />
        </td>
        <td>
          <Points.Table
            pointNumber="a"
            content="Performance of a contract with you"
          />
          <Points.Table
            pointNumber="b"
            content="Necessary for our legitimate interests (provide you with Services you contracted from the third-party, develop our services, and grow)"
          />
          <Points.Table pointNumber="c" content="Express consent" />
        </td>
      </tr>
      {/* 5 */}
      <tr>
        <td>
          <Text.Div content="To process and service your payment for any services rendered by Ditto Media or its service providers." />
          <Text.Div content="To manage payments, fees, and charges" />
        </td>
        <td>
          <Points.Table pointNumber="a" content="Identity" />
          <Points.Table pointNumber="b" content="Contact" />
          <Points.Table pointNumber="c" content="Financial" />
          <Points.Table pointNumber="d" content="Transaction" />
        </td>
        <td>
          <Points.Table
            pointNumber="a"
            content="Performance of a contract with you"
          />
          <Points.Table
            pointNumber="b"
            content="Necessary for our legitimate interests (make or receive necessary payments)"
          />
          <Points.Table pointNumber="c" content="Express consent" />
        </td>
      </tr>
      {/* 6 */}
      <tr>
        <td>
          <Text.Span content="To manage our relationship with you which may include notifying you about changes to our Terms, Privacy Policy, or Services" />
        </td>
        <td>
          <Points.Table pointNumber="a" content="Identity" />
          <Points.Table pointNumber="b" content="Contact" />
          <Points.Table pointNumber="c" content="Jobseeker" />
          <Points.Table
            pointNumber="d"
            content="Marketing and Communications"
          />
        </td>
        <td>
          <Points.Table
            pointNumber="a"
            content="Performance of a contract with you"
          />
          <Points.Table
            pointNumber="b"
            content="Necessary to comply with a legal obligation"
          />
          <Points.Table
            pointNumber="c"
            content="Necessary for our legitimate interests (keep our records updated and study how users use our Services)"
          />
          <Points.Table pointNumber="d" content="Express consent" />
        </td>
      </tr>
      {/* 7 */}
      <tr>
        <td>
          <Text.Span content="To administer and protect our company, Platform, and services (including troubleshooting, data analysis, testing, system maintenance, support, reporting and hosting of data)" />
        </td>
        <td>
          <Points.Table pointNumber="a" content="Identity" />
          <Points.Table pointNumber="b" content="Contact" />
          <Points.Table pointNumber="c" content="Technical" />
          <Points.Table pointNumber="d" content="Usage" />
        </td>
        <td>
          <Points.Table
            pointNumber="a"
            content="Necessary for our legitimate interests (running our organization, provision of administration and IT, network security, to prevent fraud and organization restructuring exercise)"
          />
          <Points.Table
            pointNumber="b"
            content="Necessary to comply with a legal obligation"
          />
          <Points.Table pointNumber="c" content="Express consent" />
        </td>
      </tr>
      {/* 8 */}
      <tr>
        <td>
          <Text.Span content="To use data analytics to improve our Platform, Services, and client relationships and experiences" />
        </td>
        <td>
          <Points.Table pointNumber="a" content="Technical" />
          <Points.Table pointNumber="b" content="Usage" />
          <Points.Table pointNumber="c" content="Identity" />
          <Points.Table
            pointNumber="d"
            content="Marketing and Communications"
          />
        </td>
        <td>
          <Points.Table
            pointNumber="a"
            content="Necessary for our legitimate interests (define types of users, keep Platform updated, develop our organization and inform our marketing strategy)"
          />
          <Points.Table pointNumber="b" content="Express consent" />
        </td>
      </tr>
      {/* 9 */}
      <tr>
        <td>
          <Text.Span content="To provide you with direct marketing, make suggestions about services that may be of interest" />
        </td>
        <td>
          <Points.Table pointNumber="a" content="Identity" />
          <Points.Table pointNumber="b" content="Contact" />
          <Points.Table pointNumber="c" content="Technical" />
          <Points.Table pointNumber="d" content="Usage" />
          <Points.Table
            pointNumber="e"
            content="Marketing and Communications"
          />
        </td>
        <td>
          <Points.Table
            pointNumber="a"
            content="Necessary for our legitimate interests (develop our services and grow)"
          />
          <Points.Table pointNumber="b" content="Express consent" />
        </td>
      </tr>
    </table>
  </TableContainer>
);
const TableContainer = styled.div`
  width: 100;
  overflow-x: scroll;
  td {
    border: 1px solid ${theme.colors.black40};
    padding: 8px;
    vertical-align: top;
  }
  th {
    border: 1px solid ${theme.colors.black40};
    padding: 8px;
    text-align: center;
    background-color: ${theme.colors.black30};
  }
`;
//#endregion

//#region 5.3
const POINT_5_3 = () => (
  <>
    <Points.SubHeading pointNumber="5.3." heading="Marketing" />
    <Points.Indent>
      <>
        <Points.Span2 pointNumber="5.3.1." Content={POINT_5_3_1} />
        <Points.Span2 pointNumber="5.3.2." Content={POINT_5_3_2} />
      </>
    </Points.Indent>
  </>
);
const POINT_5_3_1 = (
  <Text.Span content="We strive to provide you with choices regarding certain personal data uses, particularly around marketing and advertising. To manifest your rights attached to any marketing sent to you as an existing user, please use the in-built prompts provided on those communications, or contact us." />
);
const POINT_5_3_2 = (
  <Text.Span content="You will receive marketing communications from us if you have requested our Services, requested information from us, or provided us with your details in any other circumstance and, in each case, have not opted-out of receiving that marketing." />
);
//#endregion

//#region 5.4
const POINT_5_4 = () => (
  <>
    <Points.SubHeading pointNumber="5.4." heading="Third-Party Marketing" />
    <Points.Indent>
      <Text.Span content="Whilst we may use your personal data within our company, we will get your express opt-in consent before we share your personal data publicly with any entity outside Ditto Media for marketing." />
    </Points.Indent>
  </>
);
//#endregion

//#region 5.5
const POINT_5_5 = () => (
  <>
    <Points.SubHeading pointNumber="5.5." heading="Opting-Out" />
    <Points.Indent>
      <Text.Span content="You can ask us to stop sending you marketing messages at any time by contacting us and requesting us to cease or change your marketing preferences. Where you opt-out of receiving these marketing messages, this opt-out will not apply to other personal data of yours which we process for another lawful basis." />
    </Points.Indent>
  </>
);
//#endregion

//#region 5.6
const POINT_5_6 = () => (
  <>
    <Points.SubHeading pointNumber="5.6." heading="Marketing" />
    <Points.Indent>
      <>
        <Points.Span2 pointNumber="5.6.1." Content={POINT_5_6_1} />
        <Points.Span2 pointNumber="5.6.2." Content={POINT_5_6_2} />
        <Points.Span2 pointNumber="5.6.3." Content={POINT_5_6_3} />
      </>
    </Points.Indent>
  </>
);
const POINT_5_6_1 = (
  <Text.Span content="We will only use your personal data for the purposes for which we collected it, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose. If you wish to get an explanation as to how the processing for the new purpose is compatible with the original purpose, please contact us." />
);
const POINT_5_6_2 = (
  <Text.Span content="If we need to use your personal data for an unrelated purpose, we will notify you and we will explain the legal basis which allows us to do so." />
);
const POINT_5_6_3 = (
  <Text.Span content="Please note that we may process your personal data without your knowledge or consent, in compliance with the above rules, where this is required or permitted by law." />
);
//#endregion
