import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

import { ROUTES } from "src/utils/ROUTES";
import { useAppSelector } from "src/Redux";
import { IconButton } from "../Basic";

export const CreateJobButton = () => {
  const { isEditFlow } = useAppSelector((store) => store.createJob);
  const navigate = useNavigate();

  return (
    <IconButton
      handleClick={() => navigate(ROUTES.CREATE_JOB)}
      icon={faPlus}
      position="right"
      label="Create Job"
      isDisable={isEditFlow}
    />
  );
};
