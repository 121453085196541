import { ColumnInstance } from "react-table";

export const customColumnCell = (column: ColumnInstance) => {
  return ["Profile", "Action"].includes(column.Header as string)
    ? ""
    : column.render("Header");
};

export const pageJumperOptions = [
  { value: 1, label: "1" },
  { value: 5, label: "5" },
  { value: 10, label: "10" },
  { value: 15, label: "15" },
];
export const tableColumns: any = [
  {
    Header: "Profile",
    accessor: "profile_picture",
    disableSortBy: true,
    maxWidth: "10%",
  },
  { Header: "First Name", accessor: "first_name", maxWidth: "20%" },
  { Header: "Last Name", accessor: "last_name", maxWidth: "20%" },
  { Header: "Email", accessor: "email", maxWidth: "20%" },
  { Header: "User Role", accessor: "user_role", maxWidth: "20%" },
  {
    Header: "Action",
    accessor: "is_active",
    disableSortBy: true,
    maxWidth: "10%",
  },
];
export const tableColumnsMobile: any = [
  {
    Header: "Profile",
    accessor: "profile_picture",
    disableSortBy: true,
    maxWidth: "20%",
  },
  { Header: "First Name", accessor: "first_name", maxWidth: "60%" },
  {
    Header: "Action",
    accessor: "is_active",
    disableSortBy: true,
    maxWidth: "20%",
  },
];
