import { Column, ColumnInstance } from "react-table";
import { ICONS } from "src/assets/custom_Icons";

export const pageJumperOptions = [
  { value: 5, label: "5" },
  { value: 10, label: "10" },
  { value: 15, label: "15" },
  { value: 25, label: "25" },
];

const emptyHeaders = ["UserPhoto", "Options"];
export const customColumnCell = (column: ColumnInstance) => {
  return emptyHeaders.includes(column.Header as string)
    ? ""
    : column.render("Header");
};

const userPhoto: Column = { Header: "UserPhoto", accessor: "profile_photo" };
const jobColumn: Column = { Header: "Job", accessor: "title" };
const department: Column = { Header: "Department", accessor: "department" };
const reference: Column = { Header: "Reference#", accessor: "reference" };
const campaign: Column = { Header: "Campaign Status", accessor: "campaign" };
const applications: Column = {
  Header: () => <ICONS.UserIcon height={16} width={16} />,
  accessor: "applications",
};
const unread: Column = {
  Header: () => <ICONS.ViewIcon height={16} width={16} />,
  accessor: "unread",
};
const dateCreated: Column = {
  Header: "Date Created",
  accessor: "date_created",
};
const options: Column = { Header: "Options" };

export const tableColumns: Column[] = [
  userPhoto,
  jobColumn,
  department,
  reference,
  dateCreated,
  campaign,
  applications,
  unread,
  options,
];
export const tableMobileColumns: Column[] = [jobColumn, dateCreated, options];

export const DropDownOption = {
  drafts: 0,
  archived: 1,
  active: 2,
};
