import React from "react";
import clsx from "clsx";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, FormikValues } from "formik";
import { Grid } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";

import { Buttons, CustomModal, FormFields, Typography } from "src/components/Basic";
import { ICONS } from "src/assets/custom_Icons";
import {
  Arrow,
  CandidateProfileContainer,
  Container,
  Detail,
  DetailContainer,
  ImageContainer,
  RatingContainer,
  Wrapper,
  ImgContainer,
  EmailIconContainer,
  ModalHeader,
  ButtonContainer,
  ModalFormContainer,
  TemplateSettingContainer,
  ModalBody,
} from "./CandidateProfileStyle";
import { actions, useAppDispatch, useAppSelector } from "src/Redux";
import { PipelineKey, pipelinePhaseData } from "../Utils";
import { candidateProfileFields, idPrefix } from "./utils";
import { theme } from "src/components/Theme";
import { API_CALLS } from "src/API_CALLS";
import { Tags } from "../PipeLine";
import { FormikHelpers } from "src/components/Basic/FormFields/FormikFields/FormikFields.helpers";
import { ROUTES } from "src/utils/ROUTES";
import { LOCAL_STORAGE_KEY } from "src/utils/LOCAL_STORAGE_KEYS";

const CandidateProfile = ({
  isRegretModalOpen,
  setIsRegretModalOpen,
  selectedRating,
  PageDataHandler,
}: {
  setIsRegretModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isRegretModalOpen: boolean;
  selectedRating: number[];
  PageDataHandler: () => void;
}) => {
  const {
    candidate_rating,
    profile_picture,
    application_date,
    application_id,
    candidate_id,
    email,
    name,
    current_job_title,
    phone,
    pipeline_status,
    location,
    template_tags,
  } = useAppSelector((state) => state.candidate.selectedCandidate);
  const { templates, selectedTemplates, tags } = useAppSelector(
    (state) => state.messageTemplate
  );
  const dispatch = useAppDispatch();
  const { job_id } = useParams();
  const screen = Grid.useBreakpoint();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const dragStart = (e: React.DragEvent, id: string) => {
    const target = document.getElementById("drag") as HTMLElement;
    target.style.width = "80px";
    target.style.height = "80px";
    return e.dataTransfer.setData("text", id + "_true");
  };

  const dragEnd = () => {
    const target = document.getElementById("drag") as HTMLElement;
    target.style.width = "25px";
    target.style.height = "25px";
  };

  const addRating = (rating: number) => {
    candidate_rating === rating
      ? API_CALLS.PIPELINE.clearCandidateReview({
          application_id,
          selectedRating,
          PageDataHandler,
        })
      : API_CALLS.PIPELINE.updateCandidateReview({
          application_id,
          rating,
          selectedRating,
          PageDataHandler,
        });
  };

  const convertMessageTagToValue = (message: string) => {
    let templateMessage = message;
    const regex = /{{(.*?)}}/;
    tags.map(
      (tag) =>
        (templateMessage = templateMessage.replaceAll(
          tag.tag,
          tag.tag.match(regex)
            ? template_tags[0][
                (tag?.tag?.match(regex) as RegExpMatchArray)[1] as keyof Tags
              ]
            : ""
        ))
    );
    return templateMessage;
  };

  const onSubmitHandler = (value: FormikValues) => {
    API_CALLS.PIPELINE.sendMessageCandidate({
      subject: value.subject,
      message: value.message,
      email,
      application_id,
    });
    setIsModalOpen(false);
  };

  return (
    <CandidateProfileContainer>
      <Container>
        <ImageContainer
          className="main-img-user"
          onDragStartCapture={(e) => e.preventDefault()}
        >
          <img
            alt="avatar"
            className="square"
            src={`data:image/png;base64,${profile_picture}`}
          />
          <Arrow>
            <ICONS.ResizeArrow />
          </Arrow>
        </ImageContainer>
        <ImgContainer
          onDragStartCapture={(e) => dragStart(e, String(application_id))}
          onDragEndCapture={dragEnd}
          id="drag"
        >
          <img
            alt="avatar"
            className="square"
            src={`data:image/png;base64,${profile_picture}`}
          />
        </ImgContainer>
        <div className="d-flex flex-column candidate-name-job">
          <Typography content={name} styleName="heading4" color="black80" />
          <Typography
            content={current_job_title}
            styleName="inputRegular12pt"
            color="black70"
          />
          <DetailContainer>
            <Detail onClick={() => window.open(`tel:${phone}`)}>
              <ICONS.PhoneIcon />
              <Typography
                content={phone}
                styleName="inputRegular12pt"
                color="black70"
              />
            </Detail>
            <Detail onClick={() => window.open(`mailto:${email}`)}>
              <ICONS.MailIcon />
              <Typography
                content={email}
                styleName="inputRegular12pt"
                color="black70"
              />
            </Detail>
            <Detail>
              <ICONS.LocationIcon />
              <Typography
                content={location}
                styleName="inputRegular12pt"
                color="black70"
              />
            </Detail>
          </DetailContainer>
          <RatingContainer className="emoji-icons">
            <Typography
              content="RATING:"
              styleName="pipelineDefaultNav"
              color="black100"
            />
            <div className="d-flex">
              <div
                className={clsx(
                  candidate_rating === 3 && "emoji-icon-active-2"
                )}
                onClick={() => addRating(3)}
              >
                <ICONS.HappyRatingIcon
                  color={candidate_rating === 3 ? "success_default" : "black30"}
                />
              </div>
              <div
                className={clsx(
                  candidate_rating === 2 && "emoji-icon-active-1"
                )}
                onClick={() => addRating(2)}
              >
                <ICONS.NormalRatingIcon
                  color={candidate_rating === 2 ? "warning_default" : "black30"}
                />
              </div>
              <div
                className={clsx(
                  candidate_rating === 1 && "emoji-icon-active-0"
                )}
                onClick={() => addRating(1)}
              >
                <ICONS.SadRatingIcon
                  color={candidate_rating === 1 ? "danger_default" : "black30"}
                />
              </div>
            </div>
          </RatingContainer>
        </div>
      </Container>
      <Wrapper>
        <div
          className={clsx(
            "d-flex",
            !screen.lg ? "flex-row-reverse justify-content-end gap-3" : "gap-4"
          )}
        >
          <EmailIconContainer
            onClick={() => {
              API_CALLS.MESSAGE_TEMPLATE.getMessageTemplate();
              API_CALLS.MESSAGE_TEMPLATE.getTag();
              dispatch(actions.messageTemplate.setSelectedTemplates({}));
              setIsModalOpen(true);
            }}
          >
            <ICONS.EnvelopWithCircleIcon />
          </EmailIconContainer>
          <Dropdown>
            <Dropdown.Toggle
              variant=""
              aria-expanded="false"
              aria-haspopup="true"
              className="btn btn-primary Onboarding-dropdown"
              data-bs-toggle="dropdown"
              type="button"
              id="phase-dropdown"
            >
              {PipelineKey[pipeline_status]}
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ margin: "0px" }}>
              {pipelinePhaseData.map((option, index) => (
                <Dropdown.Item
                  eventKey={option.label}
                  key={index}
                  onClick={() => {
                    if (option.key === "-1") {
                      setIsRegretModalOpen(true);
                    } else
                      !(String(pipeline_status) === String(option.key)) &&
                        API_CALLS.PIPELINE.updatePipelineOverview({
                          candidate_id: candidate_id,
                          status_id: String(option.key),
                          job_id: job_id as string,
                        });
                  }}
                >
                  {option.label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <Typography
          content={`date applied: ${application_date}`}
          styleName="text_10pt"
          color="primary_default"
        />
      </Wrapper>
      <div className="candidate-detail">
        <Detail onClick={() => window.open(`tel:${phone}`)}>
          <ICONS.PhoneIcon />
          <Typography
            content={phone}
            styleName="inputRegular12pt"
            color="black70"
          />
        </Detail>
        <Detail>
          <ICONS.MailIcon />
          <Typography
            content={email}
            styleName="inputRegular12pt"
            color="black70"
          />
        </Detail>
        <Detail>
          <ICONS.LocationIcon />
          <Typography
            content={location}
            styleName="inputRegular12pt"
            color="black70"
          />
        </Detail>
      </div>
      <CustomModal isModalOpen={isRegretModalOpen}>
        <ModalHeader>
          <Modal.Title>
            <Typography
              content="Regret List"
              styleName="subtitle3"
              color="black80"
            />
          </Modal.Title>
          <Button
            variant=""
            className="btn btn-close"
            style={{ opacity: "1" }}
            onClick={() => {
              setIsRegretModalOpen(false);
            }}
          >
            <ICONS.CrossIcon />
          </Button>
        </ModalHeader>

        <ModalBody>
          Are you sure you want to take this user out of the running?
        </ModalBody>
        <Modal.Footer>
          <ButtonContainer>
            <Buttons
              variant="light"
              handleClick={() => {
                setIsRegretModalOpen(false);
              }}
              label="Cancel"
            />
          </ButtonContainer>
          <ButtonContainer>
            <Buttons
              variant="success"
              handleClick={() => {
                if (!(pipeline_status === "-1"))
                  API_CALLS.PIPELINE.updatePipelineOverview({
                    candidate_id,
                    status_id: "-1",
                    job_id: job_id as string,
                  });

                setIsRegretModalOpen(false);
              }}
              label="Confirm"
            />
          </ButtonContainer>
        </Modal.Footer>
      </CustomModal>
      <CustomModal isModalOpen={isModalOpen}>
        <ModalHeader>
          <Modal.Title>
            <Typography
              content="Send Message"
              styleName="modalHeading"
              color="black80"
            />
          </Modal.Title>
          <Button
            variant=""
            className="btn btn-close"
            style={{ opacity: "1" }}
            onClick={() => {
              setIsModalOpen(false);
            }}
          >
            <ICONS.CrossIcon />
          </Button>
        </ModalHeader>
        <Formik
          enableReinitialize
          initialValues={{
            message: "",
            subject: selectedTemplates?.subject,
          }}
          onSubmit={() => {}}
        >
          {(formik) => (
            <>
              <Modal.Body>
                <ModalFormContainer>
                  <TemplateSettingContainer
                    onClick={() => {
                      localStorage.setItem(
                        LOCAL_STORAGE_KEY.ACTIVE_SETTINGS_TAB,
                        "messaging-templates"
                      );
                      navigate(ROUTES.SETTINGS);
                    }}
                  >
                    <Typography
                      content="template settings"
                      styleName="inputRegular12pt"
                      color="primary_default"
                    />
                    <FontAwesomeIcon
                      icon={faArrowUpRightFromSquare}
                      style={{
                        color: theme.colors.primary_default,
                        width: "10px",
                      }}
                    />
                  </TemplateSettingContainer>
                  <FormFields.Dropdown
                    {...{
                      placeholder: "Select Template",
                      label: " Select Template",
                      fieldName: "select_template",
                      idPrefix: "candidate-profile",
                      isRequired: false,
                      options: templates.map((template) => ({
                        label: template.template_name,
                        value: template.id,
                      })),
                      onChangeHandler: (item) => {
                        dispatch(
                          actions.messageTemplate.setSelectedTemplates(
                            templates[
                              templates.findIndex(
                                (template) => template.id === Number(item)
                              )
                            ]
                          )
                        );
                      },
                    }}
                  />
                  <FormFields.Input {...candidateProfileFields.subject} />
                  <FormFields.RichTextArea
                    {...{
                      ...candidateProfileFields.message,
                      defaultValue: convertMessageTagToValue(
                        selectedTemplates?.message ?? ""
                      ),
                    }}
                  />
                </ModalFormContainer>
              </Modal.Body>
              <Modal.Footer>
                <ButtonContainer>
                  <Buttons
                    variant="light"
                    handleClick={() => {
                      setIsModalOpen(false);
                    }}
                    label="Cancel"
                  />
                </ButtonContainer>
                <ButtonContainer>
                  <Buttons
                    variant="success"
                    handleClick={() => {
                      FormikHelpers.formikSubmitHandler({
                        formFields: candidateProfileFields,
                        formik,
                        idPrefix,
                        onSubmitHandler,
                      });
                    }}
                    label="Send"
                  />
                </ButtonContainer>
              </Modal.Footer>
            </>
          )}
        </Formik>
      </CustomModal>
    </CandidateProfileContainer>
  );
};

export default CandidateProfile;
