import { createContext, useState } from "react";
import { Col } from "react-bootstrap";

import { useAppSelector } from "src/Redux";
import { CreateJobComponentProps } from "../../CreateJob";
import { CheckoutUtils } from "../Utils";
import { CheckoutStyles } from "../Styles";
import { Typography, TypographyLink } from "src/components/Basic";
import { CheckoutFormStripe } from "./CheckoutFormStripe";
import { CheckOutCardsList } from "./CheckoutCardsList";
import { ICONS } from "src/assets/custom_Icons";

export const CheckoutCardsContext = createContext(
  {} as CreateJobComponentProps.CheckoutContext
);
export const CheckoutCards = () => {
  const { isDarkTheme } = useAppSelector((store) => store.common);

  const { cards } = CheckoutUtils;
  const [showForm, setShowForm] = useState(cards.length === 0);
  const [selectedCardIndex, setSelectedCardIndex] = useState(0);

  return (
    <CheckoutCardsContext.Provider
      value={{ cards, selectedCardIndex, setSelectedCardIndex }}
    >
      <Col sm={12} md={6} className="checkout checkout-right">
        <CheckoutStyles.PaymentDetailsTitle>
          <Typography
            content={showForm ? "Add New Card" : "Payment details"}
            styleName="jobCheckoutFormTitle"
            color={isDarkTheme ? "black10" : "black90"}
          />
          <TypographyLink
            content={showForm ? "Go back to saved cards" : "Add New Card"}
            styleName="jobCheckoutAddCardLink"
            color={isDarkTheme ? "black10" : "black80"}
            onClick={() => setShowForm((showForm) => !showForm)}
            disabled={cards.length === 0}
          />
        </CheckoutStyles.PaymentDetailsTitle>
        {showForm ? <CheckoutFormStripe /> : <CheckOutCardsList />}
        <CheckoutStyles.StripeLogoWrapper>
          <Typography
            styleName="jobCheckoutPoweredByTitle"
            content="Powered by"
            color="grey_icon"
          />
          <ICONS.StripeLogo />
        </CheckoutStyles.StripeLogoWrapper>
      </Col>
    </CheckoutCardsContext.Provider>
  );
};
