import { createSlice } from "@reduxjs/toolkit";
import { ActiveJobsProps } from "./ActiveJobs";

const initialState: ActiveJobsProps.state = {
  jobOverviewResult: [],
};

const activeJobSlice = createSlice({
  name: "job",
  initialState,
  reducers: {
    setJobOverviewResult: (
      state,
      action: ActiveJobsProps.setJobOverviewResult
    ) => {
      console.log("JOBS", action.payload);
      state.jobOverviewResult = action.payload;
    },
  },
});

export const activeJobActions = activeJobSlice.actions;
export const activeJobReducer = activeJobSlice.reducer;
