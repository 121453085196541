import { Grid } from "antd";

import { Typography } from "src/components/Basic";
import { FirstStep } from "./FirstStep";
import { SecondStep } from "./SecondStep";
import { useAppSelector } from "src/Redux";
import { RegisterFooter } from "./RegisterFooter";

export const Register = () => {
  const screen = Grid.useBreakpoint();
  const { stepNumber } = useAppSelector((store) => store.register);

  return (
    <>
      <div className="d-flex justify-content-center">
        <Typography
          content="Register"
          styleName={screen.lg ? "authTitle" : "authTitleMobile"}
          color={screen.lg ? "black_pure" : "primary_default"}
        />
      </div>
      {stepNumber === 1 ? <FirstStep /> : <SecondStep />}
      <RegisterFooter />
    </>
  );
};
