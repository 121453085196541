import { useContext } from "react";
import { TermsAndPrivacyComponents } from "../Components";
import { TermsAndConditionsContext } from "../..";

const { Points, Text } = TermsAndPrivacyComponents;

export const RegisteringProfile = () => (
  <>
    <Points.Heading pointNumber="3." heading="Registering a Profile with Us" />
    <Points.Indent>
      <>
        <Points.Span pointNumber="3.1." Content={POINT_3_1} />
        <Points.Span pointNumber="3.2." Content={POINT_3_2} />
        <Points.Span pointNumber="3.3." Content={POINT_3_3} />
        <Points.Span pointNumber="3.4." Content={POINT_3_4} />
        <Points.Span pointNumber="3.5." Content={POINT_3_5} />
        <Points.Span pointNumber="3.6." Content={POINT_3_6} />
        <Points.Span pointNumber="3.7." Content={<POINT_3_7 />} />
      </>
    </Points.Indent>
  </>
);

const POINT_3_1 = (
  <Points.Content>
    <Text.Span
      content={`To use Nova as an Employer, you must register on Nova and create a profile. Nova will only accept registered domains when creating a profile and will not accept any email addresses using Gmail or similar domains.`}
    />
  </Points.Content>
);
const POINT_3_2 = (
  <Points.Content>
    <Text.Span
      content={`Jobseekers are required to create a profile using Ditto Jobs and will always be subject to the Legal Terms for Ditto Jobs available `}
    />
    <Text.Link content="here" />
  </Points.Content>
);
const POINT_3_3 = (
  <Points.Content>
    <Text.Span
      content={`To create a profile on Nova, you must provide us with your full name, company email address, company name, location, company size, and any other relevant information that might be required to verify the company and the information submitted.`}
    />
  </Points.Content>
);
const POINT_3_4 = (
  <Points.Content>
    <Text.Span
      content={`When creating a profile, you agree to provide accurate, current, and complete information and to update this information as and when it changes. Once registered, you will be allocated a unique profile that is under your control.`}
    />
  </Points.Content>
);
const POINT_3_5 = (
  <Points.Content>
    <Text.Span
      content={`To protect your privacy and security, we take reasonable steps to verify your identity by requiring your password together with your email address to grant you access to your profile. To view or change the personal data provided, you can go to the profile section under your account.`}
    />
  </Points.Content>
);
const POINT_3_6 = (
  <Points.Content>
    <Text.Span
      content={`By sharing your personal data with us, you warrant that the person using Nova is you and/or you have the legal authority to represent a legal entity. You are responsible for the profile and all the actions taken with it. We reserve the right to carry out verification checks on all Employer profiles and suspend and/or terminate any accounts which we reasonably believe to be fraudulent.`}
    />
  </Points.Content>
);
const POINT_3_7 = () => {
  const { setContentType } = useContext(TermsAndConditionsContext);
  return (
    <Points.Content>
      <Text.Span content={`Please see our `} />
      <span onClick={() => setContentType("privacy_policy")}>
        <Text.Link content="Privacy Policy" />
      </span>
      <Text.Span content=" for more details on how we use and processes your personal data. " />
    </Points.Content>
  );
};
