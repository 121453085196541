import { toast } from "react-toastify";

import { API_INSTANCES } from "src/API_INSTANCES";
import store, { actions } from "src/Redux";
import { IOverview } from "src/components/Settings/Overview/Overview";
import { ITeamManagement } from "src/components/pages/TeamManagement/TeamManagement";

const dispatch = store.dispatch;

const getTeamOverview = () => {
  API_INSTANCES.TEAM_OVERVIEW.getTeamOverview()
    .then((res) => {
      const userData = res?.data;
      dispatch(
        actions.settings.setUserData([...userData.team, ...userData.invited])
      );
    })
    .catch((error) => console.log(error))
    .finally(() => dispatch(actions.common.setPageLoading(false)));
};

const getUnverifiedMemberDetails = (email: string, token: string) => {
  API_INSTANCES.TEAM_OVERVIEW.getUnverifiedMemberDetails(email, token)
    .then((res) => {
      dispatch(actions.settings.setMemberInvitedValidLink(true));
      dispatch(actions.settings.setUnverifiedMemberDetails(res.data));
    })
    .catch((error) => {
      dispatch(actions.settings.setMemberInvitedValidLink(false));
      console.log(error);
    });
};

const activeDeactiveUser = (isActive: boolean, id: string) => {
  toast.promise(API_INSTANCES.TEAM_OVERVIEW.activeDeactiveUser(isActive, id), {
    pending: "Promise is pending",
    success: {
      render({ data }) {
        const userData = data?.data;
        dispatch(
          actions.settings.setUserData([...userData.team, ...userData.invited])
        );
        return "Promise resolved 👌";
      },
    },
    error: {
      render({ data }) {
        console.log(data);
        return "Promise rejected 🤯";
      },
    },
  });
};

const addMemberCompany = (value: ITeamManagement.CompanyMember) => {
  dispatch(actions.settings.setTeamManagementError(""));
  toast.promise(API_INSTANCES.TEAM_OVERVIEW.addMemberCompany(value), {
    pending: "Promise is pending",
    success: {
      render() {
        dispatch(actions.settings.setVerifyMemberDetail(true));
        return "Promise resolved 👌";
      },
    },
    error: {
      render({ data }: any) {
        console.log(data);
        dispatch(
          actions.settings.setTeamManagementError(data.response.data.detail)
        );
        return "Promise rejected 🤯";
      },
    },
  });
};

const addTeamMember = (value: IOverview.TeamMember) => {
  toast.promise(API_INSTANCES.TEAM_OVERVIEW.addTeamMember(value), {
    pending: "Promise is pending",
    success: {
      render() {
        getTeamOverview();
        return "Promise resolved 👌";
      },
    },
    error: {
      render({ data }) {
        console.log(data);
        return "Promise rejected 🤯";
      },
    },
  });
};

const updateTeamMember = (value: IOverview.UpdateTeamMember) => {
  toast.promise(API_INSTANCES.TEAM_OVERVIEW.updateTeamMember(value), {
    pending: "Promise is pending",
    success: {
      render({ data }) {
        const userData = data?.data;
        dispatch(
          actions.settings.setUserData([...userData.team, ...userData.invited])
        );
        return "Promise resolved 👌";
      },
    },
    error: {
      render({ data }) {
        console.log(data);
        return "Promise rejected 🤯";
      },
    },
  });
};

const deleteInvitedMember = (id: string) => {
  toast.promise(API_INSTANCES.TEAM_OVERVIEW.deleteInvitedMember(id), {
    pending: "Promise is pending",
    success: {
      render({ data }) {
        const userData = data?.data;
        dispatch(
          actions.settings.setUserData([...userData.team, ...userData.invited])
        );
        return "Promise resolved 👌";
      },
    },
    error: {
      render({ data }) {
        console.log(data);
        return "Promise rejected 🤯";
      },
    },
  });
};

const updateInvitedMember = (value: IOverview.UpdateInvitedMember) => {
  toast.promise(API_INSTANCES.TEAM_OVERVIEW.updateInvitedMember(value), {
    pending: "Promise is pending",
    success: {
      render({ data }) {
        const userData = data?.data;
        dispatch(
          actions.settings.setUserData([...userData.team, ...userData.invited])
        );
        return "Promise resolved 👌";
      },
    },
    error: {
      render({ data }) {
        console.log(data);
        return "Promise rejected 🤯";
      },
    },
  });
};

export const TEAM_OVERVIEW = {
  getTeamOverview,
  activeDeactiveUser,
  getUnverifiedMemberDetails,
  addMemberCompany,
  addTeamMember,
  updateTeamMember,
  deleteInvitedMember,
  updateInvitedMember,
};
