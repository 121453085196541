import { NavigateFunction } from "react-router-dom";
import { toast } from "react-toastify";

import { API_INSTANCES } from "src/API_INSTANCES";
import store, { actions } from "src/Redux";
import { CandidateProfile } from "src/components/Settings/CandidateProfile/CandidateProfile";
import { API_CALLS } from ".";

const dispatch = store.dispatch;

const getCandidateDetail = () => {
  API_INSTANCES.CANDIDATE_SETTINGS.getCandidateDetail()
    .then((response) =>
      dispatch(actions.settings.setCandidateDetails(response.data))
    )
    .catch((error) => console.log(error));
};

const updateCandidateDetail = (value: CandidateProfile.CandidateDetails) => {
  dispatch(actions.settings.setUpdateInfoButtonDisable(true));
  toast.promise(API_INSTANCES.CANDIDATE_SETTINGS.updateCandidateDetail(value), {
    pending: "Promise is pending",
    success: {
      render({ data }) {
        dispatch(actions.settings.setCandidateDetails(data?.data));
        dispatch(actions.settings.setUpdateInfoButtonDisable(false));
        return "Promise resolved 👌";
      },
    },
    error: {
      render({ data }) {
        dispatch(actions.settings.setUpdateInfoButtonDisable(false));
        console.log(data);
        return "Promise rejected 🤯";
      },
    },
  });
};

const getCandidateProfilePhoto = () => {
  API_INSTANCES.CANDIDATE_SETTINGS.getCandidateProfilePhoto()
    .then((result) =>
      dispatch(
        actions.settings.setCandidateProfilePhoto(result.data.profile_picture)
      )
    )
    .catch((error) => console.log(error))
    .finally(() => dispatch(actions.common.setPageLoading(false)));
};

const updateCandidateProfilePhoto = (values: File) => {
  toast.promise(
    API_INSTANCES.CANDIDATE_SETTINGS.updateCandidateProfilePhoto(values),
    {
      pending: "Promise is pending",
      success: {
        render({ data }) {
          dispatch(
            actions.settings.setCandidateProfilePhoto(
              data?.data.profile_picture
            )
          );
          return "Promise resolved 👌";
        },
      },
      error: {
        render({ data }) {
          console.log(data);
          return "Promise rejected 🤯";
        },
      },
    }
  );
};

const updateCandidatePassword = (
  values: CandidateProfile.Password,
  navigate: NavigateFunction
) => {
  dispatch(actions.settings.setUpdatePasswordButtonDisable(true));
  toast.promise(
    API_INSTANCES.CANDIDATE_SETTINGS.updateCandidatePassword(values),
    {
      pending: "Promise is pending",
      success: {
        render() {
          dispatch(actions.settings.setUpdatePasswordButtonDisable(false));
          API_CALLS.AUTH.logout(navigate);
          return "Promise resolved 👌";
        },
      },
      error: {
        render({ data }) {
          dispatch(actions.settings.setUpdatePasswordButtonDisable(false));
          console.log(data);
          return "Promise rejected 🤯";
        },
      },
    }
  );
};

export const CANDIDATE_SETTINGS = {
  getCandidateDetail,
  updateCandidateDetail,
  getCandidateProfilePhoto,
  updateCandidateProfilePhoto,
  updateCandidatePassword,
};
