import React from "react";
import clsx from "clsx";
import { Dropdown } from "react-bootstrap";
import moment from "moment";

import { ICONS } from "src/assets/custom_Icons";
import { Typography } from "../../Basic/Typography";
import { PipelineKey, pipelinePhaseData } from "../Utils";
import {
  CandidateContainer,
  CandidateListContainer,
  JobTitle,
  JobTitleContainer,
  Photo,
  MainContainer,
  HeadingContainer,
  NoDataContainer,
  DropDownGroup,
  JobApplied,
} from "./CandidateListStyle";
import { IPipeLine, PhaseKey } from "../PipeLine";
import Pagination from "./Pagination";
import { useAppDispatch, useAppSelector } from "src/Redux";
import { candidateActions } from "src/Redux/slices/Candidate";
import { LOCAL_STORAGE_KEY } from "src/utils/LOCAL_STORAGE_KEYS";
import RatingDropDown from "./RatingDropDown";
import { API_CALLS } from "src/API_CALLS";

const RatingIconList = {
  0: <></>,
  1: <ICONS.HappyRatingIcon color="danger_default" height={20} width={20} />,
  2: <ICONS.HappyRatingIcon color="warning_default" height={20} width={20} />,
  3: <ICONS.HappyRatingIcon color="success_default" height={20} width={20} />,
};

export const CandidateList: React.FC<IPipeLine.ICandidateList> = ({
  setActiveTab,
  phase,
  setPhase,
  latest,
  unread,
  setLatest,
  setUnread,
  selected,
  setSelected,
}) => {
  const [page, setPage] = React.useState(1);

  const { common, candidate } = useAppSelector((state) => state);
  const { candidateListData, selectedCandidate, pageSize } = candidate;
  const dispatch = useAppDispatch();
  const totalPage = candidateListData.results.total_pages;

  return (
    <MainContainer>
      <HeadingContainer>
        <div className="d-flex align-items-end text-nowrap justify-content-between">
          <Typography
            content="CANDIDATE LIST"
            styleName="subtitle2"
            color="black85"
          />
          <Dropdown
            className="onbording-dropdown"
            onSelect={(value) => {
              dispatch(candidateActions.setActivePage(1));
              const key =
                value === "-1" ? (value as PhaseKey) : (Number(value) as PhaseKey);
              setPhase(key);
              setLatest("latest");
              setUnread("unread");
              dispatch(candidateActions.setPageSize(10));
              localStorage.setItem(
                LOCAL_STORAGE_KEY.SELECTED_PHASE,
                JSON.stringify(key)
              );
            }}
          >
            <Dropdown.Toggle
              variant=""
              aria-expanded="false"
              aria-haspopup="true"
              className="btn btn-primary btn-label"
              data-bs-toggle="dropdown"
              type="button"
            >
              {PipelineKey[phase]}
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ margin: "0px" }}>
              {pipelinePhaseData.map((option, index) => (
                <Dropdown.Item eventKey={option.key} key={index}>
                  {option.label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <DropDownGroup>
          <Dropdown
            onSelect={(value) => {
              dispatch(candidateActions.setPageSize(value));
              dispatch(candidateActions.setActivePage(1));
            }}
          >
            <Dropdown.Toggle
              variant=""
              id="dropdown-basic"
              aria-expanded="false"
              aria-haspopup="true"
              className="btn btn-light show-dropdown btn-label d-flex align-items-center justify-content-between"
              data-bs-toggle="dropdown"
              type="button"
            >
              {pageSize}
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ margin: "0px" }}>
              <Dropdown.Item eventKey={10}>10</Dropdown.Item>
              <Dropdown.Item eventKey={25}>25</Dropdown.Item>
              <Dropdown.Item eventKey={50}>50</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown
            onSelect={(value) => {
              setUnread(value as "read" | "unread");
              dispatch(candidateActions.setActivePage(1));
            }}
          >
            <Dropdown.Toggle
              variant=""
              aria-expanded="false"
              aria-haspopup="true"
              className="btn btn-light read-unread-dropdown btn-label d-flex align-items-center justify-content-between"
              data-bs-toggle="dropdown"
              type="button"
            >
              {unread}
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ margin: "0px" }}>
              <Dropdown.Item eventKey={"read"}>Read</Dropdown.Item>
              <Dropdown.Item eventKey={"unread"}>Unread</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown
            onSelect={(value) => {
              setLatest(value as "latest" | "oldest");
              dispatch(candidateActions.setActivePage(1));
            }}
          >
            <Dropdown.Toggle
              variant=""
              aria-expanded="false"
              aria-haspopup="true"
              className="btn btn-light latest-dropdown btn-label d-flex align-items-center justify-content-between"
              data-bs-toggle="dropdown"
              type="button"
            >
              {latest}
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ margin: "0px" }}>
              <Dropdown.Item eventKey={"latest"}>Latest</Dropdown.Item>
              <Dropdown.Item eventKey={"oldest"}>Oldest</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <RatingDropDown selected={selected} setSelected={setSelected} />
        </DropDownGroup>
      </HeadingContainer>

      {candidateListData.applicants.length ? (
        <CandidateListContainer>
          <div className="candidate-list">
            {candidateListData.applicants?.map((candidate, index) => (
              <CandidateContainer
                key={index}
                isEmailOpen={candidate.is_read}
                isSelected={
                  candidate.application_id === selectedCandidate.application_id
                }
                className={clsx(
                  candidate.is_read ? "email-open" : "email-not-open",
                  candidate.application_id ===
                    selectedCandidate.application_id && "candidate_selected"
                )}
                onClick={() => {
                  dispatch(candidateActions.resetCandidateOverviewDetails());
                  dispatch(candidateActions.setStep(1));
                  if (
                    !(
                      candidate.application_id ===
                      selectedCandidate.application_id
                    )
                  )
                    API_CALLS.PIPELINE.getCandidateOverview({
                      application_id: candidate.application_id,
                      candidate_id: candidate.candidate_id,
                      is_read: candidate.is_read,
                      applicant_index: index,
                    });

                  setActiveTab("Summary");
                }}
              >
                <JobApplied>
                  {moment(candidate.date_applied).fromNow()}
                </JobApplied>
                <div className="d-flex w-100 gap-3">
                  <Photo src={`data:image/png;base64,${candidate.photo}`} />
                  <div className="w-100 candidate-info">
                    <Typography
                      content={candidate.name}
                      styleName={
                        candidate.is_read
                          ? "candidateName"
                          : "candidateNameEmailOpen"
                      }
                      color={candidate.is_read ? "black80" : "black90"}
                    />
                    <JobTitleContainer
                      className={clsx(
                        candidate.is_read ? "open-envelop" : "envelop"
                      )}
                    >
                      <JobTitle>
                        {candidate.job_title && <ICONS.BagIcon />}
                        <Typography
                          content={candidate.job_title}
                          styleName="text_10pt"
                          color="black70"
                        />
                      </JobTitle>
                      <div className="rating_icon">
                        {
                          RatingIconList[
                            candidate.candidate_rating as keyof typeof RatingIconList
                          ]
                        }
                        {candidate.is_read ? (
                          <div
                            className="email-icon-open-candidatelist"
                            onClick={(e) => {
                              e.stopPropagation();
                              API_CALLS.PIPELINE.updateIsRead(
                                candidate.application_id,
                                index
                              );
                            }}
                          >
                            <ICONS.OpenEnvelope />
                          </div>
                        ) : (
                          <div
                            className="email-icon-candidatelist"
                            onClick={(e) => {
                              e.stopPropagation();
                              API_CALLS.PIPELINE.updateIsRead(
                                candidate.application_id,
                                index
                              );
                            }}
                          >
                            <ICONS.Envelope />
                          </div>
                        )}
                      </div>
                    </JobTitleContainer>
                  </div>
                </div>
              </CandidateContainer>
            ))}
          </div>
          <Pagination totalPage={totalPage} page={page} setPage={setPage} />
        </CandidateListContainer>
      ) : (
        !common.isPageLoad && (
          <NoDataContainer>
            <Typography
              content="No candidates in this section."
              styleName="text1"
              color="black80"
            />
            <div className="mx-auto eye-icon">
              <ICONS.EyeOffIcon />
            </div>
          </NoDataContainer>
        )
      )}
    </MainContainer>
  );
};

