import styled from "styled-components";

import { theme } from "src/components/Theme";

export const ExperienceContainer = styled.div`
  margin: 20px 36px 20px 38px;
  @media (max-width: 991px) {
    margin: 16px 8px 20px;
  }
`;
export const AccordionHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 17px;
  .collapsed & div > :last-child {
    color: ${theme.colors.black70};
  }
  .dark-theme & div > label {
    color: ${theme.colors.white_pure};
  }
  .dark-theme & div > :nth-child(2) {
    color: ${theme.colors.primary20};
  }
  .dark-theme .collapsed & div > label {
    color: ${theme.colors.white10};
  }
  .dark-theme .collapsed & div > :nth-child(2) {
    color: ${theme.colors.grey30};
  }
  .dark-theme & svg path {
    stroke: ${theme.colors.white200};
  }
  .dark-theme .collapsed & svg path {
    stroke: ${theme.colors.primary_default};
  }
`;
export const AccordianContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 24px;
  .accordion-body div {
    color: ${theme.colors.black70};
  }
  .heading label {
    @media (max-width: 991px) {
      color: ${theme.colors.black70};
      ${theme.text.pipelineDefaultNav}
    }
  }
  .accordion-heading {
    border: 1px solid ${theme.colors.black10};
    border-radius: 0px;
  }
  .accordion-button:not(.collapsed)::after {
    background-image: none;
  }
  .accordion-button::after {
    background-image: none;
  }
  .accordion-collapse {
    background: ${theme.colors.white_pure};
  }
  .accordion-body {
    padding: 12px 22px 34px 53px;
  }
  @media (max-width: 991px) {
    margin-top: 20px;
  }
  .dark-theme & .accordion {
    .accordion-button:not(.collapsed) {
      background: ${theme.colors.primary_default} !important;
    }
    .accordion-heading {
      border: 1px solid ${theme.colors.black130};
    }
    .accordion-body div {
      color: ${theme.colors.black10};
    }
  }
  .dark-theme & .heading label {
    color: ${theme.colors.black10};
  }
`;
export const HeadingContainer = styled.div`
  @media (max-width: 991px) {
    margin-left: 8px;
  }
  .dark-theme &.no-data-msg label {
    color: ${theme.colors.black10};
  }
  &.heading label {
    text-transform: uppercase;
  }
`;
export const ExperienceNotAddedContainer = styled(HeadingContainer)`
  margin-top: 24px;
`;
export const IconContainer = styled.div`
  margin: auto;
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    @media (max-width: 991px) {
      width: 35px;
      height: 35px;
    }
  }
  @media (max-width: 991px) {
    margin: 55px auto 80px;
  }
  .dark-theme & svg path {
    stroke: ${theme.colors.light_default};
  }
`;
