import { TermsAndPrivacyComponents } from "../Components";

const { Points, Text } = TermsAndPrivacyComponents;

export const DataRetention = () => (
  <>
    <Points.Heading pointNumber="10." heading="Data Retention" />
    <Points.Indent>
      <>
        <Points.Span pointNumber="10.1." Content={POINT_10_1} />
        <Points.Span pointNumber="10.2." Content={POINT_10_2} />
        <Points.Span pointNumber="10.3." Content={POINT_10_3} />
        <Points.Span pointNumber="10.4." Content={POINT_10_4} />
      </>
    </Points.Indent>
  </>
);

const POINT_10_1 = (
  <Text.Span content="We will only retain your personal data for as long as necessary to fulfil the purpose we collected it for including any legal, accounting, or reporting requirements." />
);
const POINT_10_2 = (
  <Text.Span content="To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorized use or disclosure of your personal data, the purpose for which we process your personal data, any other applicable law requiring us to retain the personal data and whether we can achieve those purposes through other means, and the applicable legal requirements." />
);
const POINT_10_3 = (
  <Text.Span content="Details of retention periods of your personal data are available by contacting us." />
);
const POINT_10_4 = (
  <Text.Span content="We may also anonymize your personal data (so that it can no longer be associated with you) for research or statistical purposes in which case we may use this information indefinitely without further notice to you." />
);
