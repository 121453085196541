import { createContext, useContext, useEffect, useState } from "react";

import "./createJob.css";
import { Typography, TypographyLink } from "src/components/Basic";
import { API_CALLS } from "src/API_CALLS";
import {
  CreateJobProps,
  actions,
  useAppDispatch,
  useAppSelector,
} from "src/Redux";
import { CreateJobComponentProps } from "./CreateJob";
import { CreateJobStyles } from "./Styles";
import { CreateJobPropsSteps } from "src/Redux/slices/CreateJob";
import { JobCreate } from "./1-JobCreate";
import { Advertise } from "./2-Advertise";
import { CheckOut } from "./3-Checkout";
import { AppContext } from "src/App";
import { BackButton } from "./BackButton";
import { Animation } from "./CampaignLoading";

export const CreateJobContext = createContext(
  {} as CreateJobComponentProps.Context
);
export const CreateJob = () => {
  const { isMobile, isTabletOrBig } = useContext(AppContext);

  //#region Redux
  const dispatch = useAppDispatch();
  const { common, createJob, campaign } = useAppSelector((store) => store);
  const { industries, skills, isDarkTheme } = common;
  const { stepNumber } = createJob;
  const { showCampaignLoadingAnimation } = campaign;
  //#endregion

  //#region useStates
  const [isAPICallMade, setAPIAPICallMade] = useState(false);
  const [isCreateJobFormValid, setCreateJobFormValid] = useState(false);
  const [isBoostedCampaignFormValid, setBoostedCampaignFormValid] =
    useState(false);
  //#endregion

  //#region useEffects
  useEffect(() => {
    if (isAPICallMade) return;

    if (Object.keys(skills).length === 0) API_CALLS.COMMON.getSkills();
    if (Object.keys(industries).length === 0) API_CALLS.COMMON.getIndustries();

    setAPIAPICallMade(true);
  }, [isAPICallMade, skills, industries]);
  //#endregion

  //#region Steps
  const steps: CreateJobComponentProps.Steps = {
    1: {
      name: isMobile ? "Create" : "Job Create",
      title: "Let's get started!",
      subtitle: "Please complete the following.",
      element: <JobCreate />,
    },
    2: {
      name: "Advertise",
      title: "Advertise",
      subtitle: "Please select which post type you would like.",
      element: <Advertise />,
    },
    3: {
      name: "Check Out",
      title: "",
      subtitle: "",
      element: <CheckOut />,
    },
  };
  const getStepColor = (currentStep: CreateJobProps.stepNumber) =>
    currentStep === stepNumber
      ? "primary_default"
      : isMobile
      ? "black50"
      : isDarkTheme
      ? "black10"
      : "black90";

  const Step = ({ step }: { step: CreateJobProps.stepNumber }) => {
    const { campaign, createJob } = useAppSelector((store) => store);
    const { isJobCreated, isEditFlow } = createJob;
    const { advertType } = campaign;

    let isStepDisabled = false;
    if (step === 1) isStepDisabled = isEditFlow;
    if (step === 2) isStepDisabled = isEditFlow && !isJobCreated;
    if (step === 3) {
      let isStep2Valid = advertType ? true : false;
      isStepDisabled = isEditFlow && (!isJobCreated || !isStep2Valid);
    }

    useEffect(() => {
      dispatch(actions.common.setPageLoading(false));
    }, []);
    
    return (
      <CreateJobStyles.StepHeading
        key={step}
        onClick={() => {
          if (isStepDisabled) return;
          dispatch(actions.createJob.setStepNumber(step));
        }}
      >
        <TypographyLink
          content={steps[step].name}
          styleName={isMobile ? "jobStepsTitleMobile" : "jobStepsTitle"}
          color={getStepColor(step)}
          underLineNeeded={false}
          disabled={isStepDisabled}
        />
      </CreateJobStyles.StepHeading>
    );
  };
  //#endregion

  return (
    <CreateJobContext.Provider
      value={{
        isCreateJobFormValid,
        setCreateJobFormValid,
        isBoostedCampaignFormValid,
        setBoostedCampaignFormValid,
      }}
    >
      {showCampaignLoadingAnimation && <Animation />}
      <CreateJobStyles.PageContainer className="page-container">
        <CreateJobStyles.PageHeadingWrapper>
          <CreateJobStyles.PageHeading className="page-heading">
            <Typography
              styleName={isMobile ? "heading5Mobile" : "heading5"}
              content="CREATE JOB"
              color={isDarkTheme ? "black10" : "black90"}
            />
          </CreateJobStyles.PageHeading>
          <CreateJobStyles.HeadingContainer>
            {isTabletOrBig && <BackButton />}
            <CreateJobStyles.StepsContainer>
              {CreateJobPropsSteps.map((step) => (
                <Step key={step} step={step} />
              ))}
            </CreateJobStyles.StepsContainer>
          </CreateJobStyles.HeadingContainer>
        </CreateJobStyles.PageHeadingWrapper>

        <CreateJobStyles.CreateJobContainer className="create-job-container">
          {steps[stepNumber].title && (
            <CreateJobStyles.CreateJobSubtitle className="create-job-subtitle">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  styleName="subtitle3"
                  content={steps[stepNumber].title}
                  color={
                    isDarkTheme ? "dark_page_content_title" : "blue_grey_80"
                  }
                />
                {!isTabletOrBig && <BackButton />}
              </div>
              <Typography
                styleName="text_13pt"
                content={steps[stepNumber].subtitle}
                color={isDarkTheme ? "dark_page_content_subtitle" : "black70"}
              />
            </CreateJobStyles.CreateJobSubtitle>
          )}

          <CreateJobStyles.CreateJobContentWrapper className="create-job-content">
            {steps[stepNumber].element}
          </CreateJobStyles.CreateJobContentWrapper>
        </CreateJobStyles.CreateJobContainer>
      </CreateJobStyles.PageContainer>
    </CreateJobContext.Provider>
  );
};
