import { TermsAndPrivacyComponents } from "../Components";

const { Points, Text } = TermsAndPrivacyComponents;

export const DataSecurity = () => (
  <>
    <Points.Heading pointNumber="9." heading="Data Security" />
    <Points.Indent>
      <>
        <Points.Span pointNumber="9.1." Content={POINT_9_1} />
        <Points.Span pointNumber="9.2." Content={POINT_9_2} />
        <Points.Span pointNumber="9.3." Content={POINT_9_3} />
      </>
    </Points.Indent>
  </>
);
const POINT_9_1 = (
  <Text.Span content="We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used, or accessed in an unauthorized way, altered, or disclosed by protecting digital information from unauthorized access, corruption or theft throughout its entire life cycle. Through prioritizing information security from the physical security of hardware and storage devices to administrative and access controls, as well as the logical security of software applications. All user data is using the cloud storage services of Hetzner Germany and Amazon Web Services." />
);
const POINT_9_2 = (
  <Text.Span content="We also limit access to your personal data to those employees, agents, contractors and other third parties who have a legitimate need to know. They will only process your personal data on our instruction and are subject to a duty of confidentiality." />
);
const POINT_9_3 = (
  <Text.Span content="We have put in place procedures to deal with any suspected personal data breach and will notify you of a breach where we are legally required to do so." />
);
