import { API_INSTANCES } from "src/API_INSTANCES";
import { CampaignProps } from "src/Redux/slices";
import { store, actions } from "src/Redux";
import { AxiosResponse } from "axios";

const dispatch = store.dispatch;

const FIELDS = {
  CAMPAIGN: { job_title: "Job title", city_id: "City id" },
};

const NO_RESULTS_ERROR = 400;
const NOT_ENOUGH_DATA_ERROR = 406;
const INPUT_DATA_ERROR = 422;

const CAMPAIGN_ERRORS = {
  400: "No results",
  406: "Not enough data",
  422: "Error in input data ",
};

const createSingleCampaign = (values: CampaignProps.createSingleCampaign) => {
  console.log("createSingleCampaign inputs", values);
  API_INSTANCES.CAMPAIGN.createSingleCampaign(values)
    .then((response) => {
      const result: CampaignProps.campaignResponse = response.data;
      console.log("CREATE SINGLE CAMPAIGN RESPONSE", result);
      dispatch(actions.createJob.setStepNumber(3));
    })
    .catch((error) => console.log("CREATE SINGLE CAMPAIGN ERROR", error));
};
const createBoostedCampaign = (values: CampaignProps.createBoostedCampaign) => {
  console.log("createBoostedCampaign inputs", values);
  API_INSTANCES.CAMPAIGN.createBoostedCampaign(values)
    .then((response) => {
      const result: CampaignProps.campaignResponse = response.data;
      console.log("CREATE BOOSTED CAMPAIGN RESPONSE", result);
      dispatch(actions.createJob.setStepNumber(3));
    })
    .catch((error) => console.log("CREATE BOOSTED CAMPAIGN ERROR", error));
};
const updateBoostedCampaign = (values: CampaignProps.updateBoostedCampaign) => {
  API_INSTANCES.CAMPAIGN.updateBoostedCampaign(values)
    .then((response) => {
      const result: CampaignProps.campaignResponse = response.data;
      console.log("UPDATE BOOSTED CAMPAIGN RESPONSE", result);
    })
    .catch((error) => console.log("UPDATE BOOSTED CAMPAIGN ERROR", error));
};

//#region getCampaignProduct
const getCampaignProductFulFilled = (response: AxiosResponse<any, any>) => {
  const result: CampaignProps.campaignProductResponse = response.data;
  dispatch(actions.campaign.setCampaignProductResponse(result));
  console.log("GET CAMPAIGN PRODUCT RESPONSE", result);
};
const getCampaignProductRejected = (error: any) => {
  console.log("GET CMAPAIGN PRODUCT ERROR", error);
};
const getCampaignProduct = (id: string) => {
  API_INSTANCES.CAMPAIGN.getCampaignProduct(id)
    .then(getCampaignProductFulFilled)
    .catch(getCampaignProductRejected);
};
//#endregion

const getCampaignProducts = () => {
  API_INSTANCES.CAMPAIGN.getCampaignProducts()
    .then((response) => {
      const result: CampaignProps.campaignProductResponse = response.data;
      console.log("GET CMAPAIGN PRODUCTS RESPONSE", result);
      return response;
    })
    .catch((error) => console.log("GET CMAPAIGN PRODUCTS ERROR", error));
};

//#region getChartData
const getChartDataFulfilled = (response: AxiosResponse<any, any>) => {
  const { setChartData, setChartDataError } = actions.campaign;
  console.log("GET CHART RESPONSE", response.data);
  dispatch(setChartData(response.data));
  dispatch(setChartDataError(""));
};
const getChartDataRejected = (error: any) => {
  const { setChartDataError } = actions.campaign;
  if (error.response.status === NO_RESULTS_ERROR)
    dispatch(setChartDataError(CAMPAIGN_ERRORS[NO_RESULTS_ERROR]));
  else if (error.response.status === NOT_ENOUGH_DATA_ERROR)
    dispatch(setChartDataError(CAMPAIGN_ERRORS[NOT_ENOUGH_DATA_ERROR]));
  else if (error.response.status === INPUT_DATA_ERROR) {
    const errorDetail = error.response.data.detail;
    const fieldName = errorDetail.loc[1] as keyof CampaignProps.getChartDataAPI;
    const message = errorDetail.msg;
    const errorMessage = `${CAMPAIGN_ERRORS[INPUT_DATA_ERROR]} ${FIELDS.CAMPAIGN[fieldName]}: ${message}`;
    dispatch(setChartDataError(errorMessage));
  } else dispatch(setChartDataError(error.response.data.detail));
  console.log("GET CHART ERROR", error);
};
const getChartData = (data: CampaignProps.getChartDataAPI) => {
  const { setChartDataAPIInProgress } = actions.campaign;
  dispatch(setChartDataAPIInProgress(true));
  API_INSTANCES.CAMPAIGN.getChartData(data)
    .then(getChartDataFulfilled)
    .catch(getChartDataRejected)
    .finally(() => {
      dispatch(setChartDataAPIInProgress(false));
    });
};
//#endregion

const getBillingProducts = () => {
  API_INSTANCES.JOBS.getBillingProducts()
    .then((response) => {
      const data: CampaignProps.advertiseDetails = response.data;
      console.log("GET BILLING PRODUCTS RESPONSE: ", data);
      dispatch(actions.campaign.setAdvertiseDetails(data));
    })
    .catch((error) => {
      console.log("GET BILLING PRODUCTS ERROR: ", error);
    });
};

export const CAMPAIGN = {
  createBoostedCampaign,
  createSingleCampaign,
  updateBoostedCampaign,

  getCampaignProduct,
  getCampaignProductFulFilled,
  getCampaignProductRejected,

  getCampaignProducts,
  getBillingProducts,

  getChartData,
  getChartDataFulfilled,
  getChartDataRejected,
};
