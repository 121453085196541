import { Grid } from "antd";
import styled from "styled-components";

import { Buttons, Typography, TypographySpan } from "src/components/Basic";
import { AdvertiseStyles } from "./Styles";
import { PremiumPointer } from "./PremiumPointer";
import { AdvertiseProps } from "./Advertise";
import { BoostedPointer } from "./BoostedPointer";
import { actions, useAppDispatch, useAppSelector } from "src/Redux";
import { CustomColorsProps } from "src/components/Theme";
import { AdvertiseUtils } from "./Utils";

const AdvertiseTitle = ({ title, isOpen }: AdvertiseProps.TitleProps) => {
  const screen = Grid.useBreakpoint();
  const { isDarkTheme } = useAppSelector((store) => store.common);

  return (
    <Typography
      content={title}
      styleName={screen.lg ? "jobAdvertiseTitle" : "jobAdvertiseTitleMobile"}
      color={isOpen ? "white_pure" : isDarkTheme ? "black30" : "black70"}
    />
  );
};

const AdvertisePrice = ({
  price,
  isOpen,
  isStriked = false,
}: AdvertiseProps.PriceProps) => {
  const screen = Grid.useBreakpoint();
  const { isDarkTheme } = useAppSelector((store) => store.common);
  const color = isOpen ? "white_pure" : isDarkTheme ? "black30" : "black70";
  return (
    <AdvertisePriceContainer className="price-container">
      <Typography
        content={price}
        styleName={screen.lg ? "jobAdvertisePrice" : "jobAdvertisePriceMobile"}
        color={color}
      />
      {isStriked && <StrikeThrough color={color} />}
    </AdvertisePriceContainer>
  );
};
const AdvertisePriceContainer = styled.div`
  position: relative;
  width: fit-content;
  height: 17px;
  @media (min-width: 567px) {
    height: auto;
  }
`;
const StrikeThrough = styled.div<{ color: keyof CustomColorsProps.props }>`
  position: absolute;
  width: 120%;
  top: 50%;
  left: -10%;
  height: 2px;
  background-color: ${({ theme, color }) => theme.colors[color]}90;

  @media (min-width: 992px) {
    width: 140%;
    left: -20%;
  }
`;

const BoostedTitle = ({ title, isOpen }: AdvertiseProps.TitleProps) => {
  const screen = Grid.useBreakpoint();

  return (
    <div style={{ paddingTop: screen.md ? "37px" : "0" }}>
      <Typography
        content={title}
        styleName={screen.md ? "jobBoostedTitle" : "jobBoostedTitleMobile"}
        color={!screen.md && !isOpen ? "white_pure" : "primary_default"}
      />
    </div>
  );
};

const Point = ({ bold, normal, advertType }: AdvertiseProps.PointProps) => {
  const { isDarkTheme } = useAppSelector((store) => store.common);

  const { StandardPointer } = AdvertiseStyles;
  const Pointers = {
    standard: <StandardPointer />,
    premium: <PremiumPointer />,
    boosted: <BoostedPointer />,
  };

  return (
    <AdvertiseStyles.Point>
      <AdvertiseStyles.PointerWrapper {...{ advertType }}>
        {Pointers[advertType]}
      </AdvertiseStyles.PointerWrapper>
      <span>
        <TypographySpan
          content={`${bold}`}
          styleName="jobAdvertisePointBold"
          color={isDarkTheme ? "black50" : "black70"}
        />
        <TypographySpan
          content={normal}
          styleName="jobAdvertisePointNormal"
          color={isDarkTheme ? "black50" : "black70"}
        />
      </span>
    </AdvertiseStyles.Point>
  );
};

const SelectButton = ({ advertType }: AdvertiseProps.SelectButton) => {
  const dispatch = useAppDispatch();
  const { stripe, campaign } = useAppSelector((store) => store);
  const { stripeData } = stripe;
  const { campaignPrices } = campaign;

  return (
    <AdvertiseStyles.SelectButtonWrapper>
      <Buttons
        label="Select"
        variant="select"
        handleClick={() => {
          if (advertType === "boosted") {
            dispatch(actions.campaign.setBoostedSelected(true));
            return;
          }

          dispatch(actions.campaign.setAdvertType(advertType));
          dispatch(
            actions.stripe.setStripeData({
              ...stripeData,
              totalPrice: campaignPrices[advertType],
            })
          );
          AdvertiseUtils.postCampaign(campaign.boostedCampaignForm);
        }}
      />
    </AdvertiseStyles.SelectButtonWrapper>
  );
};

export const CreateJobAdvertiseCommon = {
  AdvertiseTitle,
  AdvertisePrice,
  BoostedTitle,
  Point,
  SelectButton,
};
