export const darkmode = () => {
  document.querySelector(".app")?.classList.toggle("dark-theme");
  document.querySelector(".app")?.classList.remove("light-theme");
};

export const closeSiderBar = () => {
  document.querySelector("body")?.classList.remove("sidenav-toggled");
};

export const openCloseSidebar = () => {
  document.querySelector("body")?.classList.toggle("sidenav-toggled");
};

export const handleChangeTheme = (currentTheme: string, applyTheme: string) => {
  document.querySelector(".app")?.classList.add(applyTheme);
  document.querySelector(".app")?.classList.remove(currentTheme);
};
