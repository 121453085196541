import React from "react";
import { Accordion } from "react-bootstrap";

import { Education } from "src/components/Pipeline/PipeLine";
import { AccordionHeader } from "../ExperienceStyle";
import { ICONS } from "src/assets/custom_Icons";
import { Typography } from "src/components/Basic";

const EducationAccordion: React.FC<{
  accordianData: Education[];
}> = ({ accordianData }) => {
  return (
    <Accordion
      defaultActiveKey={String(accordianData[0].candidate_id)}
      className="d-flex flex-column gap-2"
    >
      {accordianData.map((item) => (
        <Accordion.Item
          eventKey={String(item.candidate_id)}
          className="accordion-heading"
          key={item.candidate_id}
        >
          <Accordion.Header>
            <AccordionHeader>
              <ICONS.PlusCircleIcon />
              <div className="d-flex flex-column gap-2">
                <Typography
                  content={item.qualification}
                  styleName="candidateNameEmailOpen"
                  color="primary_default"
                />
                <Typography
                  content={item.institution}
                  styleName="pipelineDefaultNav"
                  color="black80"
                />
                <Typography
                  content={` ${item.year_from} - ${item.year_to}`}
                  styleName="careerYear"
                  color="primary_default"
                />
              </div>
            </AccordionHeader>
          </Accordion.Header>
          <Accordion.Body>
            <div dangerouslySetInnerHTML={{ __html: item.description }} />
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

export default EducationAccordion;
