import { createSlice } from "@reduxjs/toolkit";
import SettingsProps from "./Settings";
import { TableProps as OverviewTable } from "src/components/Settings/Overview/Overview";

const initialState: SettingsProps.SettingsState = {
  userData: [],
  editUserData: {} as OverviewTable.column,
  receiptData: [],
  companyGallery: [],
  company_image: "",
  cover_image: "",
  profile_picture: "",
  companyProfileDetail: {
    company_name: "",
    display_name: "",
    city_code: "",
    city_name: "",
    country_code: "",
    size: "",
    company_bio: "",
    company_description: "",
    company_website: "",
    social_linkedin: "",
    social_facebook: "",
    social_twitter: "",
    social_instagram: "",
    social_youtube: "",
    stripe_id: "",
  },
  unverifiedMemberDetails: {
    first_name: "",
    last_name: "",
    email: "",
    company_name: "",
    company_logo: "",
    valid_token: false,
  },
  candidateDetails: {} as SettingsProps.CandidateDetails,
  emailNotification: {
    daily_applications: false,
    weekly_applications: false,
    weekly_account: false,
    monthly_account: false,
  },
  isUpdatePasswordButtonDisable: false,
  isUpdateInfoButtonDisable: false,
  isCompanyProfileChangesButtonDisable: false,
  isAddPhotoGalleryButtonDisable: false,
  isGalleryPhotoEdit: false,
  isVerifyMemberDetail: false,
  isMemberInvitedValidLink: true,
  teamManagementError: "",
  selectedGalleryPhotoIndex: -1,
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setReceiptData: (state, action) => {
      state.receiptData = action.payload;
    },
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    setUnverifiedMemberDetails: (state, action) => {
      state.unverifiedMemberDetails = action.payload;
    },
    setEditUserData: (state, action) => {
      state.editUserData = action.payload;
    },
    setCompanyProfileDetail: (state, action) => {
      state.companyProfileDetail = action.payload;
    },
    setCompanyImage: (state, action) => {
      state.company_image = action.payload;
    },
    setCoverImage: (state, action) => {
      state.cover_image = action.payload;
    },
    setCompanyGallery: (state, action) => {
      state.companyGallery = action.payload;
    },
    setCandidateDetails: (state, action) => {
      state.candidateDetails = action.payload;
    },
    setCandidateProfilePhoto: (state, action) => {
      state.profile_picture = action.payload;
    },
    setEmailNotification: (state, action) => {
      state.emailNotification = action.payload;
    },
    setUpdatePasswordButtonDisable: (state, action) => {
      state.isUpdatePasswordButtonDisable = action.payload;
    },
    setUpdateInfoButtonDisable: (state, action) => {
      state.isUpdateInfoButtonDisable = action.payload;
    },
    setCompanyProfileChangesButtonDisable: (state, action) => {
      state.isCompanyProfileChangesButtonDisable = action.payload;
    },
    setAddPhotoGalleryButtonDisable: (state, action) => {
      state.isAddPhotoGalleryButtonDisable = action.payload;
    },
    setGalleryPhotoEdit: (state, action) => {
      state.isGalleryPhotoEdit = action.payload;
    },
    setVerifyMemberDetail: (state, action) => {
      state.isVerifyMemberDetail = action.payload;
    },
    setMemberInvitedValidLink: (state, action) => {
      state.isMemberInvitedValidLink = action.payload;
    },
    setTeamManagementError: (state, action) => {
      state.teamManagementError = action.payload;
    },
    setSelectedGalleryPhotoIndex: (state, action) => {
      state.selectedGalleryPhotoIndex = action.payload;
    },
  },
});

export const settingsActions = settingsSlice.actions;
export const settingsReducer = settingsSlice.reducer;
