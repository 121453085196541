import React from "react";
import { Formik, FormikValues } from "formik";
import clsx from "clsx";
import * as Yup from "yup";

import { Buttons, Typography } from "src/components/Basic";
import { ICONS } from "src/assets/custom_Icons";
import { FormFieldsProps } from "src/components/Basic/FormFields/FormFields";
import { FormFields } from "src/components/Basic/FormFields/FormikFields";
import { useAppSelector } from "src/Redux";
import CommentDetail from "./CommentDetail";
import { FormikHelpers } from "src/components/Basic/FormFields/FormikFields/FormikFields.helpers";
import { API_CALLS } from "src/API_CALLS";
import {
  AddCommentContainer,
  ButtonContainer,
  CommentsContainer,
  Container,
  HeadingContainer,
  IconContainer,
  TimelineContainer,
} from "./CommentsStyle";

const Comments = () => {
  const { candidate, settings } = useAppSelector((state) => state);
  const { comment, selectedCandidate } = candidate;
  const [isAddCommentOpen, setIsAddCommentOpen] = React.useState(false);

  const richTextAreaProps: FormFieldsProps.RichTextarea = {
    fieldName: "comment",
    idPrefix: "comment",
    isRequired: true,
    placeholder: "Text Area",
    textLimit: 2000,
    showTextCount: true,
  };

  React.useEffect(() => {
    if (comment.length === 0 && selectedCandidate.section_comments) {
      API_CALLS.PIPELINE.getCandidateComment(selectedCandidate.application_id);
    }
  }, []);

  const onSubmitHandler = (value: FormikValues) => {
    API_CALLS.PIPELINE.postCandidateComment({
      application_id: Number(selectedCandidate.application_id),
      comment: value.comment,
      user_id: settings.candidateDetails.id,
    });

    setIsAddCommentOpen(false);
  };

  return (
    <CommentsContainer>
      <HeadingContainer>
        <Typography
          content="COMMENTS"
          styleName="candidateNameEmailOpen"
          color="primary_default"
        />
      </HeadingContainer>
      <ButtonContainer>
        <Buttons
          isDisable={isAddCommentOpen}
          handleClick={() => {
            setIsAddCommentOpen(true);
          }}
          label="Add comment"
          variant="success"
        />
        <div
          className={clsx(isAddCommentOpen && "opacity-50")}
          onClick={() => {
            setIsAddCommentOpen(true);
          }}
        >
          <ICONS.PlusCircleIcon />
        </div>
      </ButtonContainer>
      {isAddCommentOpen && (
        <AddCommentContainer>
          <Formik
            initialValues={{ comment: "", comment_plain: "" }}
            validationSchema={Yup.object({ comment: Yup.string().required() })}
            onSubmit={() => {}}
          >
            {(formik) => {
              return (
                <div className="d-flex flex-column">
                  <FormFields.RichTextArea {...richTextAreaProps} />
                  <div className=" d-flex gap-2 justify-content-end">
                    <Buttons
                      handleClick={() => {
                        setIsAddCommentOpen(false);
                      }}
                      label="Cancel"
                      variant="light"
                    />
                    <Buttons
                      handleClick={() => {
                        if (formik.values.comment_plain.length <= 2000) {
                          FormikHelpers.formikSubmitHandler({
                            formFields: { richTextAreaProps },
                            formik,
                            idPrefix: "comment",
                            onSubmitHandler,
                          });
                        }
                      }}
                      label="Add"
                      variant="primary"
                    />
                  </div>
                </div>
              );
            }}
          </Formik>
        </AddCommentContainer>
      )}
      {comment?.length > 0 ? (
        <TimelineContainer className="vtimeline">
          {comment?.map((comment) => (
            <CommentDetail {...comment} />
          ))}
        </TimelineContainer>
      ) : (
        <Container>
          <HeadingContainer className="no-data-msg">
            <Typography
              content={
                selectedCandidate.section_comments
                  ? "Loading ..."
                  : "No  comments added.."
              }
              styleName="text1"
              color="black80"
            />
          </HeadingContainer>
          <IconContainer>
            <ICONS.MessageIcon />
          </IconContainer>
        </Container>
      )}
    </CommentsContainer>
  );
};

export default Comments;
