import styled from "styled-components";

import { theme } from "src/components/Theme";
import { TermsAndPrivacyComponents } from "../Components";

const { Points, Text } = TermsAndPrivacyComponents;

export const WhoWeAre = () => {
  return (
    <>
      <Points.Heading
        pointNumber="1."
        heading="Important Information and Who We Are"
      />
      <Points.Indent>
        <>
          <POINT_1_1 />
          <POINT_1_2 />
          <POINT_1_3 />
          <POINT_1_4 />
          <POINT_1_5 />
        </>
      </Points.Indent>
    </>
  );
};

//#region 1.1
const POINT_1_1 = () => (
  <>
    <Points.SubHeading
      pointNumber="1.1."
      heading="Purpose of this Privacy Policy"
    />
    <Points.Indent>
      <>
        <Points.Span2 pointNumber="1.1.1." Content={POINT_1_1_1} />
        <Points.Span2 pointNumber="1.1.2." Content={POINT_1_1_2} />
        <Points.Span2 pointNumber="1.1.3." Content={POINT_1_1_3} />
      </>
    </Points.Indent>
  </>
);
const POINT_1_1_1 = (
  <Text.Span content="This Privacy Policy aims to give you information on how we collect and process your personal data through any form of your engagement with us." />
);
const POINT_1_1_2 = (
  <Text.Span content="It is important that you read this Privacy Policy together with any other privacy policy or fair processing notice we may provide on specific occasions when we are collecting or processing personal data about you, so that you are fully aware of how and why we are using your personal data. This Privacy Policy supplements the other notices and is not intended to override them." />
);
const POINT_1_1_3 = (
  <Text.Span content="We do not process the data of minors or special categories of personal data. Do not provide us with any such personal data, where the provision of same will constitute an immediate and automatic material breach of this Privacy Policy and our Terms and Conditions of Use." />
);
//#endregion

//#region 1.2
const POINT_1_2 = () => (
  <>
    <Points.SubHeading pointNumber="1.2." heading="Controller and Processor" />
    <Points.Indent>
      <>
        <Points.Span2 pointNumber="1.2.1." Content={POINT_1_2_1} />
        <Points.Span2 pointNumber="1.2.2." Content={POINT_1_2_2} />
      </>
    </Points.Indent>
  </>
);
const POINT_1_2_1 = (
  <Text.Span content="Ditto Media is the “Controller” and is responsible for your personal data when we decide the processing operations of your personal data. In most instances we operate as an “Processor” of personal data on behalf of a Controller who uses our services. In these cases, that Controller’s privacy policy will apply to your use of their services." />
);
const POINT_1_2_2 = (
  <Text.Span content="We have appointed a data protection officer at Ditto Media who is responsible for overseeing questions in relation to this Privacy Policy. If you have any questions about this Privacy Policy, including any requests to exercise your legal rights, please contact our data protection officer using the details set out below." />
);
//#endregion

//#region 1.3
const POINT_1_3 = () => (
  <>
    <Points.SubHeading pointNumber="1.3." heading="Our Contact Details" />
    <Points.Indent>
      <>
        <Points.Span2 pointNumber="1.3.1." Content={POINT_1_3_1} />
        <Points.Indent>
          <Points.Indent>
            <CompanyDetails />
          </Points.Indent>
        </Points.Indent>
        <Points.Span2 pointNumber="1.3.2." Content={POINT_1_3_2} />
      </>
    </Points.Indent>
  </>
);
const POINT_1_3_1 = <Text.Span content="Our full details are:" />;
const POINT_1_3_2 = (
  <Text.Span content="You have the right to make a complaint at any time to any applicable regulator. We would, however, appreciate the chance to deal with your concerns before you approach any such regulator, so please contact us in the first instance." />
);

const CompanyDetails = () => (
  <CompanyDetailsContainer>
    <tr>
      <td>
        <Square />
      </td>
      <th>Full name of legal entity:</th>
      <td>Ditto Media Company</td>
    </tr>
    <tr>
      <td>
        <Square />
      </td>
      <th>Email address:</th>
      <td>legal@ditto.work</td>
    </tr>
    <tr>
      <td>
        <Square />
      </td>
      <th>Postal address:</th>
      <td>651 North Broad Street, Middletown, Delaware, 19709</td>
    </tr>
    <tr>
      <td>
        <Square />
      </td>
      <th>Telephone number:</th>
      <td>+1 (507) 414 6981</td>
    </tr>
  </CompanyDetailsContainer>
);
const CompanyDetailsContainer = styled.table`
  td,
  th {
    vertical-align: top;
  }
`;
const Square = styled.div`
  height: 4px;
  width: 4px;
  background-color: ${theme.colors.black80};
  margin: 8px 5px 0px 0px;
  @media (min-width: 576px) {
    margin:0
    margin-right: 5px;
  }
`;
//#endregion

//#region 1.4
const POINT_1_4 = () => (
  <>
    <Points.SubHeading
      pointNumber="1.4."
      heading="Changes to this Privacy Policy "
    />
    <Points.Indent>
      <>
        <Points.Span2 pointNumber="1.4.1." Content={POINT_1_4_1} />
        <Points.Span2 pointNumber="1.4.2." Content={POINT_1_4_2} />
        <Points.Span2 pointNumber="1.4.3." Content={POINT_1_4_3} />
      </>
    </Points.Indent>
  </>
);
const POINT_1_4_1 = (
  <Text.Span content="This Privacy Policy was last updated on 1 August 2023 and previous versions are archived and can be provided on request." />
);
const POINT_1_4_2 = (
  <Text.Span content="It is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes during your relationship with us." />
);
const POINT_1_4_3 = (
  <Text.Span content="This Privacy Policy is subject to change without notice and is updated or amended from time to time and will be effective once we upload the amended version to the Platform. Your continued access or use of our Services constitutes your acceptance of this Privacy Policy, as amended. It is your responsibility to read this document periodically to ensure you are aware of any changes." />
);
//#endregion

//#region 1.5
const POINT_1_5 = () => (
  <>
    <Points.SubHeading pointNumber="1.5." heading="Third-Party Links " />
    <Points.Indent>
      <Text.Span content="Nova may include links to third-party platforms, plug-ins, and applications. Clicking on those links or enabling those connections may allow third parties to collect or share information about you. We do not control these third-party platforms and are not responsible for their privacy statements or terms. When you leave Nova, or engage with such third parties, we encourage you to read the distinct privacy policy of every third-party you engage with." />
    </Points.Indent>
  </>
);
//#endregion
