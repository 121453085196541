import React from "react";
import { Formik, FormikValues } from "formik";
import { useNavigate } from "react-router-dom";
import { Upload } from "antd";
import ImgCrop from "antd-img-crop";

import { Buttons, FormFields, Typography } from "src/components/Basic";
import {
  CandidteProfileMainContainer,
  DetailContainer,
  HeadingDescriptionContainer,
  DetailPhotoContainer,
  ButtonContainer,
  ProfileImageContainer,
  EditContainer,
  PasswordContainer,
} from "./style";
import {
  myProfileDetailFormFields,
  idPrefix,
  myProfilePasswordFormFields,
} from "./utils";
import { FormikHelpers } from "src/components/Basic/FormFields/FormikFields/FormikFields.helpers";
import { ICONS } from "src/assets/custom_Icons";
import { API_CALLS } from "src/API_CALLS";
import { useAppSelector } from "src/Redux";
import { CandidateProfile } from "./CandidateProfile";

const CandidteProfile = () => {
  const {
    candidateDetails,
    profile_picture,
    isUpdateInfoButtonDisable,
    isUpdatePasswordButtonDisable,
  } = useAppSelector((state) => state.settings);

  const navigate = useNavigate();

  React.useEffect(() => {
    if (!profile_picture)
      API_CALLS.CANDIDATE_SETTINGS.getCandidateProfilePhoto();
  }, []);

  const onSubmitPasswordHandler = (value: FormikValues) => {
    const body: CandidateProfile.Password = {
      current_password: value.current_password,
      new_password: value.new_password,
    };
    API_CALLS.CANDIDATE_SETTINGS.updateCandidatePassword(body, navigate);
  };

  const onSubmitHandlerDetail = (value: FormikValues) => {
    const body: CandidateProfile.CandidateDetails = {
      first_name: value.first_name,
      last_name: value.last_name,
      email: value.email,
    };
    API_CALLS.CANDIDATE_SETTINGS.updateCandidateDetail(body);
  };

  return (
    <CandidteProfileMainContainer>
      <HeadingDescriptionContainer>
        <Typography
          content="MY PROFILE"
          styleName="tableButton"
          color="black80"
        />
        <Typography
          content="Choose Template. Choose Template. Choose Template. Choose template and add information. Choose Template. This is where we talk talk talk."
          styleName="text1"
          color="black80"
        />
      </HeadingDescriptionContainer>
      <Formik
        enableReinitialize
        initialValues={{
          first_name: candidateDetails.first_name,
          last_name: candidateDetails.last_name,
          email: candidateDetails.email,
        }}
        onSubmit={() => {}}
      >
        {(formik) => {
          return (
            <>
              <DetailPhotoContainer>
                <DetailContainer>
                  <FormFields.Input {...myProfileDetailFormFields.first_name} />
                  <FormFields.Input {...myProfileDetailFormFields.last_name} />
                  <FormFields.Input {...myProfileDetailFormFields.email} />
                </DetailContainer>
                <ProfileImageContainer>
                  {profile_picture ? (
                    <img
                      src={`${process.env.REACT_APP_NOVA_BASE_URL}${profile_picture}`}
                      alt=""
                      height={200}
                      width={200}
                    />
                  ) : (
                    <ICONS.Profile />
                  )}
                  <ImgCrop
                    rotationSlider
                    zoomSlider
                    modalTitle="Candidate Profile"
                    modalOk="Upload"
                  >
                    <Upload
                      accept="image/png, image/jpeg"
                      listType="picture"
                      maxCount={1}
                      customRequest={({ file }) => {
                        API_CALLS.CANDIDATE_SETTINGS.updateCandidateProfilePhoto(
                          file as File
                        );
                      }}
                    >
                      <EditContainer>
                        <ICONS.PenIcon />
                      </EditContainer>
                    </Upload>
                  </ImgCrop>
                </ProfileImageContainer>
              </DetailPhotoContainer>
              <ButtonContainer>
                <Buttons
                  handleClick={() => {
                    FormikHelpers.formikSubmitHandler({
                      formFields: myProfileDetailFormFields,
                      formik,
                      onSubmitHandler: onSubmitHandlerDetail,
                      idPrefix,
                    });
                  }}
                  label="Update Info"
                  variant="primary"
                  isDisable={isUpdateInfoButtonDisable}
                />
              </ButtonContainer>
            </>
          );
        }}
      </Formik>
      <Formik
        enableReinitialize
        initialValues={{
          current_password: "",
          new_password: "",
        }}
        onSubmit={() => {}}
      >
        {(formik) => {
          return (
            <>
              <PasswordContainer>
                <FormFields.Password
                  {...myProfilePasswordFormFields.current_password}
                />
                <FormFields.Password
                  {...myProfilePasswordFormFields.new_password}
                />
              </PasswordContainer>
              <ButtonContainer>
                <Buttons
                  handleClick={() => {
                    FormikHelpers.formikSubmitHandler({
                      formFields: myProfilePasswordFormFields,
                      formik,
                      onSubmitHandler: onSubmitPasswordHandler,
                      idPrefix,
                    });
                  }}
                  label="Update Password"
                  variant="primary"
                  isDisable={isUpdatePasswordButtonDisable}
                />
              </ButtonContainer>
            </>
          );
        }}
      </Formik>
    </CandidteProfileMainContainer>
  );
};

export default CandidteProfile;
