import { Navbar } from "react-bootstrap";

import { VerifyStyle } from "./TeamManagementStyle";
import { ICONS } from "src/assets/custom_Icons";
import { DarkModeButton } from "src/components/Basic";
import { useAppSelector } from "src/Redux";

const TeamManagementHeader = () => {
  const { isDarkTheme } = useAppSelector((state) => state.common);
  return (
    <VerifyStyle.TeamManagementHeader>
      <Navbar className="main-header d-flex justify-content-between">
        {isDarkTheme ? <ICONS.NovaWhite /> : <ICONS.NovaBlack />}
        <DarkModeButton />
      </Navbar>
    </VerifyStyle.TeamManagementHeader>
  );
};

export default TeamManagementHeader;
