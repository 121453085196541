import { Typography } from "src/components/Basic";
import { FormFields } from "src/components/Basic/FormFields";
import { ICONS } from "src/assets/custom_Icons";
import { useContext } from "react";
import { BoostedCampaignStyles } from "../../Styles";
import { BoostedCampaignContext } from "../..";
import { BoostedCampaignUtils } from "../../Utils";
import { useAppSelector } from "src/Redux";

export const BidInputs = () => {
  const { isTotalCampaignManuallyUpdated } = useAppSelector(
    (store) => store.campaign
  );
  const {
    currency,
    isBidEditable,
    setBidEditable,
    bidValue,
    setBidValue,
    formik,
  } = useContext(BoostedCampaignContext);

  const bidPriceColor = "primary_default";
  const { total_campaign_budget, campaign_input } =
    BoostedCampaignUtils.formFields;

  return (
    <BoostedCampaignStyles.BidPriceContainer className="price-container">
      <Typography
        content={currency}
        styleName="heading3"
        color={bidPriceColor}
      />
      {isBidEditable ? (
        <FormFields.Input
          {...campaign_input}
          defaultValue={bidValue}
          onChangeHandler={(value = 0) => {
            setBidValue(value || "0");
            if (isTotalCampaignManuallyUpdated) return;

            const newMinBudget = (parseFloat(`${value || 0}`) * 10).toFixed(2);
            formik.setFieldValue(total_campaign_budget.fieldName, newMinBudget);
          }}
          onBlurHandler={() => setBidEditable(false)}
        />
      ) : (
        <>
          <Typography
            content={`${bidValue}`}
            styleName="heading1"
            color={bidPriceColor}
          />
          <span
            style={{ cursor: "pointer", alignSelf: "center" }}
            onClick={() => setBidEditable(true)}
          >
            <ICONS.EditIcon height={20} width={20} />
          </span>
        </>
      )}
    </BoostedCampaignStyles.BidPriceContainer>
  );
};
