import { createSlice } from "@reduxjs/toolkit";
import { NotificationsProps } from "./Notification";

const initialState: NotificationsProps.State = {
  notificationList: [],
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setNotificationList: (state, action) => {
      state.notificationList = action.payload;
    },
  },
});

export const notificationActions = notificationSlice.actions;
export const notificationReducer = notificationSlice.reducer;
