import { API_INSTANCES } from "src/API_INSTANCES";
import { store, actions, CreateJobProps } from "src/Redux";
import { jobCreateUtils } from "src/components/pages/CreateJob/1-JobCreate/Utils";
import { API_CALLS } from ".";

const dispatch = store.dispatch;

const getJobs = (id: string | number) => {
  API_INSTANCES.JOBS.getJobs(id)
    .then((response) => {
      console.log("GET JOB RESPONSE", response);
    })
    .catch((error) => {
      console.log("GET JOB ERROR", error);
    });
};

// const create = (_values: CreateJobProps.Create) => {
//   dispatch(actions.createJob.setJobCreated(true));
//   dispatch(actions.createJob.setStepNumber(2));
//   dispatch(actions.createJob.setCreateError(``));
// };
const createJob = (values: CreateJobProps.CreateUpdateRequest) => {
  dispatch(actions.createJob.setCreateInProgress(true));
  API_INSTANCES.JOBS.createJob(values)
    .then((response) => {
      const result: CreateJobProps.CreateResponse = response.data;
      console.log("CREATE JOB RESPONSE", response);
      dispatch(actions.createJob.setCreateResponse(result));
      dispatch(actions.createJob.setCreateError(``));
      dispatch(actions.createJob.setStepNumber(2));

      API_CALLS.CAMPAIGN.getBillingProducts();
    })
    .catch((error) => {
      const errorMessage = error.message || "Error while creating job";

      const errorDetail = error.response?.data?.detail[0];
      const fieldName = errorDetail?.loc[1];
      let fieldLabel = jobCreateUtils.initialJobCreateFields[fieldName]?.label;

      const APIError = errorDetail
        ? `${fieldLabel || fieldName} ${errorDetail?.msg}`
        : errorMessage;
      dispatch(actions.createJob.setCreateError(`${APIError}`));
      console.log("CREATE JOB ERROR", error);
    })
    .finally(() => {
      dispatch(actions.createJob.setCreateInProgress(false));
      dispatch(actions.createJob.setJobCreated(true));
    });
};

const updateJob = (id: number, values: CreateJobProps.CreateUpdateRequest) => {
  dispatch(actions.createJob.setUpdateInProgress(true));
  API_INSTANCES.JOBS.updateJob(id, values)
    .then((response) => {
      const result: CreateJobProps.UpdateResponse = response.data;
      console.log("UPDATE JOB RESPONSE", response);
      dispatch(actions.createJob.setUpdateResponse(result));
      dispatch(actions.createJob.setUpdateError(``));
      dispatch(actions.createJob.setStepNumber(2));
    })
    .catch((error) => {
      const errorMessage = error.message || "Error while updating job";

      const errorDetail = error.response?.data?.detail[0];
      const fieldName = errorDetail?.loc[1];
      let fieldLabel = jobCreateUtils.initialJobCreateFields[fieldName]?.label;

      const APIError = errorDetail
        ? `${fieldLabel || fieldName} ${errorDetail?.msg}`
        : errorMessage;
      dispatch(actions.createJob.setUpdateError(`${APIError}`));
      console.log("UPDATE JOB ERROR", error);
    })
    .finally(() => {
      dispatch(actions.createJob.setUpdateInProgress(false));
    });
};

export const JOBS = { getJobs, createJob, updateJob };
