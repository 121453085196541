export const Rocket = () => (
  <svg
    width="49"
    height="77"
    viewBox="0 0 49 77"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.4438 46.6237L0.108978 63.7246L7.57624 54.8331L14.2568 56.1881L9.98123 43.7301L2.4438 46.6237Z"
      fill="#24D5B8"
    />
    <path
      d="M46.5562 46.6237L48.891 63.7246L41.4238 54.8331L34.7417 56.1881L39.0188 43.7301L46.5562 46.6237Z"
      fill="#24D5B8"
    />
    <ellipse
      cx="24.5575"
      cy="58.1874"
      rx="3.80678"
      ry="17.6896"
      fill="#FFD747"
    />
    <path
      d="M24.4485 0.0753784C15.8049 0.0753784 8.79889 17.1627 8.79889 38.2412C8.79889 46.1796 11.7338 53.5505 13.4342 59.6568H35.4613C37.1617 53.5505 40.0966 46.1796 40.0966 38.2412C40.0966 17.1627 33.0906 0.0753784 24.4485 0.0753784Z"
      fill="#1E96F2"
    />
    <path
      d="M24.4127 29.866C27.5902 29.866 30.1661 26.4284 30.1661 22.188C30.1661 17.9475 27.5902 14.5099 24.4127 14.5099C21.2351 14.5099 18.6592 17.9475 18.6592 22.188C18.6592 26.4284 21.2351 29.866 24.4127 29.866Z"
      fill="#0C5E9D"
    />
    <path
      d="M12.2608 55.7244C12.6803 57.0853 13.0834 58.3981 13.4342 59.6567H35.4613C35.8121 58.3981 36.2152 57.0838 36.6347 55.7244H12.2608Z"
      fill="#68BCFC"
    />
    <g clip-path="url(#clip0_25_1077)">
      <path
        d="M26.7566 43.3087C26.7566 42.0079 25.9609 35.2182 24.7009 34.8147C24.577 34.7635 24.4397 34.7635 24.3143 34.8147C23.0498 35.1986 22.2526 42.0049 22.2526 43.3087C21.8675 49.1139 21.7451 52.2543 22.4706 56.6745C23.023 60.0408 23.7455 63.4221 24.4815 64.0183V64.0514C24.4815 64.0514 24.4964 64.0394 24.5024 64.0348C24.5098 64.0409 24.5173 64.0469 24.5233 64.0514V64.0183C25.2593 63.4236 25.9818 60.0423 26.5342 56.6745C27.2597 52.2543 27.1373 49.1139 26.7521 43.3087H26.7566Z"
        fill="#24D5B8"
      />
    </g>
    <defs>
      <clipPath id="clip0_25_1077">
        <rect
          width="5.119"
          height="29.2743"
          fill="white"
          transform="translate(21.945 34.7769)"
        />
      </clipPath>
    </defs>
  </svg>
);
