import { useState } from "react";
import { Form } from "react-bootstrap";
import { Field, FieldProps } from "formik";
import styled from "styled-components";
import PasswordStrengthBar from "react-password-strength-bar";

import { IInput } from "./Input";
import { HidePasswordIcon, ShowPasswordIcon } from "../Icon";
import { REGEX, formFieldsHelpers } from "src/components/Basic/";
import { ErrorLabel } from "../style";
import { theme } from "src/components/Theme";

const Input = ({
  idPrefix,
  fieldName,
  placeholder,
  isRequired,
  disabled = false,
  onChangeHandler,
  onKeyDownHandler,
  InputType = "text",
  showErrorMessage = isRequired,
  showPasswordMeter = false,
}: IInput.IProps) => {
  const [type, setType] = useState<"text" | "password">("password");
  const [fieldPlaceHolder, setFieldPlaceHolder] = useState(placeholder);
  const [isFocused, setFocused] = useState(false);

  const onEyeClick = () => setType(type === "text" ? "password" : "text");
  const showPassword = type === "text" ? true : false;

  return (
    <Field name={fieldName} key={fieldName}>
      {({ field, meta }: FieldProps) => {
        const { onChange, value, onBlur } = field;
        const { touched, error } = meta;

        let hasError = isRequired && touched && !isFocused && !value;
        let errorLabel = error || `${placeholder} is Required`;
        const isPassword = InputType === "password";
        if (isPassword) {
          const isValidPassword = REGEX.PASSWORD.test(value);
          if (value && !isValidPassword) {
            hasError = !isFocused && true;
            errorLabel = formFieldsHelpers.getPasswordError(value);
          }
        }
        if (InputType === "URL") {
          const isValidURL = REGEX.WEBSITE.test(value);
          if (value && !isValidURL) {
            hasError = !isFocused && true;
            errorLabel = "Please enter a valid URL";
          }
        }
        const showErrorLabel = showErrorMessage && hasError && errorLabel;

        return (
          <InputContainer>
            <Container>
              <Form.Control
                id={`${idPrefix}_${fieldName}`}
                onFocus={() => {
                  setFieldPlaceHolder("");
                  setFocused(true);
                }}
                type={isPassword ? type : "text"}
                className="form-control"
                name={fieldName}
                placeholder={fieldPlaceHolder}
                onChange={(e) => {
                  onChange(e);
                  onChangeHandler && onChangeHandler();
                }}
                value={value}
                disabled={disabled}
                onBlur={(e) => {
                  onBlur(e);
                  setFieldPlaceHolder(placeholder);
                  setFocused(false);
                }}
                onKeyDown={onKeyDownHandler && onKeyDownHandler}
              />
              {isPassword && showPasswordMeter && (
                <PasswordStrengthBar
                  className="password-strength"
                  password={value}
                />
              )}
              {isPassword && (
                <IconContainer onClick={onEyeClick}>
                  {showPassword ? <HidePasswordIcon /> : <ShowPasswordIcon />}
                </IconContainer>
              )}
            </Container>
            {showErrorLabel && <ErrorLabel>{errorLabel}</ErrorLabel>}
          </InputContainer>
        );
      }}
    </Field>
  );
};

export default Input;

const InputContainer = styled.div`
  width: 100%;
  input {
    width: 100%;
    color: ${theme.colors.black80} !important;
    background: transparent;
    border: none !important;
    border-bottom: 1px solid ${theme.colors.primary_default} !important;
    outline: none !important;
    border-radius: 0px;
    padding: 0px 0px 8px;
    ${theme.text.authInputText}
  }
  input:active {
    background: transparent;
  }
  input:focus {
    background: transparent;
  }
  input:hover {
    background: transparent;
  }
  .password-strength p {
    margin: 0;
  }
`;
const Container = styled.div`
  position: relative;
`;
const IconContainer = styled.div`
  position: absolute;
  right: 10px;
  top: 7px;
  cursor: pointer;
`;
