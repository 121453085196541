import { NavigateFunction, useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";

import { Typography } from "../../../Basic";
import {
  Container,
  DropDownContainer,
  DropDownMenuContainer,
} from "../JobStyle";
import { IJobs } from "../Job";
import { ActiveJobsProps } from "src/Redux/slices/ActiveJobs/ActiveJobs";
import { ROUTES } from "src/utils/ROUTES";
import { store, actions, CampaignProps } from "src/Redux";
import { API_INSTANCES } from "src/API_INSTANCES";
import { API_CALLS } from "src/API_CALLS";

const advertiseClickHandler = async (
  id: string,
  navigate: NavigateFunction
) => {
  const { createJob, campaign } = actions;
  try {
    const result = await API_INSTANCES.CAMPAIGN.getCampaignProduct(id);
    API_CALLS.CAMPAIGN.getCampaignProductFulFilled(result);

    const campaignProductData: CampaignProps.campaignProductResponse =
      result.data;
    try {
      const { job_title, city_id } = campaignProductData;
      const getChartData = { job_title, city_id };
      const result = await API_INSTANCES.CAMPAIGN.getChartData(getChartData);
      API_CALLS.CAMPAIGN.getChartDataFulfilled(result);
    } catch (error) {
      console.log("IN CATCH");
      API_CALLS.CAMPAIGN.getChartDataRejected(error);
    }

    const campaign_duration_from = new Date(campaignProductData.start_date);
    const campaign_duration_to = new Date(campaignProductData.end_date);
    const boostedCampaignForm = {
      campaign_duration_from: campaign_duration_from.getTime(),
      campaign_duration_to: campaign_duration_to.getTime(),
      campaign_input: campaignProductData.cpc.toString(),
      total_campaign_budget: campaignProductData.budget.toString(),
    };

    store.dispatch(campaign.setBoostedCampaignForm(boostedCampaignForm));
    store.dispatch(createJob.setStepNumber(2));
    store.dispatch(campaign.setBoostedSelected(true));
    store.dispatch(campaign.setTotalCampaignManuallyUpdated(true));
    store.dispatch(createJob.setIsEditFlow(true));
    navigate(ROUTES.CREATE_JOB);
  } catch (error) {
    API_CALLS.CAMPAIGN.getCampaignProductRejected(error);
  }
};

export const OptionsColumn = ({
  cell,
  filterType,
  setIsModalOpen,
  className,
}: IJobs.CustomCellItem) => {
  const navigate = useNavigate();

  const rowdata = cell.row.original as ActiveJobsProps.jobOverview;
  const { advertise_update, id } = rowdata;

  return (
    <Container className={className}>
      <Dropdown>
        <DropDownContainer className="btn btn-danger">
          <FontAwesomeIcon icon={faEllipsisV} size="xl" className="user-icon" />
        </DropDownContainer>
        <DropDownMenuContainer className="dropdown-menu tx-13 custom-dropdown">
          <DropdownItem content="Edit" />
          {(filterType === "active" || filterType === "drafts") && (
            <DropdownItem
              content="Advertising"
              disabled={!advertise_update}
              clickHandler={() => advertiseClickHandler(id, navigate)}
            />
          )}
          {filterType === "active" && <DropdownItem content="View" />}
          {filterType === "drafts" && (
            <DropdownItem
              content="Delete"
              clickHandler={() => setIsModalOpen(true)}
            />
          )}
          {filterType === "archived" && <DropdownItem content="Settings" />}
        </DropDownMenuContainer>
      </Dropdown>
    </Container>
  );
};

//#region DropdownItem
type DropdownItemProps = {
  content: string;
  clickHandler?: () => void;
  disabled?: boolean;
};
const DropdownItem = ({
  content,
  clickHandler,
  disabled,
}: DropdownItemProps) => {
  const className = disabled ? "dropdown-item-disabled" : "dropdown-item";
  return (
    <Dropdown.Item
      className={className}
      href="#"
      onClick={disabled ? () => {} : clickHandler && clickHandler}
    >
      <Typography
        content={content}
        styleName="tableSubHeading"
        color={disabled ? "black50" : "black_pure"}
      />
    </Dropdown.Item>
  );
};
//#endregion
