import { Row } from "react-bootstrap";
import { useContext, useState } from "react";

import { Buttons, Typography } from "src/components/Basic";
import { CheckoutStyles } from "../Styles";
import {
  StripeProps,
  actions,
  useAppDispatch,
  useAppSelector,
} from "src/Redux";
import { AppContext } from "src/App";
import { CheckoutSummaryContext } from ".";
import { PromotionCode } from "./PromotionCode";
import { PromotionCodeInput } from "./PromotionCodeInput";

export const Bill = () => {
  const dispatch = useAppDispatch();
  const { isDesktop } = useContext(AppContext);
  const { priceClasses, currency } = useContext(CheckoutSummaryContext);
  const { common, stripe } = useAppSelector((store) => store);
  const { isDarkTheme } = common;
  const { stripeData } = stripe;

  const [showPromotionCodeInput, setShowPromotionCodeInput] = useState(false);
  const [promotionCode, setPromotionCode] = useState("");
  const [isApplyClicked, setApplyClicked] = useState(false);

  const priceColor = isDarkTheme ? "black30" : "black90";
  const priceStyle = "jobCheckoutPriceSummary";
  const totalPriceStyle = isDesktop
    ? "jobCheckoutPriceSummary"
    : "jobCheckoutTotalPriceSummary";

  return (
    <CheckoutStyles.Bill>
      <Row className={priceClasses}>
        <Typography
          styleName={priceStyle}
          content="Subtotal"
          color={priceColor}
        />
        <Typography
          styleName={priceStyle}
          content={`${currency}${stripeData.subTotal}`}
          color={priceColor}
        />
      </Row>
      <CheckoutStyles.Underline />

      {isApplyClicked ? (
        <PromotionCode
          {...{
            promotionCode,
            setApplyClicked,
            setPromotionCode,
            setShowPromotionCodeInput,
          }}
        />
      ) : (
        <CheckoutStyles.PromotionButtonWrapper>
          {showPromotionCodeInput ? (
            <PromotionCodeInput
              onChangeHandler={(value) => setPromotionCode(value.toUpperCase())}
              value={promotionCode}
              label="Promotion Code"
              onApplyClick={() => {
                if (promotionCode) {
                  setApplyClicked(true);
                  const newStripeData: StripeProps.stripeData = {
                    ...stripeData,
                    discount: 9.18,
                    discountPercentage: 20,
                  };
                  console.log("SETTING DISCOUNT", newStripeData);
                  dispatch(actions.stripe.setStripeData(newStripeData));
                } else setShowPromotionCodeInput(false);
              }}
            />
          ) : (
            <Buttons
              handleClick={() => setShowPromotionCodeInput(true)}
              label="Add promotion code"
              variant="addPromotion"
            />
          )}
        </CheckoutStyles.PromotionButtonWrapper>
      )}

      <Row className={priceClasses}>
        <Typography
          styleName={priceStyle}
          content={`Sales tax (${stripeData.salesTaxPercentage}%)`}
          color="black60"
        />
        <Typography
          styleName={priceStyle}
          content={`${currency}${stripeData.salesTax}`}
          color="black60"
        />
      </Row>
      <CheckoutStyles.Underline />
      <Row className={priceClasses}>
        <Typography
          styleName={totalPriceStyle}
          content="Total due today"
          color={priceColor}
        />
        <Typography
          styleName={totalPriceStyle}
          content={`${currency}${stripeData.totalPayablePrice}`}
          color={priceColor}
          className="price"
        />
      </Row>
    </CheckoutStyles.Bill>
  );
};
