import { createContext, useContext, useState } from "react";
import styled from "styled-components";

import { Backbutton } from "./BackButton";
import { HeadingToggleButtons } from "./HeadingToggleButtons";
import { ContentProps } from "./Contents";
import { ContentContainer } from "./ContentContainer";
import { AppContext } from "src/App";

export const TermsAndConditionsContext = createContext(
  {} as ContentProps.Context
);

export const TermsAndConditionsContent = () => {
  const { isTabletOrBig } = useContext(AppContext);
  const [contentType, setContentType] = useState<ContentProps.contentType>(
    "terms_and_conditions"
  );

  return (
    <TermsAndConditionsContext.Provider value={{ contentType, setContentType }}>
      <Container>
        {isTabletOrBig && <Backbutton />}
        <HeadingToggleButtons />
        <ContentContainer />
        
      </Container>
    </TermsAndConditionsContext.Provider>
  );
};

const Container = styled.div`
  @media (min-width: 576px) {
    padding: 0 20px;
  }
  @media (min-width: 768px) {
    padding-bottom: 32px;
    width: calc(100% - 120px);
    max-width: 877px;
    margin: auto;
    height: 100%;
    padding: 0;
  }
`;
