import * as Yup from "yup";
export const signInValidationSchema = Yup.object({
  username: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string().required("Password is required"),
});
export const resetValidationSchema = Yup.object({
  new_password: Yup.string().required("New Password is required"),
  confirm_password: Yup.string().required("Confirm Password is required"),
});
export const forgotValidationSchema = Yup.object({
  email: Yup.string().email("Invalid email").required("Email is required"),
});
export const registerFirstValidationSchema = Yup.object({
  email: Yup.string().email("Invalid email").required("Email is required"),
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  password: Yup.string().required("Password is required"),
});
export const registerSecondValidationSchema = Yup.object({
  company_name: Yup.string().required("Company name is required"),
  company_website: Yup.string().required("Website is required"),
});
export const onboardValidationSchema = Yup.object({
  password: Yup.string().required("Password is required"),
  confirm_password: Yup.string().required("Confirm Password is required"),
});

export const authUtil = {
  signInValidationSchema,
  resetValidationSchema,
  forgotValidationSchema,
  registerFirstValidationSchema,
  registerSecondValidationSchema,
};
