import styled from "styled-components";
import { Col, Row } from "antd";

import { Text } from "./Text";
import { TermsProps } from "./Terms";
import { useContext } from "react";
import { AppContext } from "src/App";

const Indent = ({ children }: TermsProps.Indent) => (
  <Row className="row-indent">
    <Col span={1} />
    <Col span={23}>{children}</Col>
  </Row>
);

//#region HOC
const WITH_0COL_GAP = ({ element1, element2 }: TermsProps.Wrap) => {
  const { isMobile } = useContext(AppContext);
  const additionalRemoval = isMobile ? 0 : 1;
  const span = isMobile ? 2 : 1;

  return (
    <Row gutter={additionalRemoval > 0 ? 32 : 16}>
      <Col span={span}>{element1}</Col>
      <Col span={24 - span - additionalRemoval}>{element2}</Col>
    </Row>
  );
};
const WITH_1COL_GAP = ({ element1, element2 }: TermsProps.Wrap) => {
  const { isMobile } = useContext(AppContext);
  const additionalRemoval = isMobile ? 0 : 1;
  const span = isMobile ? 3 : 2;

  return (
    <Row gutter={additionalRemoval > 0 ? 32 : 16}>
      <Col span={span}>{element1}</Col>
      <Col span={24 - span - additionalRemoval}>{element2}</Col>
    </Row>
  );
};
const WITH_2COL_GAP = ({ element1, element2 }: TermsProps.Wrap) => {
  const { isMobile, isDesktopOrBig } = useContext(AppContext);
  const additionalRemoval = isMobile ? 0 : 1;
  const span = isMobile ? 4 : isDesktopOrBig ? 2 : 3;

  return (
    <Row gutter={additionalRemoval > 0 ? 32 : 16}>
      <Col span={span}>{element1}</Col>
      <Col span={24 - span - additionalRemoval}>{element2}</Col>
    </Row>
  );
};
//#endregion

const PointHeading = ({ heading, pointNumber }: TermsProps.PointHeading) => {
  const element1 = <Text.Heading content={pointNumber} />;
  const element2 = <Text.Heading content={heading} />;
  return <WITH_0COL_GAP {...{ element1, element2 }} />;
};

const PointSubHeading = ({
  heading,
  pointNumber,
  needUnderline = true,
}: TermsProps.PointHeading) => {
  const element1 = <Text.Span content={pointNumber} />;
  const element2 = <Text.SubHeading {...{ content: heading, needUnderline }} />;

  return <WITH_0COL_GAP {...{ element1, element2 }} />;
};
const PointSubHeading2 = ({
  heading,
  pointNumber,
  needUnderline = true,
}: TermsProps.PointHeading) => {
  const element1 = <Text.Span content={pointNumber} />;
  const element2 = <Text.SubHeading {...{ content: heading, needUnderline }} />;
  return <WITH_1COL_GAP {...{ element1, element2 }} />;
};
const PointSubHeadingElement = ({
  pointNumber,
  Content,
}: TermsProps.PointHeadingElement) => {
  const element1 = <Text.Span content={pointNumber} />;
  return <WITH_0COL_GAP {...{ element1, element2: Content }} />;
};
const PointSubHeadingElement2 = ({
  pointNumber,
  Content,
}: TermsProps.PointHeadingElement) => {
  const element1 = <Text.Span content={pointNumber} />;
  return <WITH_1COL_GAP {...{ element1, element2: Content }} />;
};
const PointSpan = ({ pointNumber, Content }: TermsProps.Point) => {
  const element1 = <>{pointNumber && <Text.Span content={pointNumber} />}</>;
  return <WITH_0COL_GAP {...{ element1, element2: Content }} />;
};
const PointSpan2 = ({ pointNumber, Content }: TermsProps.Point) => {
  const element1 = <>{pointNumber && <Text.Span content={pointNumber} />}</>;
  return <WITH_1COL_GAP {...{ element1, element2: Content }} />;
};
const PointSpan3 = ({ pointNumber, Content }: TermsProps.Point) => {
  const element1 = <>{pointNumber && <Text.Span content={pointNumber} />}</>;
  return <WITH_2COL_GAP {...{ element1, element2: Content }} />;
};
const PointBold = ({ pointNumber, Content }: TermsProps.Point) => {
  const element1 = <>{pointNumber && <Text.Bold content={pointNumber} />}</>;
  return <WITH_0COL_GAP {...{ element1, element2: Content }} />;
};

type PointTableProps = { pointNumber: string; content: string };
const PointTable = ({ pointNumber, content }: PointTableProps) => (
  <PointTableContainer>
    {pointNumber && <Text.Span content={`(${pointNumber})`} />}
    <Text.Span content={content} />
  </PointTableContainer>
);
const PointTableContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const PointContent = styled.div``;
export const Points = {
  Indent,
  Heading: PointHeading,
  SubHeading: PointSubHeading,
  SubHeading2: PointSubHeading2,
  SubHeadingElement: PointSubHeadingElement,
  SubHeadingElement2: PointSubHeadingElement2,
  Span: PointSpan,
  Span2: PointSpan2,
  Span3: PointSpan3,
  Bold: PointBold,
  Table: PointTable,
  Content: PointContent,
};
