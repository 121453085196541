import { useLocation } from "react-router-dom";
import { headerNavLinksData } from "../../../utils/headerNavLinksData";
import { CustomNavLink } from "../../Basic";
import MobileLogo from "./MobileLogo";
import { CreateJobButton } from "../CreateJobButton";

const LeftHeaderSection = () => {
  const router = useLocation();

  return (
    <div className="main-header-left">
      <MobileLogo />
      <div className="main-header-center ms-4 d-sm-none d-md-none d-lg-block form-group">
        <div className="d-flex gap-5">
          {headerNavLinksData.map((menuItem) => {
            return (
              <CustomNavLink
                key={menuItem.title}
                {...menuItem}
                currentPathName={router?.pathname}
              />
            );
          })}
          <CreateJobButton />
        </div>
      </div>
    </div>
  );
};
export default LeftHeaderSection;
