import instance from "src/axios/axios-user";
import { API_PATHS } from "src/utils/API_PATHS";
import { OnboardingProps } from "src/Redux/slices";

const verify = (values: OnboardingProps.verifyRequest) =>
  instance.post(API_PATHS.onboardingVerify, JSON.stringify(values));
const register = (values: OnboardingProps.registerRequest) =>
  instance.post(API_PATHS.onboardingRegister, JSON.stringify(values));

export const ONBOARDING = { verify, register };
