import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import { ICONS } from "src/assets/custom_Icons";
import { TypographyLink } from "src/components/Basic";
import { theme } from "src/components/Theme";
import { ROUTES } from "src/utils/ROUTES";

export const Backbutton = () => {
  const navigate = useNavigate();
  return (
    <Container onClick={() => navigate(ROUTES.REGISTER)}>
      <ICONS.BackArrowIcon fill={theme.colors.black80} />
      <TypographyLink
        styleName="backButton"
        color="black80"
        content="back"
        underLineNeeded={false}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
`;
