import styled from "styled-components";

import { BoostedCampaignUtils } from "./Utils";
import { theme } from "src/components/Theme";
import { CampaignProps } from "src/Redux";

const Container = styled.div<{ bidType: CampaignProps.BidType }>`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  .MuiSlider-track {
    color: ${({ bidType }) => BoostedCampaignUtils.bidColors[bidType]};
  }
  @media (min-width: 768px) {
    width: 100%;
    gap: 32px;
  }
  @media (min-width: 992px) {
    max-width: 982px;
    background: red;
    padding: 20px 18px;
    border-radius: 5px;
    border: 1px solid ${theme.colors.black10};
    background: ${theme.colors.light_default};
    .dark-theme & {
      border: 1px solid ${theme.colors.black80};
      background: ${theme.colors.black120};
    }
  }
`;
const LabelWithInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
const BidPerView = styled(LabelWithInfo)`
  padding-top: 39px;
  @media (min-width: 768px) {
    padding-top: 51px;
  }
`;
const MainContent = styled.div`
  @media (min-width: 768px) {
    width: 682px;
    margin: auto;
  }
`;
const BidData = styled.div`
  padding-top: 26px;
  @media (min-width: 768px) {
    padding-top: 14px;
  }
`;
const BidPriceContainer = styled.div`
  display: flex;
  gap: 24px;
  align-items: baseline;
  width: fit-content;
  margin: auto;
`;
const BidMinMaxContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  top: 64px;
  width: 100%;
`;
const SliderWrapper = styled.div`
  padding: 32px 16px;
  @media (min-width: 768px) {
    padding: 20px 16px;
  }
`;
export const BoostedCampaignStyles = {
  Container,
  LabelWithInfo,
  BidPerView,
  MainContent,
  BidData,
  BidPriceContainer,
  BidMinMaxContainer,
  SliderWrapper,
};
