import { createContext, useContext, useMemo, useState } from "react";

import "./Advertise.css";
import { AdvertiseStyles } from "./Styles";
import { theme } from "src/components/Theme";
import { actions, useAppDispatch, useAppSelector } from "src/Redux";
import { AdvertiseProps } from "./Advertise";
import { AppContext } from "src/App";
import { BoostedCampaign } from "./BoostedCampaign";
import { AdvertCards } from "./AdvertCards";
import { FormFieldsProps } from "src/components/Basic";

export const AdvertiseContext = createContext({} as AdvertiseProps.Context);

export const Advertise = () => {
  const dispatch = useAppDispatch();
  const { common, createJob, campaign } = useAppSelector((store) => store);
  const { isDarkTheme } = common;
  const { createNovaJobForm } = createJob;
  const { isBoostedSelected, advertiseDetails } = campaign;

  const { isMobile, isSmallTablet, isTablet } = useContext(AppContext);
  const isAnyTablet = isSmallTablet || isTablet;

  //#region Advertise price
  useMemo(() => {
    const hasAdvertiseDetails = advertiseDetails?.length > 0;
    const baseCurrencyLabel =
      createNovaJobForm.base_currency?.label.toLowerCase() as FormFieldsProps.CurrencyLabelsLowerCase;
    const currencyLabel = advertiseDetails[0]?.prices[baseCurrencyLabel]
      ? (baseCurrencyLabel.toLowerCase() as FormFieldsProps.CurrencyLabelsLowerCase)
      : "usd";
    const standard = hasAdvertiseDetails
      ? advertiseDetails[0]?.prices[currencyLabel]?.unit_amount
      : 0;
    const premium = hasAdvertiseDetails
      ? advertiseDetails[1]?.prices[currencyLabel]?.unit_amount
      : 0;
    dispatch(actions.campaign.setCampaignPrices({ standard, premium }));
  }, [advertiseDetails, createNovaJobForm]);
  //#endregion

  //#region useStates
  const [isStandardOpen, setStandardOpen] = useState(false);
  const [isPremiumOpen, setPremiumOpen] = useState(false);
  const [isBoostedOpen, setBoostedOpen] = useState(false);
  //#endregion

  const { black10, black100 } = theme.colors;
  const fillColor = isDarkTheme ? black10 : black100;
  const currency = createNovaJobForm.base_currency?.value;

  //#region Cards
  const isAllCardsClosed = !isStandardOpen && !isPremiumOpen && !isBoostedOpen;
  if (isAnyTablet && isAllCardsClosed) setStandardOpen(true);

  const handleCardClick = (advertType: AdvertiseProps.AdvertType) => {
    if (isMobile) {
      setStandardOpen(advertType === "standard" ? (prev) => !prev : false);
      setPremiumOpen(advertType === "premium" ? (prev) => !prev : false);
      setBoostedOpen(advertType === "boosted" ? (prev) => !prev : false);
    }
    if (isAnyTablet) {
      setStandardOpen(advertType === "standard" ? true : false);
      setPremiumOpen(advertType === "premium" ? true : false);
      setBoostedOpen(advertType === "boosted" ? true : false);
    }
  };
  //#endregion

  return (
    <AdvertiseContext.Provider
      value={{
        handleCardClick,
        isBoostedOpen,
        setBoostedOpen,
        isPremiumOpen,
        setPremiumOpen,
        isStandardOpen,
        setStandardOpen,
        fillColor,
        currency,
      }}
    >
      <AdvertiseStyles.Container>
        {isBoostedSelected ? <BoostedCampaign /> : <AdvertCards />}
      </AdvertiseStyles.Container>
    </AdvertiseContext.Provider>
  );
};
