import styled from "styled-components";
import { theme } from "src/components/Theme";

import { Grid } from "antd";
import {
  NovaBlack,
  NovaBlackMobile,
} from "src/components/Auth/Components/Icon";

export const Header = () => {
  const screen = Grid.useBreakpoint();
  return (
    <Container>
      {screen.lg ? <NovaBlack width={120} height={43} /> : <NovaBlackMobile />}
    </Container>
  );
};

const Container = styled.header`
  height: 64px;
  width: 100%;
  background-color: ${theme.colors.white_pure};
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
