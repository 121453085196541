import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";

import { Typography } from "src/components/Basic";
import { theme } from "src/components/Theme";

const Footer = () => {
  return (
    <FooterContainer>
      <span>
        Copyright © 2023. Designed with{" "}
        <FontAwesomeIcon
          icon={faHeart}
          style={{ color: theme.colors.secondary_hover }}
        />{" "}
        by{" "}
        <Typography
          content="Ditto Jobs "
          styleName="tableRowDefault"
          color="primary_default"
        />
        .
      </span>
      <span className="d-flex justify-content-center">All rights reserved</span>
    </FooterContainer>
  );
};

export default Footer;

const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 51px;
  width: 100%;
  gap: 2px;
  background: ${theme.colors.white_pure};
  ${theme.text.tableRowDefault};
  color: ${theme.colors.black80};
  label {
    white-space: nowrap;
  }
  @media (max-width: 991px) {
    flex-direction: column;
  }
  .dark-theme & {
    background: ${theme.colors.black110};
    color: ${theme.colors.grey100};
  }
`;
