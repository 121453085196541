import { Dispatch, SetStateAction, useState } from "react";
import { Field, FieldProps, FormikValues } from "formik";
import { ContentBlock, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import {
  getHTMLContentFromEditorState,
  getInitialContentState_WithHTML,
  getPlainTextFromEditorState,
  getRichTextAreaHasError,
} from "src/components/Basic/FormFields/FormikFields/richTextArea/richTextEditor.helpers";
import {
  FormFieldCommon,
  FormFieldsProps,
} from "src/components/Basic/FormFields";
import { RichTextAreaContainer } from "src/components/Basic/FormFields/FormikFields/richTextArea/richTextAreaStyles";
import { TagContainer } from "./style";
import { useAppSelector } from "src/Redux";

type RichTextAreaHasErrorProps = {
  formik: FormikValues;
  editorState: EditorState;
  fieldName: string;
  textCount?: number;
  textLimit?: number;
  description?: string;
};

type MessageEditorProps = FormFieldsProps.RichTextarea & {
  editorState: EditorState;
  setEditorState: Dispatch<SetStateAction<EditorState>>;
  errorMessage?: string;
};

export const MessageEditor = (props: MessageEditorProps) => {
  const {
    fieldName,
    idPrefix,
    isRequired,
    errorMessage,
    label,
    placeholder = label || fieldName,
    needLabelPlaceholder = false,
    minHeight = 300,
    customValidation,
    showLabel = true,
    editorState,
    setEditorState,
    disabled = false,
  } = props;

  const [isFocused, setFocused] = useState(false);
  const [editorStateInitiated, setEditorStateInitiated] = useState(false);
  const { tags } = useAppSelector((state) => state.messageTemplate);
  const HandleSpan = ({ children }: { children: JSX.Element }) => {
    return <TagContainer>{children}</TagContainer>;
  };

  let REGEX = /^(?!.)/;
  if (tags.length > 0) {
    const temp = tags.map((item) => item.title).join("|");
    REGEX = new RegExp(temp, "g");
  }

  function handleStrategy(
    contentBlock: ContentBlock,
    callback: (startIndex: number, endIndex: number) => void
  ) {
    const text: string = contentBlock?.getText() || "";
    let matchArr, start;
    while ((matchArr = REGEX.exec(text)) !== null) {
      start = matchArr.index;
      callback(start, start + matchArr[0].length);
    }
  }

  const compositeDecorator = [
    {
      strategy: handleStrategy,
      component: HandleSpan,
    },
  ];

  return (
    <>
      <Field id={`${idPrefix}_${fieldName}`} name={fieldName} key={fieldName}>
        {({ form, meta }: FieldProps) => {
          const { touched, value } = meta;
          const { setFieldValue, setFieldTouched } = form;
          const plainText = getPlainTextFromEditorState(editorState);
          const textCount = plainText.length;
          if (!editorStateInitiated && value) {
            const newEditorStateContent = getInitialContentState_WithHTML(
              value || ""
            );
            setEditorState(EditorState.moveFocusToEnd(newEditorStateContent));
            setFieldValue(
              fieldName,
              getHTMLContentFromEditorState(newEditorStateContent)
            );

            setEditorStateInitiated(true);
          }

          //Error validation
          const richTextAreaHasErrorProps: RichTextAreaHasErrorProps = {
            formik: form,
            editorState,
            fieldName,
            textCount,
          };
          let hasError =
            !isFocused &&
            isRequired &&
            touched &&
            getRichTextAreaHasError(richTextAreaHasErrorProps);

          let errorLabel = errorMessage || `${label || fieldName} is required`;

          if (customValidation?.validation) {
            hasError =
              !isFocused && touched && customValidation.validation(plainText);
            errorLabel = customValidation.validationMessage;
          }
          const onEditorStateChange = (EditorstateFromProps: EditorState) => {
            if (!disabled) {
              setEditorState(EditorstateFromProps);
              setFieldValue(
                fieldName,
                getHTMLContentFromEditorState(editorState)
              );
            }
          };

          const showErrorLabel = hasError && errorLabel;

          return (
            <>
              <RichTextAreaContainer
                containerWidth="100%"
                value={value}
                focus={isFocused}
                hasError={hasError}
                minHeight={minHeight}
                className="w-100"
                style={{
                  pointerEvents: disabled ? "none" : "auto",
                }}
              >
                {showLabel && label && (
                  <FormFieldCommon.Label
                    content={`${label}${isRequired ? "*" : ""}`}
                  />
                )}
                {!label && needLabelPlaceholder && (
                  <FormFieldCommon.LabelPlaceHolder />
                )}
                <Editor
                  customDecorators={compositeDecorator}
                  onChange={() => {
                    setFieldValue(
                      fieldName,
                      textCount > 0
                        ? getHTMLContentFromEditorState(editorState)
                        : ""
                    );
                  }}
                  placeholder={
                    getHTMLContentFromEditorState(editorState).startsWith("<p")
                      ? placeholder
                      : ""
                  }
                  wrapperClassName={
                    isFocused
                      ? "description-editor description-editor--focus"
                      : "description-editor"
                  }
                  editorState={editorState}
                  onEditorStateChange={onEditorStateChange}
                  onFocus={(event) => {
                    event.preventDefault();
                    setFocused(true);
                  }}
                  stripPastedStyles={true}
                  onBlur={(event) => {
                    event.preventDefault();
                    setFieldTouched(fieldName, true);
                    setFieldValue(
                      fieldName,
                      textCount > 0
                        ? getHTMLContentFromEditorState(editorState)
                        : ""
                    );
                    setFocused(false);
                  }}
                  toolbar={{
                    options: ["inline", "list", "embedded"],
                    inline: {
                      inDropdown: false,
                      options: ["bold", "italic", "underline"],
                      bold: {
                        icon: "/static/images/RichTextAreaToolbarIcons/bold.svg",
                        className: undefined,
                      },
                      italic: {
                        icon: "/static/images/RichTextAreaToolbarIcons/italic.svg",
                        className: undefined,
                      },
                      underline: {
                        icon: "/static/images/RichTextAreaToolbarIcons/underline.svg",
                        className: undefined,
                      },
                    },
                    list: {
                      inDropdown: false,
                      options: ["unordered", "ordered"],
                      unordered: {
                        icon: "/static/images/RichTextAreaToolbarIcons/unordered.svg",
                        className: undefined,
                      },
                      ordered: {
                        icon: "/static/images/RichTextAreaToolbarIcons/ordered.svg",
                        className: undefined,
                      },
                    },
                    textAlign: {
                      inDropdown: false,
                      options: ["left", "center", "right", "justify"],
                      left: {
                        icon: "/static/images/RichTextAreaToolbarIcons/left.svg",
                        className: undefined,
                      },
                      center: {
                        icon: "/static/images/RichTextAreaToolbarIcons/center.svg",
                        className: undefined,
                      },
                      right: {
                        icon: "/static/images/RichTextAreaToolbarIcons/right.svg",
                        className: undefined,
                      },
                      justify: {
                        icon: "/static/images/RichTextAreaToolbarIcons/justify.svg",
                        className: undefined,
                      },
                    },
                    embedded: {
                      icon: "/static/images/RichTextAreaToolbarIcons/embedded.svg",
                    },
                  }}
                />
                {showErrorLabel && (
                  <FormFieldCommon.Error content={errorLabel} />
                )}
              </RichTextAreaContainer>
            </>
          );
        }}
      </Field>
    </>
  );
};
