import { TermsAndPrivacyComponents } from "../Components";

const { Points, Text } = TermsAndPrivacyComponents;

export const Indemnities = () => (
  <>
    <Points.Heading pointNumber="11." heading="Indemnities" />
    <Points.Indent>
      <>
        <Points.Bold pointNumber="11.1." Content={POINT_11_1} />
        <Points.Bold pointNumber="11.2." Content={POINT_11_2} />
        <Points.Bold pointNumber="11.3." Content={POINT_11_3} />
      </>
    </Points.Indent>
  </>
);

const POINT_11_1 = (
  <Points.Content>
    <Text.Bold
      content={`You indemnify and hold harmless Ditto Media, its shareholders, directors, employees, and partners from any demand, action or application or other proceedings, including for attorneys’ fees and related costs such as tracing fees, made by any third party, and arising out of or in connection with your use of Nova and/or the Services offered or concluded through Nova in any way.`}
    />
  </Points.Content>
);
const POINT_11_2 = (
  <Points.Content>
    <Text.Bold
      content={`You agree to indemnify, defend, and hold Ditto Media harmless from any direct or indirect liability, loss, claim and expense (including reasonable legal fees) related to your breach of these Terms.`}
    />
  </Points.Content>
);
const POINT_11_3 = (
  <Points.Content>
    <Text.Bold
      content={`This clause will survive termination of this agreement.`}
    />
  </Points.Content>
);
