import { NavLink } from "react-router-dom";

import { Typography } from "src/components/Basic";
import { ContentWrapper, Wrapper } from "./style";
import { ROUTES } from "src/utils/ROUTES";

export const RegisterFooter = () => {
  const descProps = {
    styleName: "authDescription",
    color: "black60",
  } as const;

  return (
    <Wrapper>
      <ContentWrapper>
        <Typography content="By registering you accept our " {...descProps} />
        <NavLink to={ROUTES.TERMS_AND_CONDITIONS}>
          <Typography content="terms and conditions" {...descProps} />
        </NavLink>
      </ContentWrapper>

      <div className="d-flex gap-2">
        <Typography
          content="Have an acount?"
          styleName="createAccount"
          color="black60"
        />
        <NavLink to={ROUTES.SIGN_IN}>
          <Typography
            content="Sign In"
            styleName="createAccount"
            color="black200"
          />
        </NavLink>
      </div>
    </Wrapper>
  );
};
