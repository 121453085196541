import styled from "styled-components";
import { theme } from "src/components/Theme";
import { Modal } from "react-bootstrap";

export const CandidateProfileContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 24px 32px 20px;
  @media (max-width: 991px) {
    margin: 16px 0px 0px 16px;
    gap: 10px;
    flex-direction: column;
  }
  .candidate-detail {
    display: none;
    @media (max-width: 991px) {
      display: flex;
      flex-direction: column;
      margin-top: 7px;
      gap: 8px;
    }
  }
`;
export const RatingContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
  svg {
    cursor: pointer;
    margin-right: 10px;
  }
  @media (max-width: 991px) {
    margin-top: 56px;
  }
`;
export const Arrow = styled.div`
  position: absolute;
  top: 10px;
  left: 0px;
  @media (max-width: 991px) {
    display: none;
  }
`;
export const Detail = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  cursor: pointer;
  svg {
    width: 18px;
  }
  label {
    cursor: pointer;
    word-break: break-all;
  }
  .dark-theme & label {
    color: ${theme.colors.grey30};
  }
`;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 110px;
  label:last-child {
    @media (max-width: 991px) {
      display: none;
    }
  }
  .Onboarding-dropdown {
    font-weight: 600;
  }
  .dropdown-menu.show {
    top: 10px !important;
    left: -17px !important;
  }
  .dark-theme & > label {
    color: ${theme.colors.black20};
  }
`;
export const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin: 12px 0px;
  @media (min-width: 992px) and (max-width: 1439px) {
    max-width: 216px;
  }
  @media (max-width: 991px) {
    display: none;
  }
`;
export const Container = styled.div`
  position: relative;
  display: flex;
  gap: 24px;
`;

export const ImageContainer = styled.div`
  width: 180px;
  height: 180px;
  box-shadow: ${theme.Effects.profileShadow};
  border-radius: 5px;
  img {
    object-fit: fill;
  }
  &::after {
    top: 11px;
    right: -8px;
    width: 18px;
    height: 18px;
    border: 3px solid #ffffff;
    background: #24d5b8;
  }
  @media (max-width: 991px) {
    width: 120px;
    height: 120px;
  }
`;

export const ImgContainer = styled.div`
  width: 25px;
  height: 25px;
  position: absolute;
  top: 10px;
  opacity: 0;
  cursor: move;
`;
export const EmailIconContainer = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: 2px solid ${theme.colors.black10};
  background: ${theme.colors.light_default};
  cursor: pointer;
  @media (max-width: 991px) {
    border: 1px solid rgba(30, 150, 242, 0.5);
    background: rgb(237, 237, 245, 0.5);
  }
  &:hover {
    border-color: rgba(237, 237, 245, 0.5);
    background: rgba(249, 249, 251, 0.5);
    svg path {
      stroke: ${theme.colors.info_default};
    }
  }
  .dark-theme & {
    border: none;
    background: rgba(30, 150, 242, 0.25);
    svg path {
      stroke: ${theme.colors.info_default};
    }
    &:hover {
      svg path {
        stroke: ${theme.colors.success_default};
      }
    }
  }
`;
export const ModalHeader = styled(Modal.Header)`
  button svg path {
    stroke: ${theme.colors.black_pure};
  }
  .dark-theme & button svg path {
    stroke: ${theme.colors.grey30};
  }
  .dark-theme & .modal-title label {
    color: ${theme.colors.black50};
  }
`;
export const ModalBody = styled(Modal.Body)`
  padding: 40px 56px;
  @media (max-width: 991px) {
    padding: 30px 33px 31px 32px;
  }
  .dark-theme & {
    color: ${theme.colors.black10};
  }
`;

export const ButtonContainer = styled.div`
  button {
    font-weight: 600;
  }
  .dark-theme .modal-footer &:first-child button.btn-light {
    background: ${theme.colors.light_default} !important;
    color: ${theme.colors.black90};
  }
`;
export const ModalFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 17px 25px;
  gap: 16px;
  @media (max-width: 991px) {
    padding: 17px 4px 16px;
  }
  .DraftEditor-editorContainer {
    z-index: 0;
  }
`;
export const TemplateSettingContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
  cursor: pointer;
  position: absolute;
  top: 33px;
  right: 43px;
  @media (max-width: 991px) {
    right: 22px;
  }
  label {
    cursor: pointer;
    text-decoration: underline;
  }
`;
