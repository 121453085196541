import { useEffect, useState } from "react";
import { InputGroup } from "react-bootstrap";
import Select from "react-select";
import { Field, FieldMetaProps, FieldProps } from "formik";

import { FormFieldCommon } from "../../FormFieldsCommon";
import { FormFieldsProps } from "../../FormFields";
import { CityContainer } from "./citySearchStyles";
import { API_CALLS } from "src/API_CALLS";
import { useAppSelector } from "src/Redux";

export const CitySearch = (props: FormFieldsProps.CitySearch) => {
  const {
    fieldName,
    idPrefix,
    label,
    isRequired,
    disabled = false,
    countrySelectorName,
    placeholder = label || fieldName,
    needLabelPlaceholder = false,
    showLabel = true,
  } = props;

  const [cityOptions, setCityOptions] = useState(
    [] as FormFieldsProps.option[]
  );
  const [inputValue, setInputValue] = useState("");
  const [timeoutId, setTimeOutId] = useState(null as unknown as NodeJS.Timeout);
  const [fieldPlaceholder, setFieldPlaceholder] = useState(placeholder);
  const [isFocused, setFocused] = useState(false);

  const { cities, getCitiesAPIInProgress } = useAppSelector(
    (store) => store.common
  );
  useEffect(() => {
    const cityOptions: FormFieldsProps.option[] = cities.map(
      ({ id, name }) => ({ label: name, value: id })
    );
    setCityOptions(cityOptions);
  }, [cities]);
  useEffect(() => {}, [inputValue]);

  return (
    <Field name={fieldName} key={fieldName}>
      {({ field, meta, form }: FieldProps<FormFieldsProps.option>) => {
        //#region Values
        const { getFieldMeta, setFieldValue, setFieldTouched } = form;
        const { value } = field;
        const { touched, error } = meta;
        const { value: flagValue }: FieldMetaProps<FormFieldsProps.Country> =
          getFieldMeta(countrySelectorName);
        //#endregion

        //#region Error handling
        const isCountrySelected = flagValue?.id ? true : false;
        const hasError =
          !isFocused && isRequired && touched && (!value || !isCountrySelected);
        let errorLabel = error || `${label || fieldName} is required`;
        if ((touched || value) && !isCountrySelected)
          errorLabel = "Please select country before searching city.";
        const showErrorLabel = hasError && errorLabel;
        //#endregion

        //#region InputChangeHandler
        const inputChangeHandler = (value: string) => {
          setInputValue(value);
          clearTimeout(timeoutId);
          if (!isCountrySelected) {
            setFieldValue(fieldName, null);
            return;
          }
          if (value.length <= 2) return;
          const newTimeoutId = setTimeout(() => {
            API_CALLS.COMMON.getCities({
              cc: flagValue.id.toUpperCase(),
              query: value,
            });
          }, 150);
          setTimeOutId(newTimeoutId);
        };
        //#endregion

        return (
          <CityContainer
            isFieldActive={isFocused}
            disabled={disabled}
            Error={hasError}
          >
            {showLabel && label && (
              <FormFieldCommon.Label
                content={`${label}${isRequired ? "*" : ""}`}
              />
            )}
            {!label && needLabelPlaceholder && (
              <FormFieldCommon.LabelPlaceHolder />
            )}
            <InputGroup className="city-search">
              <Select
                id={`${idPrefix}_${fieldName}`}
                name={fieldName}
                onFocus={() => {
                  setFocused(true);
                  setInputValue(value?.label);
                  setFieldPlaceholder("");
                }}
                onBlur={() => {
                  setFocused(false);
                  setFieldTouched(fieldName, true);
                  setInputValue(value?.label);
                  setFieldPlaceholder(placeholder);
                }}
                onChange={(item) => {
                  setFieldValue(fieldName, item);
                  setInputValue(item?.label || "");
                }}
                onInputChange={inputChangeHandler}
                onKeyDown={(props) => {
                  const isBackspace =
                    props.code === "Backspace" || props.key === "Backspace";
                  if (isBackspace && !inputValue) {
                    setFieldValue(fieldName, null);
                  }
                }}
                options={cityOptions}
                classNamePrefix="cityselect"
                className={`city-search-select ${
                  flagValue?.flag && "has-city-search-flag"
                }`}
                isSearchable={true}
                isMulti={false}
                isDisabled={disabled}
                value={value}
                inputValue={inputValue}
                filterOption={() => true}
                placeholder={
                  <FormFieldCommon.Placeholder content={fieldPlaceholder} />
                }
                defaultMenuIsOpen={isCountrySelected && cityOptions.length > 0}
                onMenuOpen={() => !isCountrySelected && setCityOptions([])}
              />
              {getCitiesAPIInProgress && (
                <div className="city-search-loading is-loading">
                  <div className="spinner-border spinner-border-sm"></div>
                </div>
              )}
              <InputGroup.Text
                className={`flagIcon ${hasError && "input-field-icon-error"}`}
                id="flagIcon"
              >
                {flagValue?.flag}
              </InputGroup.Text>
            </InputGroup>
            {showErrorLabel && <FormFieldCommon.Error content={errorLabel} />}
          </CityContainer>
        );
      }}
    </Field>
  );
};
