import { createSlice } from "@reduxjs/toolkit";

import { StripeProps } from "./Stripe";

const initialState: StripeProps.state = {
  stripeData: {
    currencyType: "USD",
    totalPrice: 0,
    totalPayablePrice: 0,
    salesTax: 3.19,
    salesTaxPercentage: 6.5,
    subTotal: 0,
    discount: 0,
    discountPercentage: 0,
  },
  cardList: [],
};

const stripeSlice = createSlice({
  name: "stripe",
  initialState,
  reducers: {
    setStripeData: (state, action: StripeProps.setStripeData) => {
      const _ = action.payload;
      state.stripeData = {
        ..._,
        totalPayablePrice: _.totalPrice - _.discount,
        subTotal: _.totalPrice - _.salesTax,
      };
    },
    setCardList: (state, action) => {
      state.cardList = action.payload;
    },
  },
});

export const stripeActions = stripeSlice.actions;
export const stripeReducer = stripeSlice.reducer;
