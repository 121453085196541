import { TermsAndPrivacyComponents } from "../Components";

const { Points, Text } = TermsAndPrivacyComponents;

export const DisclosureOfData = () => (
  <>
    <Points.Heading
      pointNumber="6."
      heading="Disclosures of Your Personal Data"
    />
    <Points.Indent>
      <>
        <POINT_6_1 />
        <Points.Span pointNumber="6.2." Content={POINT_6_2} />
      </>
    </Points.Indent>
  </>
);

const POINT_6_1 = () => (
  <>
    <Points.SubHeading
      pointNumber="6.1."
      heading="We may have to share your personal data with the parties set out below for the purposes set out in the table above."
      needUnderline={false}
    />
    <Points.Indent>
      <>
        <Points.Span2 pointNumber="6.1.1." Content={POINT_6_1_1} />
        <Points.Span2 pointNumber="6.1.2." Content={POINT_6_1_2} />
        <Points.Span2 pointNumber="6.1.3." Content={POINT_6_1_3} />
      </>
    </Points.Indent>
  </>
);
const POINT_6_1_1 = (
  <Points.Content>
    <Text.Span content="Internal Third Parties as set out in the " />
    <Text.Bold content="Glossary" />
    <Text.Span content=";" />
  </Points.Content>
);
const POINT_6_1_2 = (
  <Points.Content>
    <Text.Span content="External Third Parties as set out in the " />
    <Text.Bold content="Glossary" />
    <Text.Span content=";" />
  </Points.Content>
);
const POINT_6_1_3 = (
  <Text.Span content="Third parties to whom we may choose to sell, transfer, or merge parts of our company or our assets. Alternatively, we may seek to acquire other organizations or merge with them. If a change happens to our company, we may continue to use your personal data in the same way as set out in this Privacy Policy." />
);

const POINT_6_2 = (
  <Text.Span content="We require all third parties to respect the security of your personal data and to treat it in accordance with the law. We do not allow our third-party service providers to use your personal data for their own purposes and only permit them to process your personal data in accordance with our instructions and standards." />
);
