import { FormFieldsProps } from "../../FormFields";

const DEFAULT_COUNTRY = "us" as const;
//Used to select correct currency according to the country selected
const currencyMap: FormFieldsProps.currencyMap = {
  af: { label: "AFN", value: "؋" },
  ax: { label: "EUR", value: "€" },
  al: { label: "ALL", value: "L" },
  dz: { label: "DZD", value: "د.ج" },
  as: { label: "USD", value: "$" },
  ad: { label: "EUR", value: "€" },
  ao: { label: "AOA", value: "Kz" },
  ai: { label: "XCD", value: "$" },
  aq: { label: "USD", value: "$" },
  ag: { label: "XCD", value: "$" },
  ar: { label: "ARS", value: "$" },
  am: { label: "AMD", value: "֏" },
  aw: { label: "AWG", value: "ƒ" },
  au: { label: "AUD", value: "$" },
  at: { label: "EUR", value: "€" },
  az: { label: "AZN", value: "₼" },
  bs: { label: "BSD", value: "$" },
  bh: { label: "BHD", value: ".د.ب" },
  bd: { label: "BDT", value: "৳" },
  bb: { label: "BBD", value: "$" },
  by: { label: "BYN", value: "Br" },
  be: { label: "EUR", value: "€" },
  bz: { label: "BZD", value: "$" },
  bj: { label: "XOF", value: "CFA" },
  bm: { label: "BMD", value: "$" },
  bt: { label: "BTN", value: "Nu" },
  bo: { label: "BOB", value: "Bs" },
  bq: { label: "USD", value: "$" },
  ba: { label: "BAM", value: "KM" },
  bw: { label: "BWP", value: "P" },
  bv: { label: "NOK", value: "kr" },
  br: { label: "BRL", value: "R$" },
  io: { label: "USD", value: "$" },
  bn: { label: "BND", value: "$" },
  bg: { label: "BGN", value: "лв" },
  bf: { label: "XOF", value: "CFA" },
  bi: { label: "BIF", value: "Fr" },
  cv: { label: "CVE", value: "$" },
  kh: { label: "KHR", value: "៛" },
  cm: { label: "XAF", value: "CFA" },
  ca: { label: "CAD", value: "$" },
  ky: { label: "KYD", value: "$" },
  cf: { label: "XAF", value: "CFA" },
  td: { label: "XAF", value: "CFA" },
  cl: { label: "CLP", value: "$" },
  cn: { label: "CNY", value: "¥" },
  cx: { label: "AUD", value: "$" },
  cc: { label: "AUD", value: "$" },
  co: { label: "COP", value: "$" },
  km: { label: "KMF", value: "Fr" },
  ck: { label: "NZD", value: "$" },
  cr: { label: "CRC", value: "₡" },
  hr: { label: "HRK", value: "kn" },
  cu: { label: "CUP", value: "$" },
  cw: { label: "EUR", value: "€" },
  cy: { label: "EUR", value: "€" },
  cz: { label: "CZK", value: "Kč" },
  ci: { label: "XOF", value: "CFA" },
  cd: { label: "CDF", value: "FC" },
  dk: { label: "DKK", value: "kr" },
  dj: { label: "DJF", value: "Fr" },
  dm: { label: "XCD", value: "$" },
  do: { label: "DOP", value: "RD$" },
  ec: { label: "USD", value: "$" },
  eg: { label: "EGP", value: "ج.م" },
  sv: { label: "USD", value: "$" },
  gq: { label: "XAF", value: "CFA" },
  er: { label: "ERN", value: "Nfk" },
  ee: { label: "EUR", value: "€" },
  et: { label: "ETB", value: "Br" },
  fk: { label: "FKP", value: "£" },
  fo: { label: "DKK", value: "kr" },
  fj: { label: "FJD", value: "$" },
  fi: { label: "EUR", value: "€" },
  fr: { label: "EUR", value: "€" },
  gf: { label: "EUR", value: "€" },
  pf: { label: "XPF", value: "₣" },
  tf: { label: "EUR", value: "€" },
  ga: { label: "XAF", value: "CFA" },
  gm: { label: "GMD", value: "D" },
  ge: { label: "GEL", value: "₾" },
  de: { label: "EUR", value: "€" },
  gh: { label: "GHS", value: "₵" },
  gi: { label: "GIP", value: "£" },
  gr: { label: "EUR", value: "€" },
  gl: { label: "DKK", value: "kr" },
  gd: { label: "XCD", value: "$" },
  gp: { label: "EUR", value: "€" },
  gu: { label: "USD", value: "$" },
  gt: { label: "GTQ", value: "Q" },
  gg: { label: "GBP", value: "£" },
  gn: { label: "GNF", value: "Fr" },
  gw: { label: "XOF", value: "CFA" },
  gy: { label: "GYD", value: "$" },
  ht: { label: "HTG", value: "G" },
  hm: { label: "AUD", value: "$" },
  hn: { label: "HNL", value: "L" },
  hk: { label: "HKD", value: "HK$" },
  hu: { label: "HUF", value: "Ft" },
  is: { label: "ISK", value: "kr" },
  in: { label: "INR", value: "₹" },
  id: { label: "IDR", value: "Rp" },
  ir: { label: "IRR", value: "﷼" },
  iq: { label: "IQD", value: "ع.د" },
  ie: { label: "EUR", value: "€" },
  im: { label: "GBP", value: "£" },
  il: { label: "ILS", value: "₪" },
  it: { label: "EUR", value: "€" },
  jm: { label: "JMD", value: "J$" },
  jp: { label: "JPY", value: "¥" },
  je: { label: "GBP", value: "£" },
  jo: { label: "JOD", value: "JD" },
  kz: { label: "KZT", value: "₸" },
  ke: { label: "KES", value: "KSh" },
  ki: { label: "AUD", value: "$" },
  kp: { label: "KPW", value: "₩" },
  kr: { label: "KRW", value: "₩" },
  kw: { label: "KWD", value: "د.ك" },
  kg: { label: "KGS", value: "с" },
  la: { label: "LAK", value: "₭" },
  lv: { label: "EUR", value: "€" },
  lb: { label: "LBP", value: "ل.ل" },
  ls: { label: "LSL", value: "M" },
  lr: { label: "LRD", value: "$" },
  ly: { label: "LYD", value: "ل.د" },
  li: { label: "CHF", value: "Fr" },
  lt: { label: "EUR", value: "€" },
  lu: { label: "EUR", value: "€" },
  mo: { label: "MOP", value: "MOP$" },
  mk: { label: "MKD", value: "ден" },
  mg: { label: "MGA", value: "Ar" },
  mw: { label: "MWK", value: "MK" },
  my: { label: "MYR", value: "RM" },
  mv: { label: "MVR", value: "ރ" },
  ml: { label: "XOF", value: "CFA" },
  mt: { label: "EUR", value: "€" },
  mh: { label: "USD", value: "$" },
  mq: { label: "EUR", value: "€" },
  mr: { label: "MRO", value: "UM" },
  mu: { label: "MUR", value: "₨" },
  yt: { label: "EUR", value: "€" },
  mx: { label: "MXN", value: "$" },
  fm: { label: "USD", value: "$" },
  md: { label: "MDL", value: "L" },
  mc: { label: "EUR", value: "€" },
  mn: { label: "MNT", value: "₮" },
  me: { label: "EUR", value: "€" },
  ms: { label: "XCD", value: "$" },
  ma: { label: "MAD", value: "د.م." },
  mz: { label: "MZN", value: "MT" },
  mm: { label: "MMK", value: "K" },
  na: { label: "NAD", value: "$" },
  nr: { label: "AUD", value: "$" },
  np: { label: "NPR", value: "₨" },
  nl: { label: "EUR", value: "€" },
  nc: { label: "XPF", value: "₣" },
  nz: { label: "NZD", value: "$" },
  ni: { label: "NIO", value: "C$" },
  ne: { label: "XOF", value: "CFA" },
  ng: { label: "NGN", value: "₦" },
  nu: { label: "NZD", value: "$" },
  nf: { label: "AUD", value: "$" },
  mp: { label: "USD", value: "$" },
  no: { label: "NOK", value: "kr" },
  om: { label: "OMR", value: "ر.ع." },
  pk: { label: "PKR", value: "₨" },
  pw: { label: "USD", value: "$" },
  ps: { label: "ILS", value: "₪" },
  pa: { label: "PAB", value: "B/." },
  pg: { label: "PGK", value: "K" },
  py: { label: "PYG", value: "₲" },
  pe: { label: "PEN", value: "S/" },
  ph: { label: "PHP", value: "₱" },
  pn: { label: "NZD", value: "$" },
  pl: { label: "PLN", value: "zł" },
  pt: { label: "EUR", value: "€" },
  pr: { label: "EUR", value: "€" },
  qa: { label: "QAR", value: "ر.ق" },
  cg: { label: "XAF", value: "CFA" },
  re: { label: "EUR", value: "€" },
  ro: { label: "RON", value: "lei" },
  ru: { label: "RUB", value: "₽" },
  rw: { label: "RWF", value: "Fr" },
  bl: { label: "EUR", value: "€" },
  sh: { label: "SHP", value: "£" },
  kn: { label: "XCD", value: "$" },
  lc: { label: "XCD", value: "$" },
  mf: { label: "EUR", value: "€" },
  pm: { label: "EUR", value: "€" },
  vc: { label: "XCD", value: "$" },
  ws: { label: "WST", value: "T" },
  sm: { label: "EUR", value: "€" },
  st: { label: "STN", value: "Db" },
  sa: { label: "SAR", value: "ر.س" },
  sn: { label: "XOF", value: "CFA" },
  rs: { label: "RSD", value: "дин." },
  sc: { label: "SCR", value: "₨" },
  sl: { label: "SLL", value: "Le" },
  sg: { label: "SGD", value: "$" },
  sx: { label: "ANG", value: "ƒ" },
  sk: { label: "EUR", value: "€" },
  si: { label: "EUR", value: "€" },
  sb: { label: "SBD", value: "Si$" },
  so: { label: "SOS", value: "Sh" },
  za: { label: "ZAR", value: "R" },
  gs: { label: "GBP", value: "£" },
  ss: { label: "SSP", value: "£" },
  es: { label: "EUR", value: "€" },
  lk: { label: "LKR", value: "Rs" },
  sd: { label: "SDG", value: "ج.س." },
  sr: { label: "SRD", value: "$" },
  sj: { label: "NOK", value: "kr" },
  sz: { label: "SZL", value: "E" },
  se: { label: "SEK", value: "kr" },
  ch: { label: "CHF", value: "Fr" },
  sy: { label: "SYP", value: "£" },
  tw: { label: "TWD", value: "NT$" },
  tj: { label: "TJS", value: "ЅМ" },
  tz: { label: "TZS", value: "Sh" },
  th: { label: "THB", value: "฿" },
  tl: { label: "USD", value: "$" },
  tg: { label: "XOF", value: "CFA" },
  tk: { label: "NZD", value: "$" },
  to: { label: "TOP", value: "T$" },
  tt: { label: "TTD", value: "TT$" },
  tn: { label: "TND", value: "د.ت" },
  tr: { label: "TRY", value: "₺" },
  tm: { label: "TMT", value: "T" },
  tc: { label: "USD", value: "$" },
  tv: { label: "AUD", value: "$" },
  ug: { label: "UGX", value: "USh" },
  ua: { label: "UAH", value: "₴" },
  ae: { label: "AED", value: "د.إ" },
  gb: { label: "GBP", value: "£" },
  us: { label: "USD", value: "$" },
  um: { label: "USD", value: "$" },
  uy: { label: "UYU", value: "$" },
  uz: { label: "UZS", value: "лв" },
  vu: { label: "VUV", value: "VT" },
  va: { label: "EUR", value: "€" },
  ve: { label: "VES", value: "Bs." },
  vn: { label: "VND", value: "₫" },
  vg: { label: "USD", value: "$" },
  vi: { label: "USD", value: "$" },
  wf: { label: "XPF", value: "₣" },
  eh: { label: "MAD", value: "MAD" },
  ye: { label: "YER", value: "﷼" },
  zm: { label: "ZMW", value: "ZK" },
  zw: { label: "ZWL", value: "$" },
};
//Used for populating the dropdown data
const currencyOptions: FormFieldsProps.currencyOption[] = [
  { label: "AFN", value: "؋" },
  { label: "EUR", value: "€" },
  { label: "ALL", value: "L" },
  { label: "DZD", value: "د.ج" },
  { label: "USD", value: "$" },
  { label: "AOA", value: "Kz" },
  { label: "XCD", value: "$" },
  { label: "ARS", value: "$" },
  { label: "AMD", value: "֏" },
  { label: "AWG", value: "ƒ" },
  { label: "AUD", value: "$" },
  { label: "AZN", value: "₼" },
  { label: "BSD", value: "$" },
  { label: "BHD", value: ".د.ب" },
  { label: "BDT", value: "৳" },
  { label: "BBD", value: "$" },
  { label: "BYN", value: "Br" },
  { label: "BZD", value: "$" },
  { label: "XOF", value: "CFA" },
  { label: "BMD", value: "$" },
  { label: "BTN", value: "Nu" },
  { label: "BOB", value: "Bs" },
  { label: "BAM", value: "KM" },
  { label: "BWP", value: "P" },
  { label: "NOK", value: "kr" },
  { label: "BRL", value: "R$" },
  { label: "BND", value: "$" },
  { label: "BGN", value: "лв" },
  { label: "BIF", value: "Fr" },
  { label: "CVE", value: "$" },
  { label: "KHR", value: "៛" },
  { label: "XAF", value: "CFA" },
  { label: "CAD", value: "$" },
  { label: "KYD", value: "$" },
  { label: "CLP", value: "$" },
  { label: "CNY", value: "¥" },
  { label: "COP", value: "$" },
  { label: "KMF", value: "Fr" },
  { label: "NZD", value: "$" },
  { label: "CRC", value: "₡" },
  { label: "HRK", value: "kn" },
  { label: "CUP", value: "$" },
  { label: "CZK", value: "Kč" },
  { label: "CDF", value: "FC" },
  { label: "DKK", value: "kr" },
  { label: "DJF", value: "Fr" },
  { label: "EGP", value: "ج.م" },
  { label: "ERN", value: "Nfk" },
  { label: "DOP", value: "RD$" },
  { label: "ETB", value: "Br" },
  { label: "FKP", value: "£" },
  { label: "FJD", value: "$" },
  { label: "XPF", value: "₣" },
  { label: "GMD", value: "D" },
  { label: "GEL", value: "₾" },
  { label: "GHS", value: "₵" },
  { label: "GIP", value: "£" },
  { label: "GTQ", value: "Q" },
  { label: "GBP", value: "£" },
  { label: "GNF", value: "Fr" },
  { label: "GYD", value: "$" },
  { label: "HTG", value: "G" },
  { label: "HNL", value: "L" },
  { label: "HKD", value: "HK$" },
  { label: "HUF", value: "Ft" },
  { label: "ISK", value: "kr" },
  { label: "INR", value: "₹" },
  { label: "IDR", value: "Rp" },
  { label: "IRR", value: "﷼" },
  { label: "IQD", value: "ع.د" },
  { label: "ILS", value: "₪" },
  { label: "JMD", value: "J$" },
  { label: "JPY", value: "¥" },
  { label: "JOD", value: "JD" },
  { label: "KZT", value: "₸" },
  { label: "KES", value: "KSh" },
  { label: "KPW", value: "₩" },
  { label: "KRW", value: "₩" },
  { label: "KWD", value: "د.ك" },
  { label: "KGS", value: "с" },
  { label: "LAK", value: "₭" },
  { label: "LBP", value: "ل.ل" },
  { label: "LSL", value: "M" },
  { label: "LRD", value: "$" },
  { label: "LYD", value: "ل.د" },
  { label: "CHF", value: "Fr" },
  { label: "MOP", value: "MOP$" },
  { label: "MKD", value: "ден" },
  { label: "MGA", value: "Ar" },
  { label: "MWK", value: "MK" },
  { label: "MYR", value: "RM" },
  { label: "MVR", value: "ރ" },
  { label: "MRO", value: "UM" },
  { label: "MUR", value: "₨" },
  { label: "MXN", value: "$" },
  { label: "MDL", value: "L" },
  { label: "MNT", value: "₮" },
  { label: "MAD", value: "د.م." },
  { label: "MZN", value: "MT" },
  { label: "MMK", value: "K" },
  { label: "NAD", value: "$" },
  { label: "NPR", value: "₨" },
  { label: "NIO", value: "C$" },
  { label: "NGN", value: "₦" },
  { label: "OMR", value: "ر.ع." },
  { label: "PKR", value: "₨" },
  { label: "PAB", value: "B/." },
  { label: "PGK", value: "K" },
  { label: "PYG", value: "₲" },
  { label: "PEN", value: "S/" },
  { label: "PHP", value: "₱" },
  { label: "QAR", value: "ر.ق" },
  { label: "PLN", value: "zł" },
  { label: "RON", value: "lei" },
  { label: "RUB", value: "₽" },
  { label: "RWF", value: "Fr" },
  { label: "SHP", value: "£" },
  { label: "WST", value: "T" },
  { label: "STN", value: "Db" },
  { label: "SAR", value: "ر.س" },
  { label: "RSD", value: "дин." },
  { label: "SCR", value: "₨" },
  { label: "SLL", value: "Le" },
  { label: "SGD", value: "$" },
  { label: "ANG", value: "ƒ" },
  { label: "SBD", value: "Si$" },
  { label: "SOS", value: "Sh" },
  { label: "ZAR", value: "R" },
  { label: "SSP", value: "£" },
  { label: "LKR", value: "Rs" },
  { label: "SDG", value: "ج.س." },
  { label: "SRD", value: "$" },
  { label: "SZL", value: "E" },
  { label: "SEK", value: "kr" },
  { label: "SYP", value: "£" },
  { label: "TWD", value: "NT$" },
  { label: "TJS", value: "ЅМ" },
  { label: "TZS", value: "Sh" },
  { label: "THB", value: "฿" },
  { label: "TOP", value: "T$" },
  { label: "TTD", value: "TT$" },
  { label: "TND", value: "د.ت" },
  { label: "TRY", value: "₺" },
  { label: "TMT", value: "T" },
  { label: "UGX", value: "USh" },
  { label: "UAH", value: "₴" },
  { label: "AED", value: "د.إ" },
  { label: "UYU", value: "$" },
  { label: "UZS", value: "лв" },
  { label: "VUV", value: "VT" },
  { label: "VES", value: "Bs." },
  { label: "VND", value: "₫" },
  { label: "YER", value: "﷼" },
  { label: "ZMW", value: "ZK" },
  { label: "ZWL", value: "$" },
];
//Used to update the currency dropdown label
const currency_dict = {
  AED: "د.إ",
  AFN: "؋",
  ALL: "L",
  AMD: "֏",
  ANG: "ƒ",
  AOA: "Kz",
  ARS: "$",
  AUD: "$",
  AWG: "ƒ",
  AZN: "₼",
  BAM: "KM",
  BBD: "$",
  BDT: "৳",
  BGN: "лв",
  BHD: ".د.ب",
  BIF: "Fr",
  BMD: "$",
  BND: "$",
  BOB: "Bs",
  BRL: "R$",
  BSD: "$",
  BTN: "Nu",
  BWP: "P",
  BYN: "Br",
  BZD: "$",
  CAD: "$",
  CDF: "FC",
  CHF: "Fr",
  CLP: "$",
  CNY: "¥",
  COP: "$",
  CRC: "₡",
  CUP: "$",
  CVE: "$",
  CZK: "Kč",
  DJF: "Fr",
  DKK: "kr",
  DOP: "RD$",
  DZD: "د.ج",
  EGP: "ج.م",
  ERN: "Nfk",
  ETB: "Br",
  EUR: "€",
  FJD: "$",
  FKP: "£",
  GBP: "£",
  GEL: "₾",
  GHS: "₵",
  GIP: "£",
  GMD: "D",
  GNF: "Fr",
  GTQ: "Q",
  GYD: "$",
  HKD: "HK$",
  HNL: "L",
  HRK: "kn",
  HTG: "G",
  HUF: "Ft",
  IDR: "Rp",
  ILS: "₪",
  INR: "₹",
  IQD: "ع.د",
  IRR: "﷼",
  ISK: "kr",
  JMD: "J$",
  JOD: "JD",
  JPY: "¥",
  KES: "KSh",
  KGS: "с",
  KHR: "៛",
  KMF: "Fr",
  KPW: "₩",
  KRW: "₩",
  KWD: "د.ك",
  KYD: "$",
  KZT: "₸",
  LAK: "₭",
  LBP: "ل.ل",
  LKR: "Rs",
  LRD: "$",
  LSL: "M",
  LYD: "ل.د",
  MAD: "د.م.",
  MDL: "L",
  MGA: "Ar",
  MKD: "ден",
  MMK: "K",
  MNT: "₮",
  MOP: "MOP$",
  MRO: "UM",
  MUR: "₨",
  MVR: "ރ",
  MWK: "MK",
  MXN: "$",
  MYR: "RM",
  MZN: "MT",
  NAD: "$",
  NGN: "₦",
  NIO: "C$",
  NOK: "kr",
  NPR: "₨",
  NZD: "$",
  OMR: "ر.ع.",
  PAB: "B/.",
  PEN: "S/",
  PGK: "K",
  PHP: "₱",
  PKR: "₨",
  PLN: "zł",
  PYG: "₲",
  QAR: "ر.ق",
  RON: "lei",
  RSD: "дин.",
  RUB: "₽",
  RWF: "Fr",
  SAR: "ر.س",
  SBD: "Si$",
  SCR: "₨",
  SDG: "ج.س.",
  SEK: "kr",
  SGD: "$",
  SHP: "£",
  SLL: "Le",
  SOS: "Sh",
  SRD: "$",
  SSP: "£",
  STN: "Db",
  SYP: "£",
  SZL: "E",
  THB: "฿",
  TJS: "ЅМ",
  TMT: "T",
  TND: "د.ت",
  TOP: "T$",
  TRY: "₺",
  TTD: "TT$",
  TWD: "NT$",
  TZS: "Sh",
  UAH: "₴",
  UGX: "USh",
  USD: "$",
  UYU: "$",
  UZS: "лв",
  VES: "Bs.",
  VND: "₫",
  VUV: "VT",
  WST: "T",
  XAF: "CFA",
  XCD: "$",
  XOF: "CFA",
  XPF: "₣",
  YER: "﷼",
  ZAR: "R",
  ZMW: "ZK",
  ZWL: "$",
};

//For Typescript props implementation
const currency_dict_keys = [
  "AFN",
  "EUR",
  "ALL",
  "DZD",
  "USD",
  "AOA",
  "XCD",
  "ARS",
  "AMD",
  "AWG",
  "AUD",
  "AZN",
  "BSD",
  "BHD",
  "BDT",
  "BBD",
  "BYN",
  "BZD",
  "XOF",
  "BMD",
  "BTN",
  "BOB",
  "BAM",
  "BWP",
  "NOK",
  "BRL",
  "BND",
  "BGN",
  "BIF",
  "CVE",
  "KHR",
  "XAF",
  "CAD",
  "KYD",
  "CLP",
  "CNY",
  "COP",
  "KMF",
  "NZD",
  "CRC",
  "HRK",
  "CUP",
  "CZK",
  "CDF",
  "DKK",
  "DJF",
  "DOP",
  "EGP",
  "ERN",
  "ETB",
  "FKP",
  "FJD",
  "XPF",
  "GMD",
  "GEL",
  "GHS",
  "GIP",
  "GTQ",
  "GBP",
  "GNF",
  "GYD",
  "HTG",
  "HNL",
  "HKD",
  "HUF",
  "ISK",
  "INR",
  "IDR",
  "IRR",
  "IQD",
  "ILS",
  "JMD",
  "JPY",
  "JOD",
  "KZT",
  "KES",
  "KPW",
  "KRW",
  "KWD",
  "KGS",
  "LAK",
  "LBP",
  "LSL",
  "LRD",
  "LYD",
  "CHF",
  "MOP",
  "MKD",
  "MGA",
  "MWK",
  "MYR",
  "MVR",
  "MRO",
  "MUR",
  "MXN",
  "MDL",
  "MNT",
  "MAD",
  "MZN",
  "MMK",
  "NAD",
  "NPR",
  "NIO",
  "NGN",
  "OMR",
  "PKR",
  "PAB",
  "PGK",
  "PYG",
  "PEN",
  "PHP",
  "PLN",
  "QAR",
  "RON",
  "RUB",
  "RWF",
  "SHP",
  "WST",
  "STN",
  "SAR",
  "RSD",
  "SCR",
  "SLL",
  "SGD",
  "ANG",
  "SBD",
  "SOS",
  "ZAR",
  "SSP",
  "LKR",
  "SDG",
  "SRD",
  "SZL",
  "SEK",
  "SYP",
  "TWD",
  "TJS",
  "TZS",
  "THB",
  "TOP",
  "TTD",
  "TND",
  "TRY",
  "TMT",
  "UGX",
  "UAH",
  "AED",
  "UYU",
  "UZS",
  "VUV",
  "VES",
  "VND",
  "YER",
  "ZMW",
  "ZWL",
] as const;
const currency_dict_keys_lower_case = [
  "afn",
  "eur",
  "all",
  "dzd",
  "usd",
  "aoa",
  "xcd",
  "ars",
  "amd",
  "awg",
  "aud",
  "azn",
  "bsd",
  "bhd",
  "bdt",
  "bbd",
  "byn",
  "bzd",
  "xof",
  "bmd",
  "btn",
  "bob",
  "bam",
  "bwp",
  "nok",
  "brl",
  "bnd",
  "bgn",
  "bif",
  "cve",
  "khr",
  "xaf",
  "cad",
  "kyd",
  "clp",
  "cny",
  "cop",
  "kmf",
  "nzd",
  "crc",
  "hrk",
  "cup",
  "czk",
  "cdf",
  "dkk",
  "djf",
  "dop",
  "egp",
  "ern",
  "etb",
  "fkp",
  "fjd",
  "xpf",
  "gmd",
  "gel",
  "ghs",
  "gip",
  "gtq",
  "gbp",
  "gnf",
  "gyd",
  "htg",
  "hnl",
  "hkd",
  "huf",
  "isk",
  "inr",
  "idr",
  "irr",
  "iqd",
  "ils",
  "jmd",
  "jpy",
  "jod",
  "kzt",
  "kes",
  "kpw",
  "krw",
  "kwd",
  "kgs",
  "lak",
  "lbp",
  "lsl",
  "lrd",
  "lyd",
  "chf",
  "mop",
  "mkd",
  "mga",
  "mwk",
  "myr",
  "mvr",
  "mro",
  "mur",
  "mxn",
  "mdl",
  "mnt",
  "mad",
  "mzn",
  "mmk",
  "nad",
  "npr",
  "nio",
  "ngn",
  "omr",
  "pkr",
  "pab",
  "pgk",
  "pyg",
  "pen",
  "php",
  "pln",
  "qar",
  "ron",
  "rub",
  "rwf",
  "shp",
  "wst",
  "stn",
  "sar",
  "rsd",
  "scr",
  "sll",
  "sgd",
  "ang",
  "sbd",
  "sos",
  "zar",
  "ssp",
  "lkr",
  "sdg",
  "srd",
  "szl",
  "sek",
  "syp",
  "twd",
  "tjs",
  "tzs",
  "thb",
  "top",
  "ttd",
  "tnd",
  "try",
  "tmt",
  "ugx",
  "uah",
  "aed",
  "uyu",
  "uzs",
  "vuv",
  "ves",
  "vnd",
  "yer",
  "zmw",
  "zwl",
] as const;
const currency_dict_values = [
  "د.إ",
  "؋",
  "L",
  "֏",
  "ƒ",
  "Kz",
  "$",
  "$",
  "ƒ",
  "₼",
  "KM",
  "$",
  "৳",
  "лв",
  ".د.ب",
  "Fr",
  "$",
  "$",
  "Bs",
  "R$",
  "$",
  "Nu",
  "P",
  "Br",
  "$",
  "$",
  "FC",
  "Fr",
  "$",
  "¥",
  "$",
  "₡",
  "$",
  "$",
  "Kč",
  "Fr",
  "kr",
  "RD$",
  "د.ج",
  "ج.م",
  "Nfk",
  "Br",
  "€",
  "$",
  "£",
  "£",
  "₾",
  "₵",
  "£",
  "D",
  "Fr",
  "Q",
  "$",
  "HK$",
  "L",
  "kn",
  "G",
  "Ft",
  "Rp",
  "₪",
  "₹",
  "ع.د",
  "﷼",
  "kr",
  "J$",
  "JD",
  "¥",
  "KSh",
  "с",
  "៛",
  "Fr",
  "₩",
  "₩",
  "د.ك",
  "$",
  "₸",
  "₭",
  "ل.ل",
  "Rs",
  "$",
  "M",
  "ل.د",
  "د.م.",
  "L",
  "Ar",
  "ден",
  "K",
  "₮",
  "MOP$",
  "UM",
  "₨",
  "ރ",
  "MK",
  "$",
  "RM",
  "MT",
  "$",
  "₦",
  "C$",
  "kr",
  "₨",
  "$",
  "ر.ع.",
  "B/.",
  "S/",
  "K",
  "₱",
  "₨",
  "zł",
  "₲",
  "ر.ق",
  "lei",
  "дин.",
  "₽",
  "Fr",
  "ر.س",
  "Si$",
  "₨",
  "ج.س.",
  "kr",
  "$",
  "£",
  "Le",
  "Sh",
  "$",
  "£",
  "Db",
  "£",
  "E",
  "฿",
  "ЅМ",
  "T",
  "د.ت",
  "T$",
  "₺",
  "TT$",
  "NT$",
  "Sh",
  "₴",
  "USh",
  "$",
  "$",
  "лв",
  "Bs.",
  "₫",
  "VT",
  "T",
  "CFA",
  "$",
  "CFA",
  "₣",
  "﷼",
  "R",
  "ZK",
  "$",
] as const;

export const CurrencyUtils = {
  currencyMap,
  currencyOptions,
  currency_dict,
  currency_dict_keys,
  currency_dict_keys_lower_case,
  currency_dict_values,
  DEFAULT_COUNTRY,
};
