import clsx from "clsx";
import styled from "styled-components";

import { CandidateTabData } from "../../Utils";
import { ICandidateTabHeader } from "../CandidateTabHeader/CandidateTabHeader";
import { theme } from "src/components/Theme";
import { useAppSelector } from "src/Redux";
import { ICONS } from "src/assets/custom_Icons";

const CandidateMobileTabHeader: React.FC<ICandidateTabHeader.IProps> = ({
  activeTab,
  setActiveTab,
}) => {
  const { selectedCandidate } = useAppSelector((state) => state.candidate);
  
  return (
    <CandidateMobileTabHeaderContainer>
      <Tab
        className={clsx("Summary" === activeTab && "active-tab")}
        isactive={"Summary" === activeTab}
        onClick={() => setActiveTab("Summary")}
      >
        <ICONS.SummaryIcon width={2} />
      </Tab>
      {CandidateTabData.map((tab, index) => (
        <Tab
          key={index}
          className={clsx(
            tab.title === activeTab && "active-tab",
            selectedCandidate.section_qualifiers === 0 && index === 4
              ? "not-show"
              : ""
          )}
          isactive={tab.title === activeTab}
          onClick={() => setActiveTab(tab.title)}
        >
          {tab.icon}
        </Tab>
      ))}
    </CandidateMobileTabHeaderContainer>
  );
};

export default CandidateMobileTabHeader;

const CandidateMobileTabHeaderContainer = styled.div`
  display: flex;
  background: ${theme.colors.light_default};
  border: 1px solid ${theme.colors.light_outline};
  border-radius: 5px;
  padding: 8px 12px;
  margin: 12px;
  justify-content: space-around;
  svg {
    height: 24px;
    width: 24px;
  }
  @media (min-width: 425px) {
    gap: 19px;
    justify-content: flex-start;
  }
  @media (min-width: 992px) {
    display: none;
  }
  .dark-theme & {
    background: ${theme.colors.black130};
    border: 1px solid ${theme.colors.black130};
  }
`;
const Tab = styled.div<{ isactive: boolean }>`
  padding: 8px 10px;
  ${({ isactive }) =>
    isactive &&
    `background: rgba(30, 150, 242, 0.2);
     border-radius: 5px;
     svg path {
      stroke: ${theme.colors.primary_default};
    };
    svg circle {
     stroke: ${theme.colors.primary_default};
     fill: ${theme.colors.primary10};
    }
  `}
  .dark-theme & {
    svg path {
      stroke: ${theme.colors.light_default};
    }
    svg circle {
      stroke: ${theme.colors.light_default};
      fill: ${theme.colors.black130};
    }
  }
  &.not-show {
    display: none;
  }
`;
