import React, { DragEvent } from "react";
import clsx from "clsx";
import { Dropdown } from "react-bootstrap";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router-dom";

import { Typography } from "../../Basic/Typography";
import {
  Container,
  CountContainer,
  IconContainer,
  InBoxContainer,
  PipelinePhaseContainer,
  PhaseContainerList,
  Wrapper,
} from "./PipeLinePhaseStyle";
import { useAppDispatch, useAppSelector } from "src/Redux";
import { pipelinePhaseData } from "../Utils";
import { IPipeLine, PhaseKey } from "../PipeLine";
import { candidateActions } from "src/Redux/slices/Candidate";
import { LOCAL_STORAGE_KEY } from "src/utils/LOCAL_STORAGE_KEYS";
import { API_CALLS } from "src/API_CALLS";

const PipeLinePhase = ({
  setPhase,
  selectedPhase,
  setIsRegretModalOpen,
  setSelected,
  setLatest,
  setUnread,
}: IPipeLine.IPhase) => {
  const { pipelinePhase, candidate } = useAppSelector((state) => state);
  const { date_created, job_title, pipeline_overview_count } = pipelinePhase;
  const { job_id } = useParams();
  const dispatch = useAppDispatch();
  const dragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const dragEnter = (e: DragEvent<HTMLDivElement>, value: number) => {
    e.preventDefault();
    const target = document.getElementsByClassName(
      `candidate-drag_${value}`
    )[0] as HTMLElement;
    target.style.transform = "scale(1.08)";
  };

  const dragLeave = (e: DragEvent<HTMLDivElement>, value: number) => {
    e.preventDefault();
    const target = document.getElementsByClassName(
      `candidate-drag_${value}`
    )[0] as HTMLElement;
    const relatedTarget = e.relatedTarget as HTMLElement;
    if (!target.contains(relatedTarget)) {
      target.style.transform = "scale(1)";
    }
  };

  const drop = (e: DragEvent<HTMLDivElement>, value: number, key: PhaseKey) => {
    e.preventDefault();
    const target = document.getElementsByClassName(
      `candidate-drag_${value}`
    )[0] as HTMLElement;
    target.style.transform = "scale(1)";
    var data = e.dataTransfer.getData("text");
    if (data.includes("_true")) {
      if (key === "-1") {
        setIsRegretModalOpen(true);
        return;
      }
      !(String(key) === String(candidate.selectedCandidate.pipeline_status)) &&
        API_CALLS.PIPELINE.updatePipelineOverview({
          candidate_id: candidate.selectedCandidate.candidate_id,
          status_id: String(key),
          job_id: job_id as string,
        });
    }
  };
  React.useEffect(() => {
    API_CALLS.PIPELINE.getPipelineOverview(job_id as string);
  }, []);

  return (
    <PipelinePhaseContainer>
      <Container>
        <Wrapper>
          <Typography
            content={job_title}
            styleName="subtitle1"
            color="black90"
          />
          <Typography
            content={`Posted:${date_created}`}
            styleName="text_10pt"
            color="black50"
          />
        </Wrapper>
        <Dropdown>
          <Dropdown.Toggle
            variant=""
            aria-expanded="false"
            aria-haspopup="true"
            className="btn phase-dropdown "
            data-bs-toggle="dropdown"
            type="button"
          >
            <FontAwesomeIcon
              icon={faEllipsisH}
              size="xl"
              className="user-icon"
            />
          </Dropdown.Toggle>
          <Dropdown.Menu style={{ margin: "0px" }}>
            <Dropdown.Item>Edit</Dropdown.Item>
            <Dropdown.Item>Advertising</Dropdown.Item>
            <Dropdown.Item>Activity Log</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Container>
      <PhaseContainerList>
        {pipelinePhaseData.map((phase, index) => (
          <div className="d-flex align-items-center " key={index}>
            <InBoxContainer
              isOnBoarding={selectedPhase === phase.key}
              className={clsx(
                selectedPhase === phase.key
                  ? "inbox-onboarding"
                  : "inbox-regular",
                `candidate-drag_${index}`
              )}
              onDragOver={dragOver}
              onDragEnter={(e) => dragEnter(e, index)}
              onDragLeave={(e) => dragLeave(e, index)}
              onDrop={(e) => drop(e, index, phase.key)}
              onClick={() => {
                dispatch(candidateActions.setActivePage(1));
                setPhase(phase.key);
                setSelected([]);
                setLatest("latest");
                setUnread("unread");
                dispatch(candidateActions.setPageSize(10));
                localStorage.setItem(
                  LOCAL_STORAGE_KEY.SELECTED_PHASE,
                  JSON.stringify(phase.key)
                );
              }}
            >
              <Typography
                content={phase.label}
                styleName="inboxText"
                color="primary_default"
              />
              <CountContainer
                id={`candidate-drag_${index}`}
                isOnBoarding={selectedPhase === phase.key}
              >
                {pipeline_overview_count[phase.key]}
              </CountContainer>
            </InBoxContainer>
            {phase.icon && <IconContainer>{phase.icon}</IconContainer>}
          </div>
        ))}
      </PhaseContainerList>
    </PipelinePhaseContainer>
  );
};

export default PipeLinePhase;
