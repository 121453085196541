import { Col } from "react-bootstrap";
import { createContext, useContext, useState } from "react";

import { Typography } from "src/components/Basic";
import { CheckoutStyles } from "../Styles";
import { useAppSelector } from "src/Redux";
import { AppContext } from "src/App";
import { CreateJobComponentProps } from "../../CreateJob";
import { Bill } from "./Bill";
import { Heading } from "./Heading";
import { Modal } from "./Modal";

export const CheckoutSummaryContext = createContext(
  {} as CreateJobComponentProps.CheckoutSummaryContext
);

export const CheckoutSummary = () => {
  const { isTabletOrBig } = useContext(AppContext);
  const { common, createJob, stripe, campaign } = useAppSelector(
    (store) => store
  );
  const { isDarkTheme } = common;
  const { createNovaJobForm } = createJob;
  const { advertType } = campaign;
  const { stripeData } = stripe;

  const [showModal, setShowModal] = useState(false);

  const priceColor = isDarkTheme ? "black30" : "black90";
  const priceClasses = `d-flex flex-row justify-content-between`;
  const currency = createNovaJobForm.base_currency?.value;

  return (
    <CheckoutSummaryContext.Provider
      value={{
        showModal,
        setShowModal,
        priceClasses,
        currency,
      }}
    >
      {!isTabletOrBig && showModal && <Modal />}
      <Col sm={12} md={6} className="checkout checkout-left gap-0">
        <div>
          <Heading />
          <CheckoutStyles.BillSummary>
            <Typography
              styleName="jobCheckoutTitle"
              content={`${advertType} package`}
              color="primary_default"
            />
            <Typography
              styleName="jobCheckoutPrice"
              content={`${currency}${stripeData.totalPrice}`}
              color={priceColor}
            />
          </CheckoutStyles.BillSummary>

          {isTabletOrBig && <Bill />}
        </div>
      </Col>
    </CheckoutSummaryContext.Provider>
  );
};
