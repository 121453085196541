import { default as Button } from "./Button";
import { CreateJob } from "./CreateJob";
import { FormExample } from "./FormExample";
import { FormInputs } from "./FormInputs";
import { Programatic } from "./Programatic";
import { default as Tables } from "./Tables";
import { default as Settings } from "./Settings";
import { default as Pipeline } from "./Pipeline";
import { default as Dashboard } from "./Dashboard";
import { default as TeamManagement } from "./TeamManagement";
import { default as Job } from "./Job";
import { TermsAndConditions } from "./TermsAndConditions";
import Notification from "./Notification";

export const Pages = {
  Button,
  CreateJob,
  FormExample,
  FormInputs,
  Tables,
  Settings,
  Pipeline,
  Dashboard,
  TeamManagement,
  Programatic,
  Job,
  TermsAndConditions,
  Notification,
};
