import styled from "styled-components";

const Loading = ({ position }: { position?: string }) => {
  return (
    <LoadingContainer position={position}>
      <div className="lds-spinner">
        {Array.from({ length: 12 }, (_, index) => (
          <div key={index} />
        ))}
      </div>
    </LoadingContainer>
  );
};

export default Loading;

const LoadingContainer = styled.div<{ position?: string; minheight?: string }>`
  width: 100%;
  background: black !important;
  opacity: 0.3;
  position: ${({ position }) => (position ? position : "fixed")};
  top: 0;
  min-height: ${({ position }) => (position ? "100%" : "100vh")};
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 991px) {
    min-height: ${({ position }) =>
      position ? "calc(100vh - 200px)" : "100vh"};
  } ;
`;
