import instance from "src/axios/axios-user";
import { API_PATHS } from "src/utils/API_PATHS";
import { CampaignProps } from "src/Redux/slices";

const createSingleCampaign = (values: CampaignProps.createSingleCampaign) =>
  instance.post(API_PATHS.createSingleCampaign, JSON.stringify(values));
const createBoostedCampaign = (values: CampaignProps.createBoostedCampaign) =>
  instance.post(API_PATHS.createBoostedCampaign, JSON.stringify(values));
const updateBoostedCampaign = (values: CampaignProps.updateBoostedCampaign) =>
  instance.put(API_PATHS.updateBoostedCampaign, JSON.stringify(values));
const getCampaignProducts = () => instance.get(API_PATHS.getCampaignProducts);
const getCampaignProduct = (id: string) =>
  instance.get(`${API_PATHS.getCampaignProduct}/${id}`);
const getChartData = (values: CampaignProps.getChartDataAPI) =>
  instance.post(`${API_PATHS.getChartData}`, JSON.stringify(values));

export const CAMPAIGN = {
  createSingleCampaign,
  createBoostedCampaign,
  updateBoostedCampaign,
  getCampaignProduct,
  getCampaignProducts,
  getChartData,
};
