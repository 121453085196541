import { useEffect } from "react";
import { Grid } from "antd";
import clsx from "clsx";
import { useLocation, useNavigate } from "react-router-dom";

import { Buttons, LinkButton, Typography } from "src/components/Basic";
import { API_CALLS } from "src/API_CALLS";
import { ICONS } from "src/assets/custom_Icons";
import { ROUTES } from "src/utils/ROUTES";
import { EndComma, StartComma } from "src/components/Auth/Components/Icon";
import TeamManagementHeader from "./TeamManagementHeader";
import TeamMemberDetailForm from "./TeamMemberDetailForm";
import { VerifyStyle } from "./TeamManagementStyle";
import { useAppSelector } from "src/Redux";

const TeamManagement = () => {
  const screen = Grid.useBreakpoint();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get("email");
  const token = searchParams.get("token");
  
  const { common, settings } = useAppSelector((state) => state);
  const { isDarkTheme } = common;

  const textMsg = `Start your
  business today`;

  useEffect(() => {
    API_CALLS.TEAM_OVERVIEW.getUnverifiedMemberDetails(
      email as string,
      token as string
    );
  }, []);

  return (
    <>
      <TeamManagementHeader />
      <VerifyStyle.TeamManagementContainer>
        <VerifyStyle.Container>
          <ICONS.MoonRoketIcon />
          <VerifyStyle.TextContainer>
            <div>
              <StartComma />
            </div>
            <VerifyStyle.PreContainer children={textMsg} />
            <div className="d-flex justify-content-end">
              <EndComma />
            </div>
          </VerifyStyle.TextContainer>
        </VerifyStyle.Container>
        {settings.isMemberInvitedValidLink ? (
          !settings.isVerifyMemberDetail ? (
            <TeamMemberDetailForm />
          ) : (
            <VerifyStyle.VerifyContainer>
              <div
                className={clsx(
                  "d-flex flex-column ",
                  screen.lg ? "gap-2" : "gap-3"
                )}
              >
                <div>
                  <Typography
                    content="Welcome to the team!"
                    styleName="subtitle1"
                    color={isDarkTheme ? "grey_subtitle2" : "black100"}
                  />
                </div>
                <Typography
                  content="Please click below to continue to your team’s dashboard."
                  styleName="text_13pt"
                  color={isDarkTheme ? "grey_subtitle2" : "black100"}
                />
                <ICONS.RoketIcon />
              </div>
              <Buttons
                handleClick={() => {
                  navigate(ROUTES.HOME);
                }}
                label="Dashboard"
                variant="primary"
              />
            </VerifyStyle.VerifyContainer>
          )
        ) : (
          <VerifyStyle.VerifyContainer>
            <div
              className={clsx(
                "d-flex flex-column ",
                screen.lg ? "gap-2" : "gap-3"
              )}
            >
              <div>
                <Typography
                  content="Link not valid"
                  styleName="subtitle1"
                  color={isDarkTheme ? "grey_subtitle2" : "black100"}
                />
              </div>
              <Typography
                content="Please ask your admin to invite you again."
                styleName="subtitle3"
                color={isDarkTheme ? "grey_subtitle2" : "black100"}
              />
              <ICONS.RoketIcon />
            </div>
            <div>
              <LinkButton
                isDisable={false}
                handleClick={() => {
                  navigate(ROUTES.HOME);
                }}
                label="Back to homepage"
              />
            </div>
          </VerifyStyle.VerifyContainer>
        )}
      </VerifyStyle.TeamManagementContainer>
    </>
  );
};

export default TeamManagement;
