import styled from "styled-components";
import { useContext } from "react";

import { theme } from "src/components/Theme";
import { TermsAndConditionsContext } from "..";
import { TermsAndConditionsContent } from "../Utils";
import { TermsAndPrivacyComponents } from "./Components";
import { Terms } from "./Terms";
import { PrivacyPolicy } from "./PrivacyPolicy";
import { AppContext } from "src/App";
import { PoweredBy } from "../../PoweredBy";

export const ContentContainer = () => {
  const { isTabletOrBig } = useContext(AppContext);
  const { contentType } = useContext(TermsAndConditionsContext);
  const { Subtitle, Title, LastUpdated } = TermsAndPrivacyComponents.Container;
  return (
    <Container className="terms-and-conditions-content">
      <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
        <Title content={TermsAndConditionsContent.title[contentType]} />
        <LastUpdated content={`Last updated: 21/08/2023`} />
      </div>
      <Subtitle content={TermsAndConditionsContent.subTitle[contentType]} />

      <ContentWrapper>
        {contentType === "terms_and_conditions" ? <Terms /> : <PrivacyPolicy />}
        {!isTabletOrBig && <PoweredBy />}
      </ContentWrapper>
    </Container>
  );
};

const Container = styled.div`
  background: ${theme.colors.white_pure};
  min-height: 300px;
  border-radius: 5px;
  box-shadow: 1px 1px 7px 0px rgba(154, 154, 204, 0.1);
  .with-underline {
    text-decoration: underline;
  }
  padding: 20px;
  @media (min-width: 576px) {
    padding: 20px;
  }
  @media (min-width: 768px) {
    padding: 20px 46px 20px 20px;
  }
  @media (min-width: 992px) {
    padding: 20px 66px 20px 20px;
  }
`;
const ContentWrapper = styled.div`
  @media (min-width: 768px) {
    padding-left: 10px;
    max-width: 773px;
  }
  @media (min-width: 992px) {
    padding-left: 46px;
  }
`;
