import { toast } from "react-toastify";

import { API_INSTANCES } from "src/API_INSTANCES";
import store, { actions } from "src/Redux";
import SettingsProps from "src/Redux/slices/Settings/Settings";

const dispatch = store.dispatch;

const getTag = () => {
  API_INSTANCES.MESSAGE_TEMPLATE.getTag()
    .then((res) => dispatch(actions.messageTemplate.setTags(res.data.tags)))
    .catch((error) => console.log(error));
};

const getMessageTemplate = () => {
  API_INSTANCES.MESSAGE_TEMPLATE.getMessageTemplate()
    .then((res) =>
      dispatch(actions.messageTemplate.setTemplates(res.data.templates))
    )
    .catch((error) => console.log(error))
    .finally(() => dispatch(actions.common.setPageLoading(false)));
};

const createMessageTemplate = (body: SettingsProps.CreateMessageTemplate) => {
  toast.promise(API_INSTANCES.MESSAGE_TEMPLATE.createMessageTemplate(body), {
    pending: "Promise is pending",
    success: {
      render({ data }) {
        dispatch(actions.messageTemplate.setTemplates(data?.data.templates));
        return "Promise resolved 👌";
      },
    },
    error: {
      render({ data }) {
        console.log(data);
        return "Promise rejected 🤯";
      },
    },
  });
};

const updateMessageTemplate = (body: SettingsProps.UpdateMessageTemplate) => {
  toast.promise(API_INSTANCES.MESSAGE_TEMPLATE.updateMessageTemplate(body), {
    pending: "Promise is pending",
    success: {
      render({ data }) {
        dispatch(actions.messageTemplate.setTemplates(data?.data.templates));
        return "Promise resolved 👌";
      },
    },
    error: {
      render({ data }) {
        console.log(data);
        return "Promise rejected 🤯";
      },
    },
  });
};

const deleteMessageTemplate = (id: number) => {
  toast.promise(API_INSTANCES.MESSAGE_TEMPLATE.deleteMessageTemplate(id), {
    pending: "Promise is pending",
    success: {
      render({ data }) {
        dispatch(actions.messageTemplate.setTemplates(data?.data.templates));
        return "Promise resolved 👌";
      },
    },
    error: {
      render({ data }) {
        console.log(data);
        return "Promise rejected 🤯";
      },
    },
  });
};

export const MESSAGE_TEMPLATE = {
  getTag,
  getMessageTemplate,
  createMessageTemplate,
  updateMessageTemplate,
  deleteMessageTemplate,
};
