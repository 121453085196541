import { toast } from "react-toastify";

import { API_INSTANCES } from "src/API_INSTANCES";
import store, { actions } from "src/Redux";
import CandidateProps from "src/Redux/slices/Candidate/Candidate";

const dispatch = store.dispatch;

const updateIsRead = (application_id: string, applicant_index: number) => {
  API_INSTANCES.PIPELINE.updateIsRead(application_id)
    .then((res) =>
      dispatch(
        actions.candidate.setIsEmailOpen({
          applicant_index,
          is_read: res.data.is_read,
        })
      )
    )
    .catch((error) => console.log(error));
};

const getCandidateOverview = ({
  applicant_index,
  application_id,
  candidate_id,
  is_read,
}: CandidateProps.IGetCandidateOverview) => {
  dispatch(actions.candidate.setCandidateOverviewLoading(true));
  API_INSTANCES.PIPELINE.getCandidateOverview(application_id)
    .then((res) => {
      !is_read && updateIsRead(application_id, applicant_index);
      dispatch(
        actions.candidate.setSelectedCandidate({
          ...res.data,
          application_id,
          candidate_id,
        })
      );
    })
    .catch((err) => {
      dispatch(actions.candidate.setStep(0));
      console.log(err);
    })
    .finally(() =>
      dispatch(actions.candidate.setCandidateOverviewLoading(false))
    );
};

const getPipelineApplicants = (body: CandidateProps.PipelineApplicants) => {
  API_INSTANCES.PIPELINE.getPipelineApplicants(body)
    .then((response) => {
      dispatch(actions.candidate.setCandidatelist(response.data));
      let applicants = response.data.applicants;
      const { application_id, candidate_id, is_read } = applicants[0];
      applicants.length > 0 &&
        getCandidateOverview({
          application_id,
          candidate_id,
          is_read,
          applicant_index: 0,
        });
      applicants.length === 0 && dispatch(actions.candidate.setStep(0));
    })
    .catch((error) => {
      console.log(error);
    })
    .finally(() => dispatch(actions.common.setPageLoading(false)));
};

const downloadFile = (id: number, filename: string) => {
  toast.promise(API_INSTANCES.PIPELINE.getCandidateResume(id), {
    pending: "Promise is pending",
    success: {
      render({ data }) {
        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(data?.data);

        downloadLink.download = filename;

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        return "Promise resolved 👌";
      },
    },
    error: {
      render({ data }) {
        console.log(data);
        return "Promise rejected 🤯";
      },
    },
  });
};

const getCandidateQualifiers = (candidate_id: number) => {
  API_INSTANCES.PIPELINE.getCandidateQualifiers(candidate_id)
    .then((res) => {
      dispatch(
        actions.candidate.setQualifiers(
          res.data.qualifying_questions[0].questions
        )
      );
    })
    .catch((error) => console.log(error));
};

const getCandidateFiles = (candidate_id: number) => {
  API_INSTANCES.PIPELINE.getCandidateFiles(candidate_id)
    .then((res) => {
      dispatch(actions.candidate.setFiles(res.data.candidate_files[0].files));
    })
    .catch((error) => console.log(error));
};

const getCandidateResume = ({ file, id }: CandidateProps.GetFile) => {
  toast.promise(API_INSTANCES.PIPELINE.getCandidateResume(id), {
    pending: "Promise is pending",
    success: {
      render({ data }) {
        dispatch(
          actions.candidate.setSelectedFile({
            ...file,
            path: window.URL.createObjectURL(data?.data),
            size: Math.round(data?.data.size / 1024),
          })
        );
        dispatch(actions.candidate.setIsFilePreview(true));
        return "Promise resolved 👌";
      },
    },
    error: {
      render({ data }) {
        console.log(data);
        return "Promise rejected 🤯";
      },
    },
  });
};

const getCandidateExperience = (id: number) => {
  API_INSTANCES.PIPELINE.getCandidateExperience(id)
    .then((res) => {
      let candidate_work = res.data.candidate_work[0];
      const recentCareer = candidate_work.work;
      const recentEducation = candidate_work.education;
      dispatch(actions.candidate.setCareerHistory(recentCareer));
      dispatch(actions.candidate.setEducationHistory(recentEducation));
    })
    .catch((error) => console.log(error));
};

const deleteCandidateComment = (
  value: CandidateProps.DeleteCandidateComment
) => {
  toast.promise(API_INSTANCES.PIPELINE.deleteCandidateComment(value), {
    pending: "Promise is pending",
    success: {
      render({ data }) {
        const latestComment = data?.data.comment_items[0].comments;
        dispatch(actions.candidate.setComments(latestComment));
        return "Promise resolved 👌";
      },
    },
    error: {
      render({ data }) {
        console.log(data);
        return "Promise rejected 🤯";
      },
    },
  });
};

const getCandidateComment = (application_id: string) => {
  API_INSTANCES.PIPELINE.getCandidateComment(application_id)
    .then((res) => {
      const latestComment = res?.data.comment_items[0].comments;
      dispatch(actions.candidate.setComments(latestComment));
    })
    .catch((err) => console.log(err));
};

const postCandidateComment = (value: CandidateProps.PostCandidateComment) => {
  toast.promise(API_INSTANCES.PIPELINE.postCandidateComment(value), {
    pending: "Promise is pending",
    success: {
      render({ data }) {
        const latestComment = data?.data.comment_items[0].comments;
        dispatch(actions.candidate.setComments(latestComment));
        return "Promise resolved 👌";
      },
    },
    error: {
      render({ data }) {
        console.log(data);
        return "Promise rejected 🤯";
      },
    },
  });
};

const getPipelineOverview = (job_id: string) => {
  API_INSTANCES.PIPELINE.getPipelineOverview(job_id)
    .then((res) => dispatch(actions.pipelinePhase.incrementPhase(res.data)))
    .catch((err) => console.log(err));
};

const updatePipelineOverview = (
  value: CandidateProps.UpdatePipelineOverview
) => {
  toast.promise(API_INSTANCES.PIPELINE.updatePipelineOverview(value), {
    pending: "Promise is pending",
    success: {
      render({ data }) {
        dispatch(actions.candidate.setActivePage(0));
        dispatch(actions.pipelinePhase.incrementPhase(data?.data));
        return "Promise resolved 👌";
      },
    },
    error: {
      render({ data }) {
        console.log(data);
        return "Promise rejected 🤯";
      },
    },
  });
};

const sendMessageCandidate = (value: CandidateProps.SendMessageCandidate) => {
  toast.promise(API_INSTANCES.PIPELINE.sendMessageCandidate(value), {
    pending: "Promise is pending",
    success: {
      render({ data }) {
        console.log(data);
        return "Promise resolved 👌";
      },
    },
    error: {
      render({ data }) {
        console.log(data);
        return "Promise rejected 🤯";
      },
    },
  });
};

const updateCandidateReview = ({
  application_id,
  rating,
  selectedRating,
  PageDataHandler,
}: CandidateProps.UpdateCandidateReview) => {
  toast.promise(
    API_INSTANCES.PIPELINE.updateCandidateReview(application_id, rating),
    {
      pending: "Promise is pending",
      success: {
        render({ data }) {
          const candidateRating = data?.data.candidate_rating;
          if (
            !selectedRating.includes(candidateRating) &&
            selectedRating.length !== 0
          ) {
            PageDataHandler();
          }
          dispatch(
            actions.candidate.setCandidateRating({
              rating: candidateRating,
              application_id,
            })
          );
          return "Promise resolved 👌";
        },
      },
      error: {
        render({ data }) {
          console.log(data);
          return "Promise rejected 🤯";
        },
      },
    }
  );
};
const clearCandidateReview = ({
  PageDataHandler,
  application_id,
  selectedRating,
}: CandidateProps.ClearCandidateReview) => {
  toast.promise(API_INSTANCES.PIPELINE.clearCandidateReview(application_id), {
    pending: "Promise is pending",
    success: {
      render({ data }) {
        const candidateRating = data?.data.candidate_rating;
        if (
          !selectedRating.includes(candidateRating) &&
          selectedRating.length !== 0
        ) {
          PageDataHandler();
        }
        dispatch(
          actions.candidate.setCandidateRating({
            rating: candidateRating,
            application_id,
          })
        );
        return "Promise resolved 👌";
      },
    },
    error: {
      render({ data }) {
        console.log(data);
        return "Promise rejected 🤯";
      },
    },
  });
};

const getMyProfile = () => {
  API_INSTANCES.PIPELINE.getMyProfile()
    .then((res) => {
      dispatch(actions.settings.setCandidateDetails(res.data));
      dispatch(actions.common.setDarkTheme(res.data.theme_is_dark));
    })
    .catch((error) => console.log(error));
};

export const PIPELINE = {
  getPipelineApplicants,
  getCandidateOverview,
  downloadFile,
  getCandidateQualifiers,
  getCandidateFiles,
  getCandidateResume,
  getCandidateExperience,
  deleteCandidateComment,
  getCandidateComment,
  postCandidateComment,
  getPipelineOverview,
  updatePipelineOverview,
  sendMessageCandidate,
  updateCandidateReview,
  updateIsRead,
  clearCandidateReview,
  getMyProfile,
};
