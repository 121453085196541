import { FormikProps, FormikValues } from "formik";
import { FormFieldsProps } from "../FormFields";

const getRequiredFields = (formFields: FormFieldsProps.Fields) => {
  const requiredFields = {} as { [key: string]: boolean };
  Object.entries(formFields).forEach(([_name, value]) =>
    value.isRequired ? (requiredFields[value.fieldName] = true) : ""
  );
  return requiredFields;
};
const hasRequiredFieldNameWithNoValue = (
  requiredInputFields: Object,
  formikValues: FormikValues
) =>
  getRequiredFieldNameWithNoValue(requiredInputFields, formikValues)
    ? true
    : false;

const getRequiredFieldNameWithNoValue = (
  requiredInputFields: Object,
  formikValues: FormikValues
) => {
  const keys = Object.keys(requiredInputFields);
  if (keys.length > 0 && !formikValues) return true;
  const isRequiredFieldNameWithNoValue = keys.find((key) => {
    const value = formikValues[key];
    if (typeof value === "undefined") return true;
    if (typeof value === "string") return value === "";
    if (Array.isArray(value)) return value.length === 0;

    return false;
  });
  return isRequiredFieldNameWithNoValue;
};

const setFocusOnErrorNode = (errorNodeId: string) => {
  const errorNode = document.getElementById(errorNodeId);
  if (errorNode) {
    errorNode.scrollIntoView();
    window.scrollBy(0, -120);
    errorNode.click();
  }
};

type formikSubmitHandlerProps = {
  formFields: FormFieldsProps.Fields;
  formik: FormikProps<any>;
  onSubmitHandler: (values: FormikValues) => void;
  idPrefix: string;
};
const formikSubmitHandler = ({
  formFields,
  formik,
  idPrefix,
  onSubmitHandler,
}: formikSubmitHandlerProps) => {
  const requiredFields = getRequiredFields(formFields);
  formik.setTouched(requiredFields);
  formik.validateForm();

  const requiredFieldNameWithNoValue = getRequiredFieldNameWithNoValue(
    requiredFields,
    formik.values
  );
  const isFormUnTouched = Object.keys(formik.touched).length === 0;
  const isNoErrors = isFormUnTouched || Object.keys(formik.errors).length === 0;
  const isValidForm = !requiredFieldNameWithNoValue && isNoErrors;

  !isValidForm &&
    setFocusOnErrorNode(idPrefix + "_" + requiredFieldNameWithNoValue);
  isValidForm && onSubmitHandler(formik.values);
  return isValidForm;
};

export const FormikHelpers = {
  getRequiredFields,
  getRequiredFieldNameWithNoValue,
  hasRequiredFieldNameWithNoValue,
  formikSubmitHandler,
  setFocusOnErrorNode,
};
