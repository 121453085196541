import instance from "src/axios/axios-user";
import { PayLoadCompanyProfileDetail } from "src/components/Settings/CompanyProfileSettings/CompanyProfileSettings";
import { API_PATHS } from "src/utils/API_PATHS";

const getCompanyDetails = () => {
  return instance.get(API_PATHS.companyProfileSettings);
};
const updateCompanyDetails = (values: PayLoadCompanyProfileDetail) =>
  instance.put(API_PATHS.companyProfileSettings, JSON.stringify(values));

const getCompanyLogo = () => {
  return instance.get(API_PATHS.companyProfileLogo);
};

const uploadCompanyLogo = (values: File) => {
  var formdata = new FormData();
  formdata.append("company_logo", values as File);
  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: API_PATHS.companyProfileLogo,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formdata,
  };
  return instance(config);
};

const getCompanyCover = () => {
  return instance.get(API_PATHS.companyProfileCover);
};

const uploadCompanyCover = (values: File) => {
  const formdata = new FormData();
  formdata.append("company_cover", values as File);
  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: API_PATHS.companyProfileCover,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formdata,
  };
  return instance(config);
};

const getCompanyGallery = () => {
  return instance.get(API_PATHS.companyProfileGallery);
};
const uploadCompanyGallery = (values: File) => {
  const formdata = new FormData();
  formdata.append("files", values);
  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: API_PATHS.companyProfileUploadGallery,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formdata,
  };
  return instance(config);
};

const deleteCompanyGallery = (id: number) => {
  return instance.delete(`${API_PATHS.companyProfileGallery}/${id}`);
};
const updateGalleryPhotoCaption = (id: number, caption: string) => {
  return instance.post(API_PATHS.updateGalleryPhotoCaption, { id, caption });
};
const updateGalleryPhotoPosition = (id: number, position: number) => {
  return instance.post(API_PATHS.updateGalleryPhotoPosition, { id, position });
};

export const COMPANY_SETTINGS = {
  getCompanyDetails,
  updateCompanyDetails,
  uploadCompanyLogo,
  getCompanyLogo,
  uploadCompanyCover,
  getCompanyCover,
  uploadCompanyGallery,
  getCompanyGallery,
  deleteCompanyGallery,
  updateGalleryPhotoCaption,
  updateGalleryPhotoPosition,
};
