import { TermsAndPrivacyComponents } from "../Components";

const { Points, Text } = TermsAndPrivacyComponents;

export const DisclaimersAndWarranties = () => (
  <>
    <Points.Heading pointNumber="10." heading="Disclaimers And Warranties" />
    <Points.Indent>
      <>
        <Points.Span pointNumber="10.1." Content={POINT_10_1} />
        <Points.Span pointNumber="10.2." Content={POINT_10_2} />
        <Points.Bold pointNumber="10.3." Content={POINT_10_3} />
        <Points.Bold pointNumber="10.4." Content={POINT_10_4} />
        <Points.Span pointNumber="10.5." Content={POINT_10_5} />
      </>
    </Points.Indent>
  </>
);

const POINT_10_1 = (
  <Points.Content>
    <Text.Span
      content={`Nova and the Services, including intellectual property appearing therein, are provided "as is" and "as available". Ditto Media makes no representations or warranties, express or implied, including but not limited to warranties as to the accuracy, correctness, or suitability of either Nova or information provided by another user, the information contained about any job listing, Employer, or Jobseeker.`}
    />
  </Points.Content>
);
const POINT_10_2 = (
  <Points.Content>
    <Text.Span
      content={`All content, information, and/or opinions of users made available on Nova are those of the authors and not Ditto Media. While we make every reasonable effort to present such information accurately and reliably on Nova we do not endorse, approve, or certify such information, nor guarantee the accuracy or completeness of such information on Nova.`}
    />
  </Points.Content>
);
const POINT_10_3 = (
  <Points.Content>
    <Text.Bold
      content={`Ditto Media, its shareholders, directors, employees, and partners, accept no liability whatsoever for any loss, whether direct or indirect, consequential, or arising from information made available on (or by means of) Nova and/or transactions or actions resulting therefrom.`}
    />
  </Points.Content>
);
const POINT_10_4 = (
  <Points.Content>
    <Text.Bold
      content={`Ditto Media, its shareholders, directors, employees, partners, and affiliates, accept no liability whatsoever for any costs, expenses, fines, or damages, including but not limited to direct or indirect loss or damages, including any economic loss, consequential loss, loss of profits or any form of punitive damages, resulting from the facilitation and offering of the Services, and access to, or use of, Nova in any manner.`}
    />
  </Points.Content>
);
const POINT_10_5 = (
  <Points.Content>
    <Text.Span
      content={`We take reasonable security measures to ensure the safety and integrity of Nova and to exclude viruses, unlawful monitoring and/or access from Nova offline. However, we do not warrant or represent that your access to Nova will be uninterrupted or error-free or that any information, data, content, software, or other material accessible through Nova will be free of bugs, viruses, worms, trojan horses or other harmful components. Your access to and use of Nova remains solely at your own risk, and you should take your own precautions accordingly.`}
    />
  </Points.Content>
);
