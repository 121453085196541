import instance from "src/axios/axios-user";
import CandidateProps from "src/Redux/slices/Candidate/Candidate";
import { API_PATHS } from "src/utils/API_PATHS";

const getPipelineApplicants = (body: CandidateProps.PipelineApplicants) => {
  const config = {
    method: "post",
    url: API_PATHS.getPipelineApplicants,
    headers: { "Content-Type": "application/json" },
    data: JSON.stringify(body),
  };
  return instance(config);
};
const getPipelineOverview = (id: string) => {
  return instance.get(`${API_PATHS.pipelineOverview}/${id}`);
};
const updatePipelineOverview = ({
  candidate_id,
  job_id,
  status_id,
}: CandidateProps.UpdatePipelineOverview) => {
  const body = { candidate_id, status_id };
  const config = {
    method: "put",
    url: `${API_PATHS.pipelineOverview}/${job_id}`,
    headers: { "Content-Type": "application/json" },
    data: JSON.stringify(body),
  };
  return instance(config);
};

const getCandidateOverview = (id: string) => {
  return instance.get(`${API_PATHS.candidateOverview}/${id}`);
};
const getCandidateExperience = (id: number) => {
  return instance.get(`${API_PATHS.candidateExperience}/${id}`);
};
const getCandidateFiles = (id: number) => {
  return instance.get(`${API_PATHS.candidateFiles}/${id}`);
};
const getCandidateQualifiers = (id: number) => {
  return instance.get(`${API_PATHS.candidateQualifiers}/${id}`);
};
const getCandidateComment = (id: string) => {
  return instance.get(`${API_PATHS.candidateComments}/${id}`);
};
const deleteCandidateComment = ({
  application_id,
  id,
  user_id,
}: CandidateProps.DeleteCandidateComment) => {
  const config = {
    method: "delete",
    url: `${API_PATHS.candidateComments}/${user_id}`,
    headers: { "Content-Type": "application/json" },
    data: JSON.stringify({ id, application_id }),
  };
  return instance(config);
};
const postCandidateComment = (body: CandidateProps.PostCandidateComment) => {
  const config = {
    method: "post",
    url: API_PATHS.candidateComments,
    headers: { "Content-Type": "application/json" },
    data: JSON.stringify(body),
  };
  return instance(config);
};
const updateIsRead = (id: string) => {
  return instance.get(`${API_PATHS.updateIsRead}/${id}`);
};

const updateCandidateReview = (id: string, review: number) => {
  return instance.post(
    API_PATHS.updateCandidateReview,
    JSON.stringify({
      application_id: id,
      candidate_rating: review,
    })
  );
};
const clearCandidateReview = (id: string) => {
  return instance.put(`${API_PATHS.clearCandidateReview}/${id}`);
};

const getCandidateResume = (id: number) => {
  return instance.get(`${API_PATHS.candidateFiles}/file/${id}`, {
    responseType: "blob",
  });
};

const sendMessageCandidate = ({
  application_id,
  email,
  message,
  subject,
}: CandidateProps.SendMessageCandidate) => {
  const formdata = new FormData();
  formdata.append("subject", subject);
  formdata.append("message_body", message);
  formdata.append("candidate_email", email);
  formdata.append("application_id", application_id);

  return instance.post(API_PATHS.candidateOverviewMessage, formdata, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};

const getMyProfile = () => {
  return instance.get(API_PATHS.myProfile);
};

export const PIPELINE = {
  postCandidateComment,
  getCandidateComment,
  getCandidateQualifiers,
  getCandidateFiles,
  getPipelineApplicants,
  getPipelineOverview,
  updatePipelineOverview,
  getCandidateOverview,
  getCandidateExperience,
  updateIsRead,
  updateCandidateReview,
  getCandidateResume,
  deleteCandidateComment,
  sendMessageCandidate,
  clearCandidateReview,
  getMyProfile,
};
