import { Dropdown, Navbar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";

import { userSettings } from "src/utils/userSettings";
import { API_CALLS } from "src/API_CALLS";
import { ICONS } from "src/assets/custom_Icons";
import { useState } from "react";
import styled from "styled-components";
import { theme } from "src/components/Theme";
import { useAppSelector } from "src/Redux";
// import NotificationDropDown from "./NotificationDropDown";

const RightHeaderSection = () => {
  const navigate = useNavigate();
  const [isThemeOpen, setIsThemeOpen] = useState(false);
  const { common, settings } = useAppSelector((state) => state);
  const { isDarkTheme } = common;
  const { candidateDetails } = settings;

  return (
    <RightHeaderSectionContainer className="main-header-right">
      <div className="mb-0 navbar-expand-lg navbar-nav-right responsive-navbar navbar-dark p-0">
        <Navbar.Collapse className="collapse" id="navbarSupportedContent-4">
          <ul className="nav nav-item header-icons navbar-nav-right">
            {/* <NotificationDropDown /> */}
            <Dropdown
              className="main-profile-menu nav nav-item nav-link ps-lg-2"
              id="main-profile-menu"
            >
              <Dropdown.Toggle
                className="new nav-link profile-user d-flex"
                variant=""
              >
                <img
                  alt=""
                  src={`data:image/png;base64,${candidateDetails.photo}`}
                  className=""
                />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <div className="menu-header-content p-3 border-bottom">
                  <div className="d-flex wd-100p">
                    <div className="main-img-user">
                      <img
                        alt=""
                        src={`data:image/png;base64,${candidateDetails.photo}`}
                        className=""
                      />
                    </div>
                    <div className="ms-3 my-auto">
                      <h6
                        className="tx-15 font-weight-semibold mb-0"
                        style={{ textTransform: "capitalize" }}
                      >
                        {candidateDetails.first_name +
                          " " +
                          candidateDetails.last_name}
                      </h6>
                    </div>
                  </div>
                </div>

                {userSettings.map(({ icon, url, title }, index) => (
                  <Dropdown.Item
                    key={`${title}_${index}`}
                    className="dropdown-item"
                    href={`${process.env.PUBLIC_URL}${url}`}
                  >
                    {icon}
                    {title}
                  </Dropdown.Item>
                ))}
                <div className="position-relative">
                  <div
                    className={clsx(
                      "dropdown-item",
                      isThemeOpen && "theme-open"
                    )}
                    onClick={() => setIsThemeOpen(!isThemeOpen)}
                  >
                    <ICONS.ThemeIcon />
                    Theme
                  </div>
                  {isThemeOpen && (
                    <ThemeDropdownContainer>
                      <ThemeDropdownItem
                        isSelected={!isDarkTheme}
                        onClick={() => {
                          setIsThemeOpen(false);
                          if (isDarkTheme) API_CALLS.COMMON.toggleTheme();
                        }}
                        className={clsx(!isDarkTheme && "theme-selected")}
                      >
                        <ICONS.LightModeIcon
                          color={!isDarkTheme ? "primary_default" : "black70"}
                        />
                        Light
                      </ThemeDropdownItem>
                      <ThemeDropdownItem
                        isSelected={isDarkTheme}
                        onClick={() => {
                          setIsThemeOpen(false);
                          if (!isDarkTheme) API_CALLS.COMMON.toggleTheme();
                        }}
                        className={clsx(isDarkTheme && "theme-selected")}
                      >
                        <ICONS.DarkModeIcon
                          color={isDarkTheme ? "primary_default" : "black70"}
                        />
                        Dark
                      </ThemeDropdownItem>
                    </ThemeDropdownContainer>
                  )}
                </div>
                <Dropdown.Item
                  className="dropdown-item"
                  onClick={() => {
                    API_CALLS.AUTH.logout(navigate);
                  }}
                >
                  <ICONS.SignoutIcon />
                  Sign Out
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </ul>
        </Navbar.Collapse>
      </div>
    </RightHeaderSectionContainer>
  );
};
export default RightHeaderSection;

const ThemeDropdownContainer = styled.div`
  position: absolute;
  top: 7px;
  right: 229px;
  width: 230px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid ${theme.colors.black10};
  box-shadow: 1px 1px 7px 0px rgba(154, 154, 204, 0.1);
  background: ${theme.colors.white_pure};
  z-index: 10000;
  @media (max-width: 991px) {
    position: initial;
    width: 100%;
    border: none;
  }
  .dark-theme & {
    background: ${theme.colors.black120};
    border: 1px solid ${theme.colors.black130};
    @media (max-width: 991px) {
      border: none;
      border-radius: 0;
    }
  }
`;

const ThemeDropdownItem = styled.div<{ isSelected: boolean }>`
  color: ${({ isSelected }) =>
    isSelected ? theme.colors.primary_default : theme.colors.black70};
  display: flex;
  gap: 16px;
  padding: 15px 15px 14px;
  cursor: pointer;
  &:hover {
    background: ${theme.colors.grey_input_dropdown_hover};
  }
  @media (max-width: 991px) {
    padding: 15px 15px 14px 49px;
  }
  .dark-theme & {
    color: ${theme.colors.black10};
    svg path {
      fill: ${theme.colors.grey100};
    }
    &:hover {
      background: ${theme.colors.black130};
    }
  }
`;

const RightHeaderSectionContainer = styled.div`
  .dark-theme & .dropdown-item {
    color: ${theme.colors.black10};
    svg path {
      stroke: ${theme.colors.grey100} !important;
    }
  }
`;
