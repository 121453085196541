import { useLocation } from "react-router-dom";
import styled from "styled-components";

import styles from "./Sidebar.module.css";
import { headerNavLinksData } from "../../../utils/headerNavLinksData";
import { CustomNavLink, DarkModeButton } from "../../Basic";
import { CreateJobButton } from "../CreateJobButton";

const Sidebar = () => {
  const router = useLocation();

  return (
    <>
      <div className="sticky">
        <aside className="app-sidebar">
          <div className={styles.sidebar}>
            <div className={styles.buttonContainer}>
              <CreateJobButton />
            </div>
            <div className="main-sidemenu">
              <MenuContainer>
                {headerNavLinksData.map((menuItem, index) => {
                  return (
                    <RightBorderContainer
                      key={`${menuItem.title}_${index}`}
                      isRightSideBorder={router?.pathname === menuItem.pathName}
                    >
                      <CustomNavLink
                        {...menuItem}
                        currentPathName={router?.pathname}
                      />
                    </RightBorderContainer>
                  );
                })}
              </MenuContainer>
              <div className={styles.darkmode_button}>
                <DarkModeButton />
              </div>
            </div>
          </div>
        </aside>
      </div>
    </>
  );
};

export default Sidebar;

const MenuContainer = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 43px;
`;

const RightBorderContainer = styled.div<{ isRightSideBorder: boolean }>`
  width: 101%;
  border-right: ${({ isRightSideBorder }) =>
    isRightSideBorder && "3px solid var(--primary-bg-color)"};
`;
