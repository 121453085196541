import { CreateJobProps } from "./CreateJob";
import { createSlice } from "@reduxjs/toolkit";

export const CreateJobPropsSteps = [1, 2, 3] as const;

const startDate = new Date();
const endDate = new Date(startDate);
endDate.setDate(startDate.getDate() + 30);

const initialState: CreateJobProps.State = {
  stepNumber: 1,
  isEditFlow: false,

  //#region Create
  createNovaJobForm: {
    show_company_gallery: false,
    add_external_apply_URL: false,
    add_qualifying_question: false,
    external_apply: "",
    advertised_type: "0",
  } as CreateJobProps.CreateNovaJobForm,
  createUpdateRequest: {} as CreateJobProps.CreateUpdateRequest,
  createInProgress: false,
  isJobCreated: false,
  createResponse: {} as CreateJobProps.CreateResponse,
  createError: "",
  //#endregion

  //#region Update
  updateInProgress: false,
  updateResponse: {} as CreateJobProps.UpdateResponse,
  updateError: "",
  //#endregion

  questionComponents: {} as CreateJobProps.questionComponents,
};

const createJobSlice = createSlice({
  name: "createJob",
  initialState,
  reducers: {
    setStepNumber: (state, action: CreateJobProps.setStepNumber) => {
      state.stepNumber = action.payload;
    },
    setIsEditFlow: (state, action: CreateJobProps.setIsEditFlow) => {
      state.isEditFlow = action.payload;
    },

    //#region Create
    setCreateNovaJobForm: (
      state,
      action: CreateJobProps.setCreateNovaJobForm
    ) => {
      state.createNovaJobForm = action.payload;
    },
    setCreateUpdateRequest: (
      state,
      action: CreateJobProps.setCreateUpdateRequest
    ) => {
      state.createUpdateRequest = action.payload;
    },
    setCreateInProgress: (
      state,
      action: CreateJobProps.setCreateInProgress
    ) => {
      state.createInProgress = action.payload;
    },
    setJobCreated: (state, action: CreateJobProps.setJobCreated) => {
      state.isJobCreated = action.payload;
    },
    setCreateResponse: (state, action: CreateJobProps.setCreateResponse) => {
      state.createResponse = action.payload;
    },
    setCreateError: (state, action: CreateJobProps.setCreateError) => {
      state.createError = action.payload;
    },
    setQuestionComponents: (
      state,
      action: CreateJobProps.setQuestionComponents
    ) => {
      state.questionComponents = action.payload;
    },
    //#endregion

    //#region Update
    setUpdateInProgress: (
      state,
      action: CreateJobProps.setUpdateInProgress
    ) => {
      state.updateInProgress = action.payload;
    },
    setUpdateResponse: (state, action: CreateJobProps.setUpdateResponse) => {
      state.updateResponse = action.payload;
    },
    setUpdateError: (state, action: CreateJobProps.setUpdateError) => {
      state.updateError = action.payload;
    },
    //#endregion

    reset: () => initialState,
  },
});

export const createJobReducer = createJobSlice.reducer;
export const createJobActions = createJobSlice.actions;
