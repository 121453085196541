import { useContext } from "react";

import { CheckoutStyles } from "./Styles";
import { AppContext } from "src/App";

type PayNowButtonProps = {
  disabled: boolean;
  clickHandler?: () => void;
};
export const PayNowButton = ({ disabled, clickHandler }: PayNowButtonProps) => {
  const { isMobile } = useContext(AppContext);

  return (
    <CheckoutStyles.ButtonWrapper>
      <button
        className="btn btn-primary"
        type="submit"
        disabled={disabled}
        style={{ width: isMobile ? "320px" : "auto" }}
        onClick={() => {
          clickHandler && clickHandler();
        }}
      >
        Pay Now
      </button>
    </CheckoutStyles.ButtonWrapper>
  );
};
