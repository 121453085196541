import { Dispatch, SetStateAction, useContext, useState } from "react";
import { Button } from "react-bootstrap";
import {
  AddressElement,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";

import { Typography } from "src/components/Basic";
import { ButtonContainer, FormContainer, FormDetailContainer } from "./style";
import { useAppSelector } from "src/Redux";
import { AppContext } from "src/App";
import { theme } from "src/components/Theme";
import { ROUTES } from "src/utils/ROUTES";

const AddNewCardForm = ({
  setIsAddCardFormOpen,
}: {
  setIsAddCardFormOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const { isDarkTheme } = useAppSelector((store) => store.common);
  const { isTabletOrSmall } = useContext(AppContext);
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState("");


  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!elements || !stripe) return;

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      // Show error to your customer
      console.log(submitError);
      setErrorMessage(submitError.message || "");
      return;
    }

    const { error } = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: window.location.origin + ROUTES.SETTINGS,
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message || "");
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <>
      <FormDetailContainer>
        <Typography
          content="Add New Card"
          styleName="jobCheckoutFormBillingTitle"
          color="primary_default"
        />

        <form onSubmit={handleSubmit}>
          <FormContainer>
            <PaymentElement />
            <div style={{ paddingTop: isTabletOrSmall ? "32px" : "40px" }}>
              <Typography
                styleName="jobCheckoutFormBillingTitle"
                content="Billing Address"
                color={isDarkTheme ? "success_default" : "primary_default"}
              />
            </div>

            <AddressElement options={{ mode: "billing" }} />
            <br />
            {false && errorMessage && (
              <div style={{ color: theme.colors.red100 }}>{errorMessage}</div>
            )}
          </FormContainer>
          <ButtonContainer>
            <Button variant="light" onClick={() => setIsAddCardFormOpen(false)}>
              Cancel
            </Button>
            <Button
              variant="primary"
              disabled={!stripe || !elements}
              type="submit"
            >
              Save
            </Button>
          </ButtonContainer>
        </form>
      </FormDetailContainer>
    </>
  );
};

export default AddNewCardForm;
