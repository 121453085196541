import { TermsAndPrivacyComponents } from "../Components";

const { Points, Text } = TermsAndPrivacyComponents;

export const DisputeResolution = () => (
  <>
    <Points.Heading pointNumber="12." heading="Dispute Resolution" />
    <Points.Indent>
      <>
        <Points.Span pointNumber="12.1." Content={POINT_12_1} />
        <Points.Span pointNumber="12.2." Content={POINT_12_2} />
        <Points.Span pointNumber="12.3." Content={POINT_12_3} />
        <Points.Span pointNumber="12.4." Content={POINT_12_4} />
        <POINT_12_5 />
      </>
    </Points.Indent>
  </>
);

const POINT_12_1 = (
  <Points.Content>
    <Text.Span
      content={`Should any dispute, disagreement or claim arise between you and Ditto Media, we shall endeavor to resolve the dispute amicably, by negotiation, and with the best interests of both parties in mind.`}
    />
  </Points.Content>
);
const POINT_12_2 = (
  <Points.Content>
    <Text.Span
      content={`If the dispute is still not resolved, the parties will commence and be party to binding and confidential arbitration with an arbitrator selected by Ditto Media.`}
    />
  </Points.Content>
);
const POINT_12_3 = (
  <Points.Content>
    <Text.Span
      content={`Notwithstanding the above, both parties' consent to the jurisdiction of a competent court. Either party may also always use the dispute resolution services of any applicable legislative tribunal or ombud, as provided for in applicable legislation.`}
    />
  </Points.Content>
);
const POINT_12_4 = (
  <Points.Content>
    <Text.Span
      content={`The parties both agree that in no circumstance will either party publicize the dispute on any social media or other public platforms. The parties understand that any publicity of this nature can cause serious damage to the other party, which damage may result in a financial claim against the infringing party.`}
    />
  </Points.Content>
);

//#region 12.5
const POINT_12_5 = () => (
  <>
    <Points.SubHeading
      pointNumber="12.5."
      heading={`Disputes between users:`}
    />
    <Points.Indent>
      <>
        <Points.Span2 pointNumber="12.5.1." Content={POINT_12_5_1} />
        <Points.Span2 pointNumber="12.5.2." Content={POINT_12_5_2} />
        <Points.Span2 pointNumber="12.5.3." Content={POINT_12_5_3} />
      </>
    </Points.Indent>
  </>
);
const POINT_12_5_1 = (
  <Points.Content>
    <Text.Span
      content={`Should a dispute arise between users and their private transaction relating to the advertisement of a job listing, such dispute is between the users exclusively. Ditto Media is not responsible for fulfilling any function in any way or in any role.`}
    />
  </Points.Content>
);
const POINT_12_5_2 = (
  <Points.Content>
    <Text.Span
      content={`The users agree that they shall handle their private dispute between them in a manner they choose for the attempted benefit of both parties.`}
    />
  </Points.Content>
);
const POINT_12_5_3 = (
  <Points.Content>
    <Text.Span
      content={`The users may inform Ditto Media of the dispute to log the issues experienced, and to try assist both parties in whatever way it deems fit, but it is under no obligation to do so.`}
    />
  </Points.Content>
);
//#endregion
