import { TermsAndPrivacyComponents } from "../Components";

const { Points, Text } = TermsAndPrivacyComponents;

export const JobPostingGuidelines = () => (
  <>
    <Points.Heading pointNumber="6." heading="Job Posting Guidelines" />
    <Points.Indent>
      <>
        <Points.SubHeading
          pointNumber="6.1."
          heading={`When creating a job listing on Nova, we require the following guidelines to be followed/understood: `}
          needUnderline={false}
        />
        <Points.Indent>
          <>
            <Points.Span2 pointNumber="6.1.1." Content={POINT_6_1_1} />
            <Points.Span2 pointNumber="6.1.2." Content={POINT_6_1_2} />
            <Points.Span2 pointNumber="6.1.3." Content={POINT_6_1_3} />
            <Points.Span2 pointNumber="6.1.4." Content={POINT_6_1_4} />
            <Points.Span2 pointNumber="6.1.5." Content={POINT_6_1_5} />
            <Points.Span2 pointNumber="6.1.6." Content={POINT_6_1_6} />
            <Points.Span2 pointNumber="6.1.7." Content={POINT_6_1_7} />
            <Points.Span2 pointNumber="6.1.8." Content={POINT_6_1_8} />
            <Points.Span2 pointNumber="6.1.9." Content={POINT_6_1_9} />
            <Points.Span2 pointNumber="6.1.10." Content={POINT_6_1_10} />
            <Points.Span2 pointNumber="6.1.11." Content={POINT_6_1_11} />
            <Points.Span2 pointNumber="6.1.12." Content={POINT_6_1_12} />
            <Points.Span2 pointNumber="6.1.13." Content={POINT_6_1_13} />
            <Points.Span2 pointNumber="6.1.13." Content={POINT_6_1_14} />
          </>
        </Points.Indent>
      </>
    </Points.Indent>
  </>
);

const POINT_6_1_1 = (
  <Points.Content>
    <Text.Span
      content={`you must post actual or authentic employment opportunities and will be in violation of these Terms if you advertise fictitious positions whether with the intent to create an employment pipeline or to defraud any Jobseeker;`}
    />
  </Points.Content>
);
const POINT_6_1_2 = (
  <Points.Content>
    <Text.Span
      content={`contact details are not allowed to be added to any job advert, this includes email addresses and telephone/cellphone numbers;`}
    />
  </Points.Content>
);
const POINT_6_1_3 = (
  <Points.Content>
    <Text.Span
      content={`If we receive complaints from Jobseekers stating that you are charging Jobseekers to process their application, we reserve the right to remove your job listings and suspend or terminate your account without refund; `}
    />
  </Points.Content>
);
const POINT_6_1_4 = (
  <Points.Content>
    <Text.Span
      content={`Links to external websites may not be used in a job description. You have the option to add your own “apply URL” but you accept full responsibility for the use of that link by Jobseekers; `}
    />
  </Points.Content>
);
const POINT_6_1_5 = (
  <Points.Content>
    <Text.Span
      content={`You understand that we reserve the right to remove any listings for any reason whatsoever in our reasonable opinion;`}
    />
  </Points.Content>
);
const POINT_6_1_6 = (
  <Points.Content>
    <Text.Span
      content={`You must familiarize yourself with all local and national requirements for posting job listings. We will not be held responsible if you violate any local laws surrounding job listings and their minimum requirements;  `}
    />
  </Points.Content>
);
const POINT_6_1_7 = (
  <Points.Content>
    <Text.Span
      content={`We do not allow the posting of any franchising or business opportunities; `}
    />
  </Points.Content>
);
const POINT_6_1_8 = (
  <Points.Content>
    <Text.Span
      content={`Only paid positions may be advertised. We will not accept the posting of any unpaid internships or unpaid roles;`}
    />
  </Points.Content>
);
const POINT_6_1_9 = (
  <Points.Content>
    <Text.Span
      content={`Any form of “get rich quick scheme” may not be posted (including but not limited to multi-level marketing schemes or similar systems);`}
    />
  </Points.Content>
);
const POINT_6_1_10 = (
  <Points.Content>
    <Text.Span
      content={`All job listings need to represent the role as accurately as possible;  `}
    />
  </Points.Content>
);
const POINT_6_1_11 = (
  <Points.Content>
    <Text.Span
      content={`A job listing may not include the names, logos, or trademarks of a company that is not affiliated to you and the company you are representing on Nova; `}
    />
  </Points.Content>
);
const POINT_6_1_12 = (
  <Points.Content>
    <Text.Span
      content={`Job listings must be advertised in the actual location where the position will be located; `}
    />
  </Points.Content>
);
const POINT_6_1_13 = (
  <Points.Content>
    <Text.Span
      content={`material or links to material that exploits anyone in a sexual, violent, or associated manner, or solicits personal information from anyone under the age of 18 will not be allowed; and `}
    />
  </Points.Content>
);
const POINT_6_1_14 = (
  <Points.Content>
    <Text.Span
      content={`you allow, and grant to, Ditto Media a non-exclusive and non-transferable right to your intellectual property to be used only on Nova and/or by Ditto Media when advertising or describing job listings on Nova to Jobseekers.`}
    />
  </Points.Content>
);
