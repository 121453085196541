import { API_INSTANCES } from "src/API_INSTANCES";
import store, { actions, CommonProps } from "src/Redux";

const dispatch = store.dispatch;

const getSkills = () => {
  API_INSTANCES.COMMON.getSkills()
    .then((response) => {
      const result: CommonProps.skills = response.data;
      console.log("GET SKILL RESPONSE", result.length);
      dispatch(actions.common.setSkills(result));
      return response;
    })
    .catch((error) => console.log("GET SKILL ERROR", error));
};

const getIndustries = () => {
  API_INSTANCES.COMMON.getIndustries()
    .then((response) => {
      const result: CommonProps.industries = response.data;
      console.log("GET INDUSTRY RESPONSE", result.length);
      dispatch(actions.common.setIndustries(result));
      return response;
    })
    .catch((error) => console.log("GET INDUSTRY ERROR", error));
};

const getCities = (data: CommonProps.cityAPIData) => {
  dispatch(actions.common.setGetCitiesAPIInProgress(true));
  API_INSTANCES.COMMON.getCities(data)
    .then((response) => {
      const result: CommonProps.cities = response.data?.data;
      dispatch(actions.common.setCities(result));
      console.log("GET CITY RESPONSE", result);
      return result;
    })
    .catch((error) => console.log("GET CITY ERROR", error))
    .finally(() => {
      dispatch(actions.common.setGetCitiesAPIInProgress(false));
    });
};

const getCityById = (id: string) => {
  API_INSTANCES.COMMON.getCityById(id)
    .then((response) => {
      const result: CommonProps.cities = response.data?.data;
      dispatch(actions.common.setCities(result));
      console.log("GET CITY BY ID RESPONSE", result);
      return result;
    })
    .catch((error) => console.log("GET CITY BY ID  ERROR", error));
};

const toggleTheme = () => {
  API_INSTANCES.COMMON.toggleTheme()
    .then((response) => {
      dispatch(actions.settings.setCandidateDetails(response.data));
      dispatch(actions.common.setDarkTheme(response.data.theme_is_dark));
    })
    .catch((error) => console.log(error));
};

export const COMMON = {
  getSkills,
  getIndustries,
  getCities,
  getCityById,
  toggleTheme,
};
