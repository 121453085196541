import { TermsAndPrivacyComponents } from "../Components";

const { Points, Text } = TermsAndPrivacyComponents;

export const ServicesOffered = () => (
  <>
    <Points.Heading pointNumber="2." heading="Services Offered through Nova " />
    <Points.Indent>
      <>
        <Points.Span pointNumber="2.1." Content={POINT_2_1} />
        <Points.Span pointNumber="2.2." Content={POINT_2_2} />
        <Points.Span pointNumber="2.3." Content={POINT_2_3} />
        <Points.Span pointNumber="2.4." Content={POINT_2_4} />
        <Points.Span pointNumber="2.5." Content={POINT_2_5} />
      </>
    </Points.Indent>
  </>
);

const POINT_2_1 = (
  <Points.Content>
    <Text.Span
      content={`Nova is integrated with our current platform Ditto Jobs, an international job portal. Users of Ditto Jobs use Nova to assist them in finding a job listing that matches their skillset and interests. Where Ditto Job is geared towards Jobseekers, Nova is focused on Employers. `}
    />
  </Points.Content>
);
const POINT_2_2 = (
  <Points.Content>
    <Text.Span
      content={`Nova allows Employers to create and list available jobs and employment opportunities and consider and browse interested applications.  Job listings published on Nova are shared with other international job portals, job aggregators, and search platforms who are integration partners of Ditto Media. A list of our integration partners can be provided on request.`}
    />
  </Points.Content>
);
const POINT_2_3 = (
  <Points.Content>
    <Text.Span
      content={`The personal information of a Jobseeker captured through Ditto Jobs will be available to view by an Employer once the Jobseeker applies for an Employer’s advertised position.`}
    />
  </Points.Content>
);
const POINT_2_4 = (
  <Points.Content>
    <Text.Span
      content={`The personal data available will include a Jobseekers curriculum vitae or resume, their contact details, and the information added to their Candidate Profile on Ditto Jobs. `}
    />
  </Points.Content>
);
const POINT_2_5 = (
  <Points.Content>
    <Text.Span
      content={`Nova will not accept job listings that are for any unpaid positions and does not allow Employers to charge Jobseekers a fee for submitting a job application.`}
    />
  </Points.Content>
);
