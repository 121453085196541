import { useEffect } from "react";
import { Formik } from "formik";

import { FormFields, Typography } from "src/components/Basic";
import {
  EmailNotificationContainer,
  HeadingContainer,
  NotificationDetailContainer,
  NotificationDetail,
} from "./style";
import { emailNotificationFields } from "./utils";
import { API_CALLS } from "src/API_CALLS";
import { useAppSelector } from "src/Redux";

const EmailNotification = () => {
  const { emailNotification } = useAppSelector((state) => state.settings);

  useEffect(() => {
    API_CALLS.EMAIL_NOTIFICATION.getEmailNotificationSettings();
  }, []);

  const onSubmitHandler = (key: string, value: boolean) => {
    API_CALLS.EMAIL_NOTIFICATION.updateEmailNotificationSettings(key, value);
  };

  return (
    <EmailNotificationContainer>
      <HeadingContainer>
        <Typography
          styleName="candidateNameEmailOpen"
          content="EMAIL NOTIFICATIONS"
        />
        <Typography
          styleName="text1"
          content="Turn your notifications on or off."
        />
      </HeadingContainer>
      <Formik
        enableReinitialize
        initialValues={{
          ...emailNotification,
        }}
        onSubmit={() => {}}
      >
        {() => (
          <NotificationDetailContainer>
            <NotificationDetail>
              <div className="d-flex justify-content-between">
                <Typography
                  styleName="subtitle3"
                  content="Daily Applications Summary"
                />
                <FormFields.Switch
                  {...{
                    ...emailNotificationFields.daily_applications,
                    onChangeHandler: (value) => {
                      onSubmitHandler(
                        emailNotificationFields.daily_applications.fieldName,
                        !value
                      );
                    },
                  }}
                />
              </div>
              <div className="notification-detail">
                <Typography
                  styleName="text_12pt"
                  content="Choose Template. Choose Template. Choose Template. Choose template and add information.
                           Choose Template. This is where we talk talk talk. Choose Template. This is where we talk."
                  color="black70"
                />
              </div>
            </NotificationDetail>
            <NotificationDetail>
              <div className="d-flex justify-content-between">
                <Typography
                  styleName="subtitle3"
                  content="Weekly Applications Summary"
                />
                <FormFields.Switch
                  {...{
                    ...emailNotificationFields.weekly_applications,
                    onChangeHandler: (value) => {
                      onSubmitHandler(
                        emailNotificationFields.weekly_applications.fieldName,
                        !value
                      );
                    },
                  }}
                />
              </div>
              <div className="notification-detail">
                <Typography
                  styleName="text_12pt"
                  content="Choose Template. Choose Template. Choose Template. Choose template and add information.
                  Choose Template. This is where we talk talk talk. This is where we talk talk talk."
                  color="black70"
                />
              </div>
            </NotificationDetail>
            <NotificationDetail>
              <div className="d-flex justify-content-between">
                <Typography styleName="subtitle3" content="Weekly Account" />
                <FormFields.Switch
                  {...{
                    ...emailNotificationFields.weekly_account,
                    onChangeHandler: (value) => {
                      onSubmitHandler(
                        emailNotificationFields.weekly_account.fieldName,
                        !value
                      );
                    },
                  }}
                />
              </div>
              <div className="notification-detail">
                <Typography
                  styleName="text_12pt"
                  content="Choose Template. Choose Template. Choose Template. Choose template and add information.
                  Choose Template. This is where we talk talk talk."
                  color="black70"
                />
              </div>
            </NotificationDetail>
            <NotificationDetail>
              <div className="d-flex justify-content-between">
                <Typography styleName="subtitle3" content="Monthly Account" />
                <FormFields.Switch
                  {...{
                    ...emailNotificationFields.monthly_account,
                    onChangeHandler: (value) => {
                      console.log(value);

                      onSubmitHandler(
                        emailNotificationFields.monthly_account.fieldName,
                        !value
                      );
                    },
                  }}
                />
              </div>
              <div className="notification-detail">
                <Typography
                  styleName="text_12pt"
                  content="Choose Template. Choose Template. Choose Template. Choose template and add information.
                  Choose Template. This is where we talk talk talk. Choose Template. This is where we talk talk talk. Talk talk talk talk talk."
                  color="black70"
                />
              </div>
            </NotificationDetail>
          </NotificationDetailContainer>
        )}
      </Formik>
    </EmailNotificationContainer>
  );
};

export default EmailNotification;
