import React from "react";
import { Cell, ColumnInstance } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV, faPlus } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { Dropdown } from "react-bootstrap";

import {
  DarkModeButton,
  IconButton,
  Table,
  TableFilter,
  Typography,
} from "../Basic";
import { colors } from "../Theme/Colors";
import { tableColumns, tableData } from "src/utils/table";

const Tables = () => {
  const [filterType, setFilterType] = React.useState("active");
  const [rowData, setRowData] = React.useState(
    tableData.filter((data) => data.status === "active")
  );
  const updateRowData = () => {
    const data = tableData.filter((data) => data.status === filterType);
    setRowData(data);
  };

  React.useEffect(() => {
    updateRowData();
  }, [filterType]);

  const customRowCell = (cell: Cell) => {
    switch (cell.column.Header) {
      case "Job":
        return (
          <RowCellContainer
            className="job-column"
            style={{ marginTop: "18px" }}
          >
            <Typography
              content={cell.value[0]}
              styleName="tableRowDefault"
              color="black85"
            />
            <Typography
              content={cell.value[1]}
              styleName="tableSubHeading"
              color="blue_grey"
            />
          </RowCellContainer>
        );
      case "UserPhoto":
        return (
          <IconContainer>
            <FontAwesomeIcon
              icon={cell.value}
              size="2xl"
              className="user-icon"
            />
          </IconContainer>
        );
      case "Options":
        return (
          <Container>
            <Dropdown>
              <DropDownContainer className="btn btn-danger">
                <FontAwesomeIcon
                  icon={faEllipsisV}
                  size="xl"
                  className="user-icon"
                />
              </DropDownContainer>
              <DropDownMenuContainer className="dropdown-menu tx-13 custom-dropdown">
                <Dropdown.Item className="dropdown-item" href="#">
                  <Typography
                    content={cell.value[0]}
                    styleName="tableSubHeading"
                    color="black_pure"
                  />
                </Dropdown.Item>
                <Dropdown.Item className="dropdown-item" href="#">
                  <Typography
                    content={cell.value[1]}
                    styleName="tableSubHeading"
                    color="black_pure"
                  />
                </Dropdown.Item>
                <Dropdown.Item className="dropdown-item" href="#">
                  <Typography
                    content={cell.value[2]}
                    styleName="tableSubHeading"
                    color="black_pure"
                  />
                </Dropdown.Item>
              </DropDownMenuContainer>
            </Dropdown>
          </Container>
        );
      case "User":
      case "Jobs":
      case "View":
        return (
          <RowCellContainer className="job-column">
            <Typography
              content={cell.value}
              styleName="tableSubHeading"
              color="blue_grey"
            />
          </RowCellContainer>
        );
      default:
        return (
          <RowCellContainer className="job-column">
            <Typography
              content={cell.value}
              styleName="tableRowDefault"
              color="black85"
            />
          </RowCellContainer>
        );
    }
  };

  const customColumnCell = (column: ColumnInstance) => {
    return column.render("Header");
  };

  return (
    <div className="my-3 mx-3">
      <DarkModeButton />
      <Table
        tablefilter={
          <TableFilter filterType={filterType} setFilterType={setFilterType} />
        }
        data={rowData}
        columns={tableColumns}
        customRowCell={customRowCell}
        customColumnCell={customColumnCell}
      />
      <div className="d-flex justify-content-center">
        <IconButton
          handleClick={() => {}}
          icon={faPlus}
          position="right"
          label="Create Job"
        />
      </div>
    </div>
  );
};

export default Tables;

export const RowCellContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  .user-icon {
    font-size: 48px;
  }
`;
export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  .user-icon {
    color: ${colors.setting_grey};
  }
`;
export const DropDownContainer = styled(Dropdown.Toggle)`
  background: transparent !important;
  border: none;

  &::after {
    display: none;
  }
  &:hover {
    background: transparent !important;
    border: none;
  }
`;
export const DropDownMenuContainer = styled(Dropdown.Menu)`
  margin: 0px;
  .dropdown-item:hover {
    background: ${colors.grey_input_dropdown_hover} !important;
  }
  &::after {
    content: "";
    position: absolute;
    width: 40px;
    height: 40px;
    background: ${colors.white_pure};
    transform: rotate(45deg);
    top: 0;
    right: 10px;
    z-index: -1;
  }
`;
