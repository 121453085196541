import React, { RefObject, useRef, useState } from "react";
import { Document, Page } from "react-pdf";
import { useParams } from "react-router-dom";
import { Grid } from "antd";
import clsx from "clsx";

import { Typography } from "../../Basic";
import { CandidateList, CandidateProfile, PipeLinePhase } from "../../Pipeline";
import { ICandidateTabHeader } from "../../Pipeline/TabHeaderComponent/CandidateTabHeader/CandidateTabHeader";
import { ICONS } from "src/assets/custom_Icons";
import { IPipeLine, PhaseKey } from "src/components/Pipeline/PipeLine";
import { useAppDispatch, useAppSelector, actions } from "src/Redux";
import { LOCAL_STORAGE_KEY } from "src/utils/LOCAL_STORAGE_KEYS";
import { API_CALLS } from "src/API_CALLS";
import Loading from "src/components/Basic/Loading";
import {
  CandidateMobileTabHeader,
  CandidateTabHeader,
  Activity,
  Experience,
  Comments,
  Summary,
  Files,
  Qualifiers,
} from "../../Pipeline/TabHeaderComponent";
import {
  CandidateContainer,
  Container,
  MainContainer,
  PipelineContainer,
  Wrapper,
  Modal,
  ModalBody,
  ModalHeader,
  Circle,
  PaginationContainer,
  ModalMainContainer,
  ModalWrapper,
} from "./PipelineStyle";

const Pipeline = () => {
  const [phase, setPhase] = React.useState<PhaseKey>(
    JSON.parse(
      (localStorage.getItem(LOCAL_STORAGE_KEY.SELECTED_PHASE) as string) ?? "0"
    )
  );
  const [activeTab, setActiveTab] =
    React.useState<keyof ICandidateTabHeader.TabHeader>("Summary");
  const [latest, setLatest] = React.useState<"latest" | "oldest">("latest");
  const [unread, setUnread] = React.useState<"read" | "unread">("unread");
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [selected, setSelected] = useState<IPipeLine.RatingOption[]>([]);
  const [isRegretModalOpen, setIsRegretModalOpen] = React.useState(false);

  const screen = Grid.useBreakpoint();
  const { job_id } = useParams();
  const dispatch = useAppDispatch();
  const { common, candidate } = useAppSelector((state) => state);
  const {
    pageSize,
    candidateListData,
    selectedFile,
    candidateOverviewLoading,
    isFilePreview,
    step,
    activePage,
  } = candidate;

  const containerRef: RefObject<HTMLDivElement> = useRef(null);
  const containerRefMobile: RefObject<HTMLDivElement> = useRef(null);
  const clientHeight = containerRef.current?.clientHeight as number;
  const clientHeightMobile = containerRefMobile.current?.clientHeight as number;

  const headerdata: ICandidateTabHeader.TabHeader = {
    Experience: <Experience />,
    Summary: <Summary setActiveTab={setActiveTab} />,
    Activity: <Activity />,
    Comments: <Comments />,
    Files: <Files />,
    Qualifiers: <Qualifiers />,
  };

  const PageDataHandler = () => {
    if (activePage === 0) {
      dispatch(actions.candidate.setActivePage(1));
    } else {
      setActiveTab("Summary");
      dispatch(actions.candidate.resetCandidateOverviewDetails());
      dispatch(actions.candidate.setIsFilePreview(false));
      const body = {
        job_id: job_id as string,
        page: activePage,
        size: pageSize,
        status: phase,
        read_sort: unread,
        date_sort: latest,
        rating:
          selected.length === 4 ? [] : selected.map((option) => option.value),
      };
      API_CALLS.PIPELINE.getPipelineApplicants(body);
    }
  };

  React.useEffect(() => {
    PageDataHandler();
  }, [activePage, pageSize, phase, latest, unread, selected]);
  const modalRef = useRef(null);

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !(modalRef?.current as Element).contains(event.target as Element)
      ) {
        dispatch(actions.candidate.setIsFilePreview(false));
        dispatch(actions.candidate.setSelectedFile({}));
      }
    };

    if (isFilePreview) {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [isFilePreview]);

  return (
    <MainContainer>
      <PipelineContainer className="pipeline-heading">
        <Typography
          content="PIPELINE"
          styleName="pipelineText"
          color="primary_default"
        />
        <div
          className=" back-button"
          onClick={() => {
            dispatch(actions.candidate.setStep(0));
            dispatch(actions.candidate.setIsFilePreview(false));
            dispatch(actions.candidate.setSelectedFile({}));
          }}
        >
          <ICONS.LessThanIcon />
          <Typography content="back" styleName="backButton" color="black80" />
        </div>
      </PipelineContainer>
      <PipeLinePhase
        setPhase={setPhase}
        selectedPhase={phase}
        setIsRegretModalOpen={setIsRegretModalOpen}
        setSelected={setSelected}
        setUnread={setUnread}
        setLatest={setLatest}
      />
      <Container ref={containerRef}>
        <Wrapper isShown={step === 0}>
          <CandidateList
            phase={phase}
            setPhase={setPhase}
            setActiveTab={setActiveTab}
            latest={latest}
            unread={unread}
            setLatest={setLatest}
            setUnread={setUnread}
            selected={selected}
            setSelected={setSelected}
          />
        </Wrapper>
        <CandidateContainer isShown={step === 1} ref={containerRefMobile}>
          {candidateOverviewLoading && <Loading position="absolute" />}
          {candidateListData.applicants.length ? (
            <>
              <CandidateProfile
                setIsRegretModalOpen={setIsRegretModalOpen}
                isRegretModalOpen={isRegretModalOpen}
                selectedRating={selected.map((option) => option.value)}
                PageDataHandler={PageDataHandler}
              />
              <CandidateTabHeader
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
              <CandidateMobileTabHeader
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
              {headerdata[activeTab]}
            </>
          ) : (
            !common.isPageLoad && (
              <div className="mx-auto eye-icon">
                <ICONS.EyeOffIcon />
              </div>
            )
          )}
        </CandidateContainer>

        <Modal isShown={isFilePreview} isMobileShow={step === 1}>
          <ModalMainContainer>
            <ModalWrapper ref={modalRef}>
              <ModalHeader>
                <div className="d-flex flex-column justify-content-end">
                  <Typography
                    content={selectedFile.name}
                    styleName="filePreviewHeading"
                    color="black85"
                  />
                  <Typography
                    content={`${selectedFile.size}KB`}
                    styleName="qualifiersResponseMobile"
                    color="black70"
                  />
                </div>
                <div className="d-flex gap-3">
                  {selectedFile.type !== "docx" && (
                    <div
                      onClick={() =>
                        API_CALLS.PIPELINE.downloadFile(
                          selectedFile.id,
                          selectedFile.name
                        )
                      }
                    >
                      <ICONS.FileDownloadBlueIcon />
                    </div>
                  )}
                  <Circle
                    onClick={() =>
                      dispatch(actions.candidate.setIsFilePreview(false))
                    }
                  >
                    <ICONS.CrossIcon />
                  </Circle>
                </div>
              </ModalHeader>

              <ModalBody
                style={
                  screen.lg
                    ? selectedFile.type === "docx"
                      ? {
                          minHeight: clientHeight - 82,
                        }
                      : {
                          maxHeight: clientHeight - 200,
                        }
                    : selectedFile.type === "docx"
                    ? {
                        minHeight: clientHeightMobile - 128,
                      }
                    : {
                        maxHeight: clientHeightMobile - 310,
                      }
                }
              >
                <Document
                  className={clsx(screen.xs && "w-100")}
                  file={selectedFile.path}
                  onLoadSuccess={({ numPages }: { numPages: number }) =>
                    setNumPages(numPages)
                  }
                  error={
                    <>
                      <div className="pb-1 d-flex justify-content-center">
                        No preview available{" "}
                      </div>
                      <div className="d-flex justify-content-center mt-3 pt-2">
                        <ICONS.FileDownloadBlueIcon width={42} height={56} />
                      </div>
                      <div
                        className="download-file-text"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          API_CALLS.PIPELINE.downloadFile(
                            selectedFile.id,
                            selectedFile.name
                          )
                        }
                      >
                        <Typography
                          content="Download file"
                          styleName="authInputText"
                          color="primary_default"
                        />
                      </div>
                    </>
                  }
                >
                  <Page pageNumber={pageNumber} devicePixelRatio={1} />
                </Document>
              </ModalBody>
              {selectedFile.type !== "docx" && (
                <PaginationContainer className="d-flex justify-content-center gap-3 ">
                  <span
                    className="py-2 px-3 bg-primary radius-5 tx-white"
                    onClick={() => {
                      if (pageNumber === 1) return;
                      setPageNumber(pageNumber - 1);
                    }}
                  >
                    {"<"}
                  </span>
                  <p className="d-flex justify-content-center align-items-center mb-0">
                    Page {pageNumber} of {numPages}
                  </p>
                  <span
                    className="py-2 px-3 bg-primary radius-5 tx-white"
                    onClick={() => {
                      if (pageNumber >= numPages) return;
                      setPageNumber(pageNumber + 1);
                    }}
                  >
                    {">"}
                  </span>
                </PaginationContainer>
              )}
            </ModalWrapper>
          </ModalMainContainer>
        </Modal>
      </Container>
    </MainContainer>
  );
};

export default Pipeline;
