import styled from "styled-components";

import { theme } from "src/components/Theme";
import { NovaBlackMobile } from "../Components/Icon";

export const Header = () => (
  <Container>
    <NovaBlackMobile />
  </Container>
);

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  border-bottom: 1px solid ${theme.colors.black10};
`;
