const ThemeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clipPath="url(#clip0_1_417)">
        <path
          d="M1.66663 9.99996C1.66663 14.6023 5.39759 18.3333 9.99996 18.3333C11.3807 18.3333 12.5 17.214 12.5 15.8333V15.4166C12.5 15.0296 12.5 14.8361 12.5213 14.6736C12.669 13.5518 13.5518 12.669 14.6736 12.5213C14.8361 12.5 15.0296 12.5 15.4166 12.5H15.8333C17.214 12.5 18.3333 11.3807 18.3333 9.99996C18.3333 5.39759 14.6023 1.66663 9.99996 1.66663C5.39759 1.66663 1.66663 5.39759 1.66663 9.99996Z"
          stroke="#7A839B"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.83329 10.8333C6.29353 10.8333 6.66663 10.4602 6.66663 9.99996C6.66663 9.53972 6.29353 9.16663 5.83329 9.16663C5.37306 9.16663 4.99996 9.53972 4.99996 9.99996C4.99996 10.4602 5.37306 10.8333 5.83329 10.8333Z"
          stroke="#7A839B"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.3333 7.49996C13.7935 7.49996 14.1666 7.12686 14.1666 6.66663C14.1666 6.20639 13.7935 5.83329 13.3333 5.83329C12.8731 5.83329 12.5 6.20639 12.5 6.66663C12.5 7.12686 12.8731 7.49996 13.3333 7.49996Z"
          stroke="#7A839B"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.33329 6.66663C8.79353 6.66663 9.16663 6.29353 9.16663 5.83329C9.16663 5.37306 8.79353 4.99996 8.33329 4.99996C7.87306 4.99996 7.49996 5.37306 7.49996 5.83329C7.49996 6.29353 7.87306 6.66663 8.33329 6.66663Z"
          stroke="#7A839B"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_417">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ThemeIcon;
