import styled from "styled-components";

import { theme } from "src/components/Theme";

export const FilesMainContainer = styled.div`
  margin: 20px 0px 20px 38px;
  @media (max-width: 991px) {
    margin: 16px 8px 89px;
  }
`;
export const File = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 494px;
  height: 42px;
  border: 1px solid ${theme.colors.black30};
  border-radius: 5px;
  cursor: pointer;
  padding: 9px 0px 9px 12px;
  label {
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
    @media (max-width: 991px) {
      font-size: 12px;
    }
  }
  @media (min-width: 1440px) {
    width: 543px;
  }
  @media (max-width: 991px) {
    width: 90%;
  }
  .dark-theme & {
    border: 1px solid ${theme.colors.black130};
    background: ${theme.colors.black130};
    label {
      color: ${theme.colors.black10};
    }
  }
`;
export const FileContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 11px;
  @media (max-width: 991px) {
    margin: 0px 8px 0px 4px;
  }
`;
export const FilesListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 24px;
  @media (max-width: 991px) {
    margin-top: 20px;
  }
`;
export const HeadingContainer = styled.div`
  @media (max-width: 991px) {
    margin-left: 8px;
  }
  .dark-theme &.no-data-msg label {
    color: ${theme.colors.black10};
  }
`;
export const IconContainer = styled.div`
  margin: auto;
  margin-top: 40px;
  svg {
    @media (max-width: 991px) {
      width: 35px;
      height: 35px;
    }
  }
  @media (max-width: 991px) {
    margin: 55px auto 80px;
  }
  .dark-theme & svg path {
    stroke: ${theme.colors.light_default};
  }
`;
export const Icon = styled.div`
  svg {
    cursor: pointer;
    @media (max-width: 991px) {
      width: 18px;
      height: 24px;
    }
  }
`;
