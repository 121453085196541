import { TermsAndPrivacyComponents } from "../Components";

const { Text } = TermsAndPrivacyComponents;

export const Description = () => (
  <>
    <div>
      <Text.Span content="At Ditto Media Company (" />
      <Text.Bold content="“Ditto Media”" />
      <Text.Span content=") we adhere to the highest standards of protecting your personal data when we process it by virtue of your use of our Services or our platform " />
      <Text.Link content="https://www.nova" />
      <Text.Span content=" any related application (collectively, " />
      <Text.Bold content="“Nova”" />
      <Text.Span content="), or by providing us with your personal data in any other way. As such, we have created this privacy policy for you to read and to understand how we safeguard your personal data and respect your privacy (" />
      <Text.Bold content="“Privacy Policy”" />
      <Text.Span content=")." />
    </div>
    <div>
      <Text.Span content="Please note that Ditto Media is a Delaware C Corporation Subsidiary and is registered in accordance with the laws of the State of Delaware." />
    </div>
    <div>
      <Text.Span content="Not all terms are necessarily defined in order or may be defined in our Terms and Conditions of Use (" />
      <Text.Bold content="“Terms”" />
      <Text.Span content=")." />
    </div>
    <div>
      <Text.Span content="Please ensure that you read all the provisions below, and our policies and guidelines which may apply from time to time, to understand all of your, and our, rights and duties." />
    </div>
  </>
);
