import { IInput } from "../Components/input/Input";
import { OnboardingProps } from "./Onboarding";

const initialValues: OnboardingProps.FormValues = {
  password: "",
  confirm_password: "",
};

const idPrefix = "onboarding";
const password: IInput.IProps = {
  fieldName: "password",
  isRequired: true,
  placeholder: "Password",
  InputType: "password",
  idPrefix,
};
const confirm_password: IInput.IProps = {
  fieldName: "confirm_password",
  isRequired: true,
  placeholder: "Confirm Password",
  InputType: "password",
  idPrefix,
};
const formFields = { password, confirm_password };
export const OnboardingUtils = { initialValues, formFields, idPrefix };
